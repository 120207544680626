export default function CGV(props) {
    return (<div className="text-left legal-text">
        <p align="center" className="text-primary h1 pb-4">
            <strong>Conditions Générales de Vente MeetPharma</strong>
        </p>
        <p>
            Les présentes conditions générales de Vente (ci-après dénommées «
            Conditions Générales de Vente ») sont conclues entre :
        </p>
        <p>
            La société MeetPharma, société par actions simplifiée (S.A.S), immatriculée
            au RCS de Bobigny sous le n° 901 382 531, dont le siège social est situé au
            182 Avenue Jean Lolive - 93500 Pantin (ci-après : la « Société »).
        </p>
        <p>
            Et
        </p>
        <p>
            L’Utilisateur de la plateforme numérique    <a href="https://app.meetpharma.fr/">www.app.meetpharma.fr</a>
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>Objet et champ d’application</strong>
        </p>
        <p>
            La Société exploite une plateforme numérique accessible à l’adresse    <a href="https://app.meetpharma.fr/">www.app.meetpharma.fr</a> (ci-après
            la « Plateforme ») hébergée par la société OVH située au 2 Rue Kellermann
            59100 Roubaix.
        </p>
        <p>
            La Plateforme est dédiée à la mise en relation de Candidats et d’Employeurs
            (ci-après le « Client ») du secteur de la santé dans le domaine de la
            pharmacie d’officine.
        </p>
        <p>
            Les présentes Conditions Générales de Vente de MeetPharma ont pour objet de
            régir les relations contractuelles et commerciales entre la Plateforme et
            le Client (l’Employeur).
        </p>
        <p>
            <strong>Définitions</strong>
            <strong> </strong>
        </p>
        <p>
            <strong>Compte : </strong>
            désigne le compte créé sur la Plateforme par l’Utilisateur lors de son
            inscription sur la Plateforme.
        </p>
        <p>
            <strong>Contenu : </strong>
            désigne tous propos, messages ou informations de quelque nature que ce soit
            (texte, image, vidéos, photographies, commentaires, marques, dénominations
            sociales, etc.), mis en ligne par un Utilisateur sur la Plateforme.
        </p>
        <p>
            <strong>Candidat : </strong>
            désigne toute personne morale ou physique souhaitant exercer dans le
            domaine de la pharmacie d’officine. Est également désigné comme Candidat,
            toute personne agissant en tant qu’indépendant disposant d’un numéro SIRET
            et inscrite au Registre du Commerce et des Sociétés au Répertoire des
            Métiers, au Registre spécial tenu par le greffe du tribunal de Commerce
            dont dépend son centre de formalités des entreprises, ou au Répertoire
            national des associations, soit exerçant en tant qu’indépendant dans des
            conditions légales depuis la France ou l’étranger et qui propose ses
            services aux Employeurs à travers la Plateforme.
        </p>
        <p>
            <strong>Client (Employeur) : </strong>
            désigne toute personne morale ou physique exerçant dans le domaine de la
            pharmacie d’officine, utilisant la Plateforme à des fins strictement
            professionnelles, en vue d’entrer en contact ou d’être mis en relation avec
            un ou plusieurs Candidat(s) afin de lui confier la réalisation d’une
            Mission.
        </p>
        <p>
            <strong>Plateforme : </strong>
            désigne le site internet exploité par la Société dont l’adresse est    <a href="https://app.meetpharma.fr/">www.app.meetpharma.fr</a>
        </p>
        <p>
            <strong>Mission : </strong>
            désigne la tâche spécifique confiée par un Client (Employeur) à un
            Candidat via la Plateforme.
        </p>
        <p>
            <strong>Services : </strong>
            désigne tous les services fournis par la Société tels que définis à
            l’article 3.1 des Conditions Générales d’Utilisation.
        </p>
        <p>
            <strong>Utilisateurs : </strong>
            désigne les Candidats et les Clients (Employeurs) inscrits sur la
            Plateforme.
        </p>
        <p>
            <strong>I. </strong>
            <strong>Responsabilités</strong>
        </p>
        <p>
            <strong>1. </strong>
            <strong>Responsabilité de la Société</strong>
        </p>
        <p>
            La Société MeetPharma s’engage à tout mettre en œuvre dans la réalisation
            de la mission qui lui a été confiée.
        </p>
        <p>
            <strong>1.1 </strong>
            <strong>Mise en relation</strong>
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>1.1.1 </strong>
            La Société met à la disposition des Candidats et des Clients des outils et
            moyens techniques leur permettant d’entrer en relation aux fins de conclure
            un contrat de travail. Sa responsabilité se limite à la fourniture de ces
            moyens, tels que décrits dans les Conditions Générales d’Utilisation.
        </p>
        <p>
            <strong>1.1.2 </strong>
            La Société et l’Utilisateur sont des parties indépendantes, chacun agissant
            en son nom personnel et pour son propre compte. La Société ne conclut donc
            aucun contrat pour le compte et/ou au nom d’un Candidat ou d’un Client, ces
            derniers contractant directement entre eux par l’intermédiaire de la
            Plateforme. Par conséquent, la Société ne peut en aucun cas être considérée
            comme employé/employeur ou agent d’un Utilisateur.
        </p>
        <p>
            <strong>1.1.3 </strong>
            La Société n’étant pas partie à quelque titre que ce soit aux contrats
            relatifs à une Mission conclus entre les Candidats et les Clients, ces
            derniers sont seuls responsables des difficultés, réclamations et litiges
            pouvant intervenir lors de la conclusion et/ou de l’exécution des dits
            contrats.
        </p>
        <p>
            <strong>1.1.4 </strong>
            La Société fait son possible pour s’assurer du contenu et de la validité
            des informations et documents transmis par les Candidats sur la Plateforme,
            dès lors qu’ils sont établis en France, mais ne saurait être tenue
            responsable des fausses informations, trompeuses ou non à jour qui lui sont
            communiquées par l’Utilisateur.
        </p>
        <p>
            <strong>1.1.5 </strong>
            La Société ne saurait être tenue responsable des infractions aux
            obligations leur incombant notamment dans le cadre de la lutte contre le
            travail dissimulé, du respect des règles de transparences et des dommages
            pouvant en résulter.
        </p>
        <p>
            <strong> </strong>
        </p>
        <h3>
            <strong>1.2 </strong>
            <strong>Navigation sur la Plateforme</strong>
        </h3>
        <p>
            <strong>1.2.1 </strong>
            La navigation sur la Plateforme    <a href="https://app.meetpharma.fr/">www.app.meetpharma.fr</a> relève
            uniquement de la responsabilité des Utilisateurs. La Société MeetPharma ne
            saurait être tenue responsable au titre d’erreurs, de virus informatiques,
            de pannes qui pourraient faire obstacle à la continuité d’accès à sa
            Plateforme.
        </p>
        <p>
            <strong>1.2.2 </strong>
            La Société ne peut exclure que l’accès et le fonctionnement de la
            Plateforme et ses Services soient interrompus notamment en cas de mauvais
            fonctionnement des équipements de l’Utilisateur, de dysfonctionnements du
            réseau internet de l’Utilisateur, d’opération de maintenance ou de force
            majeure. Par conséquent, la Société ne saurait être tenue responsable d’une
            interruption des Services, qu’elle soit volontaire ou non.
        </p>
        <p>
            <strong>2. </strong>
            <strong>Responsabilité des Utilisateurs</strong>
        </p>
        <p>
            <strong>2.1 </strong>
            L’Utilisateur est seul responsable du préjudice direct ou indirect qu’il
            est susceptible de subir du fait d’informations inexactes, incomplètes, et/
            ou trompeuses qu’il fournirait lors de son inscription sur la Plateforme ou
            en l’absence de mise à jour de ces informations.
        </p>
        <p>
            <strong>2.2 </strong>
            L’Utilisateur est seul responsable de l’ensemble des Contenus qu’il choisit
            de mettre en ligne sur la Plateforme.
        </p>
        <p>
            <strong>2.3 </strong>
            Le Client est le seul responsable de la description de l’offre de Mission
            pour laquelle il sollicite un Candidat. En cas d’erreur du descriptif de
            cette offre, le Client assumera seul, en lien avec le Candidat, les
            éventuelles prestations complémentaires nécessaires et surcoûts y
            afférents.
        </p>
        <p>
            <strong>2.4 </strong>
            L’Utilisateur s’interdit expressément de publier tout Contenu injurieux,
            diffamant, dénigrant, calomnieux, raciste, xénophobe, contraire à la morale
            et aux bonnes mœurs, contrefaisant, portant atteinte à l’ordre public ou
            aux droits de tiers, susceptible de porter atteinte aux droits, à la
            réputation et à l’image de la Société MeetPharma et plus généralement, dont
            la teneur enfreindrait la loi et/ou les règlements, notamment d’ordre
            pénal.
        </p>
        <p>
            <strong>II. </strong>
            <strong>Abonnement</strong>
        </p>
        <p>
            Les Utilisateurs enregistrés en tant qu’Employeurs doivent souscrire à un
            abonnement pour accéder à l’intégralité des Services de la Plateforme.
            L’abonnement est enregistré directement sur la Plateforme <a href="https://app.meetpharma.fr/">www.app.meetpharma.fr</a>
            {/* sous forme de prélèvements mensuels. */}
        </p>
        <p>
            Tout abonnement ayant été souscrit par le Client entraîne automatiquement
            l’acceptation des présentes Conditions Générales de Vente.
        </p>
        <p>
            <strong>III. </strong>
            <strong>Tarifs et paiements</strong>
        </p>
        <p>
            Les tarifs des services vendus sont ceux en vigueur au jour de la prise la
            souscription à l’abonnement par le Client sur la Plateforme.
        </p>
        <p>
            La Société MeetPharma s'accorde le droit de modifier ses tarifs à tout
            moment. Toutefois, elle s'engage à facturer les services commandés aux prix
            indiqués lors de la souscription.
        </p>
        <p>
            MEETPHARMA propose 2 types d'abonnements
        </p>
        {/* <p>
            - Offre 1 : 49,99€ H.T par mois avec un engagement de 6 mois dont 2 mois
            gratuits puis, 49,99€ H.T les mois suivants.
        </p>
        <p>
            - Offre 2 : 69,99€ HT par mois sans engagement dont 2 mois gratuits
        </p>
        <p>
            Les engagements de 1 an sont renouvelables par tacite reconduction à partir
            de la date de souscription.
        </p>
        <p>
            Le Paiement est effectué en Euros par carte bancaire (Carte Bleue, Visa,
            Master Card) ou par prélèvement bancaire, au choix du Client. Le Prix est
            payable comptant, en totalité au jour de la passation de la commande par le
            Client, par voie de paiement sécurisée.
            <br />
            Les paiements effectués par le Client ne seront considérés comme définitifs
            qu’après encaissement effectif des sommes dues, par la Société.
        </p>
        <p>
            Aux fins de mise en œuvre du système de paiement, la Société MeetPharma a
            souscrit aux services de la société Stripe, société agréée en tant
            qu’établissement de monnaie électronique.
        </p>
        <p>
            Les Utilisateurs contractent directement avec Stripe s’agissant du système
            de paiement par provision, et déclarent accepter les conditions générales
            de Stripe disponibles ici :    <a href="https://stripe.com/fr/ssa">https://stripe.com/fr/ssa</a>
        </p> */}
        <p>
            - OFFRE BASIC 1 : Tarif de 34,90 TTC sans engagement reconductible par tacite reconduction avec 2 mois gratuits afin de tester les services de MEETPHARMA.L'employeur peut se désabonner quand il le souhaite.
        </p>
        <p>
            - OFFRE PREMIUM 2 : Tarif de 24,90 TTC sur 12 mois reconductible par tacite reconduction avec 2 mois gratuits pour tester les services de MEETPHARMA.L'employeur peut se désabonner à la fin de son contrat d'engagement.
        </p>
        <p>
            La période de gratuité des 2 mois gratuits et valable une seule fois par compte utilisateur.
            L'employeur/candidat peuvent demander la suppression définitive de leurs comptes MEETPHARMA à l'adresse suivante : contact@meetpharma.fr
        </p>
        {/* <p>
            <strong>IV. </strong>
            <strong>Rabais et ristournes</strong>
        </p>
        <p>
            Les Utilisateurs peuvent bénéficier d’une offre de parrainage grâce à un
            lien fourni par la Plateforme.
        </p>
        <p>
            Les Pharmacies peuvent parrainer d’autres pharmacies sans limite et ainsi
            bénéficier de remise en numéraire sur leur abonnement pour chaque filleul
            enregistré en tant que Pharmacie ayant souscrit un abonnement à la
            Plateforme.
        </p>
        <p>
            Les Candidats peuvent parrainer d’autres candidats et ainsi bénéficier de
            Meetpoints qui représentent des points cumulés sur le Compte du Candidat et
            sont transformables en euros ou avantages en nature. La conversion de ces
            Meetpoints en euros est limitée à la grille de conversion proposée par la
            Plateforme.
        </p>
        <p>
            Les Meetpoints sont octroyés uniquement dans le cadre d'un contrat à durée déterminée.
        </p>
        <p>
            L’Utilisateur peut également bénéficier de Meetpoints grâce à des Meetcodes
            (clé promotionnelle) fournis par la Société MeetPharma.
        </p>
        <p>
            Ces tarifs sont fermes et limités dans le temps.
        </p> */}

        <p>
            <strong>IV. </strong>
            <strong>Nullité – Renonciation</strong>
        </p>
        <p>
            Dans l’hypothèse où l’une des clauses du présent contrat serait déclarée
            nulle et non avenue par un changement de législation, de réglementation ou
            par une décision de justice, cela ne saurait en aucun cas affecter la
            validité et le respect des présentes Conditions Générales de Vente.
        </p>
        <p>
            Le défaut pour la Société MeetPharma d’exercer les droits qui lui sont
            reconnus en application des présentes ne constitue pas une renonciation à
            faire valoir ses droits.
        </p>
        <p>
            <strong>V. </strong>
            <strong>Durée, résiliation et sanctions</strong>
            <strong></strong>
        </p>
        <p>
            Le présent contrat est conclu pour une durée indéterminée à compter de
            l’acceptation des présentes Conditions Générales de Vente par
            l’Utilisateur.
        </p>
        <p>
            Toute utilisation de la Plateforme et de ses Services contraire aux
            Conditions Générales de Vente et/ou aux lois et règlements en vigueur donne
            droit à la Société MeetPharma de suspendre, sans préavis, le Compte de
            l’Utilisateur ou de refuser à l’Utilisateur l'accès à tout ou partie de la
            Plateforme, sans préjudice de tous dommages et intérêts éventuels que la
            Société serait en droit de réclamer.
        </p>
        <p>
            <strong>VI. </strong>
            <strong>Droit applicable et juridiction compétente</strong>
        </p>
        <p>
            Les présentes Conditions Générales de Vente sont soumises à la loi
            française. Tout litige relatif à l’exécution des présentes Conditions sera
            de la compétence exclusive des tribunaux français.
        </p>
        <p>
            Les présentes Conditions Générales de Vente sont rédigées en français ;
            dans le cas où elles seraient traduites en une ou plusieurs langues
            étrangères, seul le texte français fera foi en cas de litige.
        </p>
        <p>
            Version en vigueur à compter du 01 Avril 2024.
        </p>
    </div>)

}