import moment from "moment"

var yesterday = moment().subtract(1, "day")

class MiscUtils {
    checkDate(current) {
        return current.isAfter(yesterday)
    }

    replaceAt(str, pos, replacement) {
        var head = str.substr(0, pos)
        var tail = str.substr(pos + replacement.length)
        return head + replacement + tail
    }

    emailIsCorrect = (email) => {
        var emailRegex = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
        if (!email)
            return false;

        if (email.length > 254)
            return false;

        var valid = emailRegex.test(email);
        if (!valid)
            return false;

        // Further checking of some things regex can't handle
        var parts = email.split("@");
        if (parts[0].length > 64)
            return false;

        var domainParts = parts[1].split(".");
        if (domainParts.some(function (part) { return part.length > 63; }))
            return false;

        return true;
    }
    checkSameDate(first, second){
    return first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
    }

}

export default new MiscUtils()