import React, { useState } from 'react'
import { markerOnMapStyle, markerOnMapStyleHover } from '../../styles/markerOnMap.style'

import { ReactComponent as PharmacyPinIcon } from '../../assets/img/icons/pharmacy-pin.svg';
import { ReactComponent as CandidatePinIcon } from '../../assets/img/icons/candidate-pin.svg';

function MarkerOnMap(props) {


    const [isHovered, setIsHovered] = useState(false)

    if(props.activeMarker===props.id){
        
    }
    const style = (isHovered || props.activeMarker === props.id) ? markerOnMapStyleHover : markerOnMapStyle

    const _onClick = () => {
        props.setClickedMarker(props.id)
    }

    const _onMouseEnter = () => {
        setIsHovered(true)
        props.setActiveMarker(props.id)
    }
    const _onMouseLeave = () => {
        setIsHovered(false)
        props.setActiveMarker("")
    }


    var icon
    if (props.mapType === "candidate") {
        icon = "fas fa-clinic-medical"
    } else if (props.mapType === "pharmacy") {
        icon = "fas fa-id-card-alt"
    } else {
        icon = "fas fa-map-pin"
    }
    return (<>
        {props.noActions ?
            <div>
                <i className={icon + " text-primary fa-2x"} style={{ position: "absolute", top: -20, right: -8 }} />
            </div> :
              <>  {(props.mapType === "candidate") && <PharmacyPinIcon style={{ ...style}} 
              fill={(isHovered || props.activeMarker === props.id )? "#808E93" : "#e2025f"}
              onMouseEnter={() => _onMouseEnter()}
              onMouseLeave={() => _onMouseLeave()}
              onClick={() => _onClick()} />}
              {(props.mapType === "pharmacy") && <CandidatePinIcon style={{ ...style}} 
              fill={(isHovered || props.activeMarker === props.id )? "#808E93" : "#e2025f"}
              onMouseEnter={() => _onMouseEnter()}
              onMouseLeave={() => _onMouseLeave()}
              onClick={() => _onClick()} />}
                </>
        }
    </>
    )

}

export default MarkerOnMap