import moment from "moment"
import React, { Component } from "react"
import { Button, Card, Col, Input, Row, Table } from "reactstrap"
import meetPointService from "../../services/meetPoint.service"

export default class adminPoints extends Component {
    constructor(props) {
        super(props)
        this.validate = this.validate.bind(this)
        this.state = {
            requestList: [],
            currentRequest: null
        }
    }

    async getAllRequests() {
        var requestList = await meetPointService.getAllRequests()
        this.setState({ requestList })
    }

    async validate(e) {
        await meetPointService.debitOne(this.state.currentRequest.id, this.state.currentRequest.reference)
        window.location.reload()
    }

    componentDidMount() {
        this.getAllRequests()
    }

    render() {
        const { requestList, currentRequest } = this.state
        return (
            <Row className="mt-2 p-0 p-lg-3 mx-auto">
                <Col className="col-12 col-lg-8 px-0 px-lg-3" style={{ maxHeight: "70vw", overflowY: 'auto' }}>
                    <div className="h2 text-left text-primary col-auto text-xl">
                        <span>Virements à effectuer</span>
                    </div>
                    <Table className="table-hover  justify-content-center text-left">
                        <thead>
                            <tr>
                                <th>Titulaire</th>
                                <th>MP</th>
                                <th>€</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requestList.length > 0 &&
                                requestList.filter(request => request.type === "request").map((info, index) => (
                                    <tr key={index} className={info === currentRequest ? "cursor-pointer text-white bg-primary" : "cursor-pointer"} onClick={() => this.setState({ currentRequest: info })}>
                                        <td>{info.account_holder}</td>
                                        <td>{info.points}</td>
                                        <td>{info.amount}</td>
                                    </tr>

                                ))}
                        </tbody>
                    </Table>

                    <div className="h2 text-left text-primary col-auto text-xl">
                        <span>Historique</span>
                    </div>
                    <Table className="table-hover  justify-content-center text-left">
                        <thead>
                            <tr>
                                <th>Titulaire</th>
                                <th>MP</th>
                                <th>€</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requestList.length > 0 &&
                                requestList.filter(request => request.type === "debit").map((info, index) => (
                                    <tr key={index} className={info === currentRequest ? "cursor-pointer text-white bg-primary" : "cursor-pointer"} onClick={() => this.setState({ currentRequest: info })}>
                                        <td>{info.account_holder}</td>
                                        <td>{info.points}</td>
                                        <td>{info.amount}</td>
                                    </tr>

                                ))}
                        </tbody>
                    </Table>
                </Col>
                <Col className="col-12 col-lg-4 px-0 px-lg-3">
                    <Card className="mt-2">
                        {currentRequest && <Row>
                            <Col className="col-12 text-left">
                                Demande du : <b>{moment(currentRequest.creation_date).format('LLLL')}</b> <br />
                                Titulaire : <b>{currentRequest.account_holder}</b> <br />
                                Montant : <b>{currentRequest.amount} € </b>({currentRequest.points} MP) <br />
                                IBAN : <b>{currentRequest.iban}</b> <br />
                                BIC : <b>{currentRequest.bic}</b> <br />
                                Référence de virement : <b>{currentRequest.reference}</b> <br />
                                Date de la dernière modification : <b>{moment(currentRequest.modification_date).format('LLLL')}</b>
                            </Col>
                            <Col className="col-12 mt-2 pt-2 border-top">
                                {currentRequest.type === "request" ? 'Enregistrer' : 'Modifier'} la référence :
                                <Input type="text" label="Référence de virement" value={currentRequest.reference ? currentRequest.reference : ""}
                                    onChange={e => this.setState({
                                        currentRequest: { ...currentRequest, reference: e.target.value }
                                    })}
                                />
                                <Button className="mt-1" onClick={this.validate} color="primary" disabled={!currentRequest.reference}> {currentRequest.type === "request" ? 'Valider' : 'Modifier'} le virement</Button>
                            </Col>
                        </Row>
                        }
                    </Card>
                </Col>
            </Row>
        )
    }
}