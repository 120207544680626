export const getOperatingSystem = () => {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;

    if (platform.includes('Win')) return 'Windows';
    if (platform.includes('Mac')) return 'Mac OS';
    if (platform.includes('Linux') || platform.includes('X11')) {
        return userAgent.includes('Android') ? 'Android' : 'Linux';
    }
    if (platform.includes('iPhone')) return 'iOS';
    if (platform.includes('iPad')) return 'iPad';

    return 'Unknown';
};
export const getWebBrowser = () => {
    const userAgent = navigator.userAgent;

    if (userAgent.includes('Firefox')) return 'Firefox';
    if (userAgent.includes('SamsungBrowser')) return 'Samsung Browser';
    if (userAgent.includes('Opera') || userAgent.includes('OPR')) return 'Opera';
    if (userAgent.includes('Trident')) return 'Internet Explorer';
    if (userAgent.includes('Edge')) return 'Edge';
    if (userAgent.includes('Chrome')) return 'Chrome';
    if (userAgent.includes('Safari')) return 'Safari';

    return 'Unknown';
};