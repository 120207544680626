import http from '../http-common'
import accountHeader from './account-header'


class CandidateService {

  checkCandidate(idCandidate) {
    return http.get(`/candidates/${idCandidate}/check`)
      .then(result => {
        return result.data
      })
  }

  getCandidateAll(params={}) {
    return http.get('/candidates/search', { headers: accountHeader(), params }).then(response => {
      return response.data
    })
  }

  getCandidateByAccount = async (idAccount) => {
    return http.get(`/candidates/byAccount/${idAccount}`, { headers: accountHeader() }).then(response => {
      return response.data
    })
  }

  getCandidateInfo(idCandidate, withValidations = false, withMeetPoints = false) {
    return http.get(`/candidates/${idCandidate}`,
      { params: { withMeetPoints, withValidations }, headers: accountHeader() }).then(response => {
        return response.data
      })
  }

  getCandidateByOffer(idOffer) {
    return http.get(`/candidates/search/${idOffer}`, { headers: accountHeader() }).then(response => {
      return response.data
    }).catch((err) => {
      return false
    })
  }
  candidateEdit(body, idCandidate) {
    return http.post(
      `/candidates/${idCandidate}`,
      body,
      { headers: accountHeader() }
    )
  }
  candidateRegister(body) {
    return http.post(
      '/candidates', body, {}
    ).then(response => {
      return response.data
    }).catch((err) => {
      return err
    })
  }

  getAllUnvalidatedFiles() {
    return http.get(
      "/candidates/unvalidatedFiles",
      { headers: accountHeader() }
    ).then(response => {
      return response.data
    })
  }

  getAllFiles() {
    return http.get(
      "/candidates/files",
      { headers: accountHeader() }
    ).then(response => {
      return response.data
    })
  }

  validateFile(file, id) {
    return http.post(
      "/candidates/validateFile",
      { file, id },
      { headers: accountHeader() }
    )
  }
  refuseFile(file, id) {
    return http.post(
      "/candidates/refuseFile",
      { file, id },
      { headers: accountHeader() }
    )
  }
  getMyHistory() {
    return http.get(
      "candidates/myPointsHistory",
      { params: { withMeetPoints: true }, headers: accountHeader() }
    ).then(response => {
      return response.data
    })
  }
  requestPoints(data) {
    return http.post(
      "candidates/requestPoints",
      data,
      { headers: accountHeader() },
    )
  }
  deleteCandidate(id) {
    return http.delete(
      `candidates/${id}`,
      { headers: accountHeader() }
    )
  }
}

export default new CandidateService()