import React, { useState } from 'react'
import { Badge, Col, Row, UncontrolledTooltip } from 'reactstrap'
import accountConfig from '../config/account.config'
import miscConfig from '../config/misc.config'

function MarkerInTable(props) {
    const aPoint = props.aPoint
    const [isHovered, setIsHovered] = useState(false)

    const _onMouseEnter = () => {
        setIsHovered(true)
        if (aPoint.offer) {
            props.setActiveMarker(aPoint.offer.id)
        } else {
            props.setActiveMarker(aPoint.candidate.id)
        }
    }
    const _onMouseLeave = () => {
        setIsHovered(false)
        props.setActiveMarker("")
    }

    const _onClick = () => {
        if (props.aPoint.offer) {
            props.setClickedMarker(props.aPoint.offer.id)
        } else {
            props.setClickedMarker(props.aPoint.candidate.id)
        }
    }

    var key, lat, lng, nompharma, id, distance, shifts, contract, remuneration, logo, position
    if (props.mapType === 'candidate') {
        key = aPoint.offer.id
        lat = aPoint.offer.pharmacy.latitude
        lng = aPoint.offer.pharmacy.longitude
        nompharma = aPoint.offer.pharmacy.name
        id = aPoint.offer.id
        distance = aPoint.similarities.distance
        shifts = aPoint.similarities.shifts
        contract = aPoint.offer.disponibility.contract_type
        remuneration = aPoint.offer.disponibility.salary
        logo = aPoint.offer.pharmacy.logo
        position = aPoint.offer.disponibility.position
    } else {
        key = aPoint.candidate.id
        lat = aPoint.candidate.latitude
        lng = aPoint.candidate.longitude
        nompharma = aPoint.candidate.name
        id = aPoint.candidate.id
        distance = aPoint.similarities.distance
        shifts = aPoint.similarities.shifts
        contract = aPoint.candidate.disponibility.contract_type
        remuneration = aPoint.candidate.disponibility.salary
        position = aPoint.candidate.disponibility.position
    }
    return (
        <Row className={"border-bottom border-bottom-2 m-0 rounded py-3 cursor-pointer " + ((props.activeMarker === id || isHovered) && " bg-lighter")}
            onClick={() => _onClick()}
            key={key}
            lat={lat}
            lng={lng}
            nompharma={nompharma}
            id={id}
            distance={distance}
            shifts={shifts}
            contract={contract}
            remuneration={remuneration}

            onMouseEnter={() => _onMouseEnter()}
            onMouseLeave={() => _onMouseLeave()}
        >
            <Col className="col-4 col-lg-3 d-flex justify-content-center">
                <div
                    alt="Meetpharma Logo"
                    className="navbar-brand-img border border-light rounded w-100 h-100"
                    style={{
                        backgroundImage: "url('" + (logo ? logo : require("../../assets/img/default-thumbnail-profil.jpg").default) + "')", // TODO add Pharmacy avatar
                        backgroundPosition: "center",
                        backgroundSize: "cover"
                    }}>
                </div>
            </Col>
            <Col className="col-8 col-lg-9 text-left">
                <h3 className="text-primary h2 mb-0 mb-lg-2">{nompharma}</h3>
                <p className="text-secondary mb-0 mb-lg-2">Poste : {accountConfig.positionList[position]}</p>
                <Row className="h1 d-none d-lg-flex">
                    <Col className="col-auto pr-0">
                        <Badge className="px-3 bg-primary text-lowercase"><i className="fas fa-map-marker-alt" /><span className="pl-1">{Math.ceil(distance)} km</span></Badge>
                    </Col>
                    <Col className="col-auto pr-0">
                        <Badge color="secondary" className="px-3"><span>{contract}</span></Badge>
                    </Col>
                    <Col className="col-auto pr-0">
                        <Badge className="bg-gray px-3 text-lowercase"><span>{remuneration} € / h</span></Badge>
                    </Col>
                </Row>
            </Col>
            <Col className="col-12 h1 d-flex d-lg-none mt-2 mb--1">
                <Row>
                    <Col className="col-auto pr-0">
                        <Badge className="px-3 bg-primary text-lowercase"><i className="fas fa-map-marker-alt" /><span className="pl-1">{Math.ceil(distance)} km</span></Badge>
                    </Col>
                    <Col className="col-auto pr-0">
                        <Badge color="secondary" className="px-3"><span>{contract}</span></Badge>
                    </Col>
                    <Col className="col-auto pr-0">
                        <Badge className="bg-gray px-3 text-lowercase"><span>{remuneration} € / h</span></Badge>
                    </Col>
                    {aPoint.candidate?.cv_validated &&
                        <Col className="col-auto pr-0">
                            <Badge className="bg-default px-3 text-lowercase" id={"cv-validated-" + id}><i className="fas fa-user-check" /></Badge>
                            <UncontrolledTooltip delay={0}
                                placement="bottom"
                                target={"cv-validated-" + id}> CV vérifié </UncontrolledTooltip>
                        </Col>
                    }
                    {aPoint.candidate?.diploma_validated &&
                        <Col className="col-auto pr-0">
                            <Badge className="bg-default px-3 text-lowercase" id={"diploma-validated-" + id}><i className="fas fa-clipboard-check" /></Badge>
                            <UncontrolledTooltip delay={0}
                                placement="bottom"
                                target={"diploma-validated-" + id}> Diplôme vérifié </UncontrolledTooltip>
                        </Col>
                    }
                    {aPoint.candidate?.kbis_validated &&
                        <Col className="col-auto pr-0">
                            <Badge className="bg-default px-3 text-lowercase" id={"kbis-validated-" + id}><i className="fas fa-building" /></Badge>
                            <UncontrolledTooltip delay={0}
                                placement="bottom"
                                target={"kbis-validated-" + id}> Kbis vérifié </UncontrolledTooltip>
                        </Col>
                    }
                </Row>
            </Col>
        </Row>
    )
}
export default MarkerInTable