export default function RGPD(props) {
    return (<div className="text-left legal-text">
        <p align="center" className="text-primary h1 pb-4">
            <strong>Politique de Confidentialité et Mise en Conformité RGPD</strong>
        </p>
        <br/>
        <p>
            Plateforme de Mise en Relation Entre Employeurs et Travailleurs du Secteur de la Santé en Pharmacie  d'Officine
        </p>
        <br/>
        <p>
            <strong>1. </strong>
            <strong>Introduction</strong>
        </p>
        <p>
            La présente politique de confidentialité décrit comment MEETPHARMA collecte, utilise, et protège les  données personnelles des utilisateurs de notre plateforme de mise en relation entre employeurs et  travailleurs du secteur de la santé en pharmacie d'officine, conformément au Règlement Général sur  la Protection des Données (RGPD).
        </p>
        <p>
            <strong>2. </strong>
            <strong>Responsable du traitement des données </strong>
        </p>
        <p>
            Le responsable du traitement des données est MEETPHARMA dont le siège social est situé au 182  AVENUE JEAN LOLIVE 93500 PANTIN
        </p>
        <p>
            Pour toute question concernant cette politique de confidentialité, veuillez nous contacter à
            <a href="mailto:contact@meetpharma.fr">contact@meetpharma.fr</a>
        </p>
        <p>
            <strong>3. </strong>
            <strong>Données collectées </strong>
        </p>
        <p>
            Nous collectons les données personnelles suivantes :
        </p>
        <ul type="disc">
            <li>
                - Employeurs : Nom, prénom, adresse e-mail, numéro de téléphone, adresse de l'officine,  informations professionnelles, CV et lettres de motivation des candidats.

            </li>
            <li>
                - Travailleurs : Nom, prénom, adresse e-mail, numéro de téléphone, qualifications professionnelles,  expériences professionnelles, CV, lettres de motivation.
            </li>
        </ul>

        <p>
            <strong>4. </strong>
            <strong>Finalités du traitement des données</strong>
        </p>
        <p>
            Les données collectées sont utilisées pour :
        </p>
        <ul type="disc">
            <li>- Faciliter la mise en relation entre employeurs et travailleurs.</li>
            <li>- Gérer et améliorer les services de la plateforme.</li>
            <li>- Communiquer avec les utilisateurs à propos de leurs candidatures et des offres d'emploi.</li>
            <li>- Respecter les obligations légales et réglementaires.</li>
        </ul>

        <p>
            <strong>5. </strong>
            <strong>Base légale du traitement</strong>
        </p>
        <p>
            Le traitement de vos données personnelles est basé sur :
        </p>
        <ul type="disc">
            <li>- <strong>Le consentement :</strong> En vous inscrivant sur notre plateforme, vous consentez au traitement de vos données pour les finalités mentionnées ci-dessus.</li>
            <li>- <strong>L'exécution d'un contrat :</strong> Le traitement est nécessaire à l'exécution des services fournis par notre plateforme.</li>
            <li>- <strong>Les obligations légales :</strong> Nous traitons vos données pour respecter nos obligations légales et réglementaires.</li>
        </ul>

        <p>
            <strong>6. </strong>
            <strong>Partage des données</strong>
        </p>
        <p>
            Vos données personnelles peuvent être partagées avec :
        </p>
        <ul type="disc">
            <li>- Les employeurs ou travailleurs inscrits sur notre plateforme pour faciliter la mise en relation.</li>
            <li>- Nos prestataires de services techniques et administratifs, dans la mesure où cela est nécessaire pour la fourniture de nos services.</li>
            <li>- Les autorités compétentes, si la loi l'exige.</li>
        </ul>

        <p>
            <strong>7. </strong>
            <strong>Durée de conservation des données</strong>
        </p>
        <p>
            Vos données personnelles sont conservées aussi longtemps que nécessaire pour atteindre les finalités pour lesquelles elles ont été collectées, et en conformité avec les délais légaux de conservation.
        </p>

        <p>
            <strong>8. </strong>
            <strong>Droits des utilisateurs</strong>
        </p>
        <p>
            Conformément au RGPD, vous disposez des droits suivants :
        </p>
        <ul type="disc">
            <li>- <strong>Droit d'accès :</strong> Vous pouvez demander à accéder à vos données personnelles.</li>
            <li>- <strong>Droit de rectification :</strong> Vous pouvez demander la correction des données inexactes ou incomplètes.</li>
            <li>- <strong>Droit à l'effacement :</strong> Vous pouvez demander la suppression de vos données personnelles.</li>
            <li>- <strong>Droit à la limitation du traitement :</strong> Vous pouvez demander la limitation du traitement de vos données dans certains cas.</li>
            <li>- <strong>Droit d'opposition :</strong> Vous pouvez vous opposer au traitement de vos données personnelles pour des motifs légitimes.</li>
            <li>- <strong>Droit à la portabilité des données :</strong> Vous pouvez demander à recevoir vos données personnelles dans un format structuré, couramment utilisé et lisible par machine, et à les transmettre à un autre responsable de traitement.</li>
        </ul>
        <p>
            Pour exercer ces droits, veuillez nous contacter à
            <a href="mailto:contact@meetpharma.fr">contact@meetpharma.fr</a>
        </p>

        <p>
            <strong>9. </strong>
            <strong>Sécurité des données</strong>
        </p>
        <p>
            Nous mettons en œuvre des mesures techniques et organisationnelles appropriées pour protéger vos données personnelles contre la destruction, la perte, l'altération, la divulgation ou l'accès non autorisé.
        </p>

        <p>
            <strong>10. </strong>
            <strong>Modifications de la politique de confidentialité</strong>
        </p>
        <p>
            Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page et vous sera notifiée par e-mail si nécessaire.
        </p>

        <p>
            <strong>11. </strong>
            <strong>Contact</strong>
        </p>
        <p>
            Pour toute question concernant cette politique de confidentialité ou pour exercer vos droits, veuillez nous contacter à :
        </p>
        <ul type="none">
            <li>MEETPHARMA</li>
            <li>182 AVENUE JEAN LOLIVE 93500 PANTIN</li>
            <li><a href="mailto:contact@meetpharma.fr">contact@meetpharma.fr</a></li>
            <li>Téléphone : 06 58 05 47 62</li>
        </ul>

        <p>
            <strong>Date de la dernière mise à jour : </strong>25/07/2024
        </p>
    </div>)

}