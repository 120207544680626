import React, { Component } from "react"
import candidateService from "../services/candidate.service"
import pharmacyService from "../services/pharmacy.service"
import accountConfig from "./config/account.config"
import Datetime from "react-datetime"
import "moment/locale/fr"
import moment from "moment"
import miscUtils from "../utils/misc.utils"
import Select from 'react-select'
import ShiftDisplay from "./shift/shiftDisplay.component"
import SliderToggle from "../components/forms/sliderToggle"
import FileForm from "../components/forms/fileForm"
import CountUp from 'react-countup';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, Popover, message, Button as AntButton, Alert, Card, Descriptions, Tag } from 'antd';
import {
    Row,
    Col,
    Button,
    Input,
    Badge,
    InputGroup,
    InputGroupAddon,
    Toast,
    ToastHeader,
    ToastBody,
} from 'reactstrap'
import Cropper from "./cropper.component"
import ShiftCalendarDisplay from "./shift/shiftCalendarDisplay.component"
import miscConfig from "./config/misc.config"
import stripeService from "../services/stripe.service"
import accountService from "../services/account.service"

const { confirm } = Modal;

export default class EditProfile extends Component {
    constructor(props) {
        super(props)
        this.userInfo = this.retrieveUser()
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handlePositionChange = this.handlePositionChange.bind(this)
        this.handleContractTypeChange = this.handleContractTypeChange.bind(this)
        this.saveEdit = this.saveEdit.bind(this)
        this.checkFlexibility = this.checkFlexibility.bind(this)
        this.handleSalaryChange = this.handleSalaryChange.bind(this)
        this.handleInfoChange = this.handleInfoChange.bind(this)
        this.handleSoftwareChange = this.handleSoftwareChange.bind(this)
        this.shiftsUpdate = this.shiftsUpdate.bind(this)
        this.checkTwoWeeks = this.checkTwoWeeks.bind(this)
        this.checkStandBy = this.checkStandBy.bind(this)
        this.checkAutoEntrepreneur = this.checkAutoEntrepreneur.bind(this)
        this.handleFileChange = this.handleFileChange.bind(this)
        this.removeFileChange = this.removeFileChange.bind(this)
        this.handleCancelSubscription = this.handleCancelSubscription.bind(this)
        this.shiftDisplayRef = null
        this.shiftCalendarRef = null
        this.state = {
            saveAlert: false,
            changed: false,
            twoWeeks: true,
            id: "",
            listDisponibilities: "",
            userName: "",
            userLastName: "",
            email: this.props.user.email,
            //specifiques pharmacy
            ownerLastname: "",
            ownerFirstname: "",
            siret: "",
            phone: "",
            address: "",
            city: "",
            zip: "",
            lat: "",
            lng: "",
            logo: "",
            banner: "",
            softwareList: "",
            shifts: "",
            diploma: "",
            diplomaValidated: false,
            diplomaTmp: null,
            cv: "",
            cvTmp: null,
            cvValidated: false,
            kbis: "",
            kbisTmp: null,
            kbisValidated: false,
            moTimes: [],
            tuTimes: [],
            weTimes: [],
            thTimes: [],
            frTimes: [],
            saTimes: [],
            suTimes: [],
            moTimes2: [],
            tuTimes2: [],
            weTimes2: [],
            thTimes2: [],
            frTimes2: [],
            saTimes2: [],
            suTimes2: [],
            disableCancel: false,
            isCanceled: false,
            currentPeriodEnd: null,
            subscriptionsNumber: 0,
            subscription: null
        }
    }

    logOut() {
        accountService.logout()
    }

    async handleCancelSubscription(email) {
        const response = await stripeService.cancelSubscription(email);
        message.success('Votre abonnement a été annulé.');
        if (this.state.currentPeriodEnd === new Date()) {
            // this.logOut()
        } else {
            window.location.reload()
        }
        return response
    }

    async retrieveUser() {
        var userInfo
        if (this.props.user.role === "candidat") {
            userInfo = this.props.candidate
            let dateStart = moment(userInfo.dateStart).format("D/MM/YYYY")
            let dateEnd
            if (userInfo.dateEnd) {
                dateEnd = moment(userInfo.dateEnd).format("D/MM/YYYY")
            } else {
                dateEnd = ""
            }
            let listDisponibilities = [{
                salary: userInfo.salary,
                contractType: userInfo.contractType,
                position: userInfo.position,
                dateStart: dateStart,
                dateEnd: dateEnd,
                shiftsFlexibility: userInfo.shiftsFlexibility,
                shifts: userInfo.shifts, //not used
                info: userInfo.info,
                autoEntrepreneur: userInfo.auto_entrepreneur,
            }]
            if (!userInfo.info) {
                listDisponibilities[0].info = ""
            }
            if (userInfo.shifts.substr(0, 336) === userInfo.shifts.substr(336)) {
                this.setState({ twoWeeks: false })
            } else {
                this.setState({ twoWeeks: true })
            }
            var mySoftwareList = []
            if (userInfo.software) {
                userInfo.software.forEach(softwareValue => {
                    // let software= 

                    accountConfig.softwareList2.find((software, index) => {
                        if (software.value === softwareValue) {
                            mySoftwareList.push(software)
                            return true
                        }
                        return false
                    })
                })
            }
            var shiftsList = userInfo.shifts.match(/.{1,48}/g)
            var nbShifts = 0
            shiftsList.forEach(day => {
                let nameList = accountConfig.dayEnum[nbShifts]
                nbShifts++
                var listShifts = []
                let found1 = false
                for (let i = 0; i < day.length; i++) {
                    if (day[i] === "1" && found1 === false) {
                        found1 = true
                        listShifts.push(accountConfig.timeEnum[i])
                    } else if (day[i] === "0" && found1 === true) {
                        found1 = false
                        listShifts.push(accountConfig.timeEnum[i - 1])
                    } else if (i === 47 && found1 === true) {
                        listShifts.push(accountConfig.timeEnum[i])
                    }
                }
                this.setState({ [nameList]: listShifts })
            })
            this.setState({
                id: await userInfo.candidateId, userName: await userInfo.candidateName, userLastName: await userInfo.candidateLastName, listDisponibilities, softwareList: mySoftwareList, shifts: userInfo.shifts, diploma: userInfo.diploma, cv: userInfo.cv, kbis: userInfo.kbis, diplomaValidated: userInfo.diplomaValidated, cvValidated: userInfo.cvValidated, kbisValidated: userInfo.kbisValidated, meetPoints: userInfo.meetPoints, standby_mode: userInfo.standby_mode, phone: userInfo.phone
            })
        } else if (this.props.user.role === "titulaire") {
            userInfo = this.props.pharmacy
            if (!userInfo.firstName) {
                userInfo.firstName = "Non renseigné"
            }
            if (!userInfo.lastName) {
                userInfo.lastName = "Non renseigné"
            }
            let mySoftware = ""
            accountConfig.softwareList2.find((software, index) => {
                if (software.value === userInfo.software) {
                    mySoftware = software
                    return true
                }
                return false
            })
            this.setState({ id: await userInfo.pharmacyId, userName: userInfo.pharmacyName, address: userInfo.address, ownerFirstname: userInfo.firstName, ownerLastname: userInfo.lastName, siret: userInfo.siret, city: userInfo.city, zip: userInfo.zip, logo: userInfo.logo, banner: userInfo.banner, phone: userInfo.phone, lat: userInfo.latitude, lng: userInfo.longitude, softwareList: mySoftware })
        }
    }

    handleInputChange(e) {
        const target = e.target
        const value = target.value
        const name = target.name
        if ((name === "phone" && isNaN(value)) || (name === "phone" && value.length > 10)) {
            return
        }
        this.setState({ [name]: value })
    }
    async handleFileChange(e) {
        const target = e.target
        const name = target.name
        const file = target.files[0]

        const reader = new FileReader()
        await reader.readAsDataURL(file)
        reader.onload = function (e) {
            this.setState({ [name]: reader.result })
            this.setState({ [name + "Tmp"]: reader.result })
        }.bind(this)
    }
    removeFileChange(label) {
        this.setState({ [label]: null })
        this.setState({ [label + "Tmp"]: "delete" })
    }

    handlePositionChange(e) { // for Candidates only
        const target = e.target
        const value = target.value
        var newDisponibility = this.state.listDisponibilities[0]
        newDisponibility.position = value
        this.setState({ listDisponibilities: [newDisponibility] })
    }

    handleContractTypeChange(e) { // for Candidates only
        const target = e.target
        const value = target.value
        var newDisponibility = this.state.listDisponibilities[0]
        newDisponibility.contractType = value
        this.setState({ listDisponibilities: [newDisponibility] })
    }

    handleSalaryChange(e) {
        const target = e.target
        const value = target.value
        var newDisponibility = this.state.listDisponibilities[0]
        newDisponibility.salary = value
        this.setState({ listDisponibilities: [newDisponibility] })
    }
    handleInfoChange(e) {
        const target = e.target
        const value = target.value
        var newDisponibility = this.state.listDisponibilities[0]
        newDisponibility.info = value
        this.setState({ listDisponibilities: [newDisponibility] })
    }

    checkFlexibility() {
        var newDisponibility = this.state.listDisponibilities[0]
        newDisponibility.shiftsFlexibility = !newDisponibility.shiftsFlexibility
        this.setState({ listDisponibilities: [newDisponibility] })
    }

    async checkTwoWeeks() {
        await this.setState({ twoWeeks: !this.state.twoWeeks })
        this.shiftDisplayRef.updateTwoWeeks()
        this.shiftCalendarRef.checkTwoWeeks()
    }

    async checkStandBy() {
        await this.setState({ standby_mode: !this.state.standby_mode })
    }

    async checkAutoEntrepreneur() {
        var newDisponibility = this.state.listDisponibilities[0]
        newDisponibility.autoEntrepreneur = !newDisponibility.autoEntrepreneur
        this.setState({ listDisponibilities: [newDisponibility] })
    }
    handleDateChange(e, date) {
        const value = e._d
        var newDisponibility = this.state.listDisponibilities[0]
        if (date === "start") {
            newDisponibility.dateStart = moment(value, "D/MM/YYYY")
            this.setState({ listDisponibilities: [newDisponibility] })
        }
        if (date === "end") {
            newDisponibility.dateEnd = moment(value, "D/MM/YYYY")
            this.setState({ listDisponibilities: [newDisponibility] })
        }
    }

    handleSoftwareChange(e) {
        if (this.props.user.role === "candidat") {
            var newSoftwareList = []
            e.forEach(software => {
                newSoftwareList.push(software)
            });
            this.setState({ softwareList: newSoftwareList })
        } else {
            this.setState({ softwareList: e })
        }
    }

    shiftsUpdate(shifts, daySchedule, dayName, pos) {
        let newShifts = miscUtils.replaceAt(this.state.shifts, pos, shifts)
        this.setState({ [dayName]: daySchedule, shifts: newShifts })
        this.shiftCalendarRef.turnShiftsIntoDays(newShifts)
    }

    async saveEdit() {
        var body = {}
        if (this.props.user.role === "candidat") {
            var mySoftwareList = []
            this.state.softwareList.forEach(software => {
                mySoftwareList.push(software.value)
            });
            body.software = mySoftwareList
            if (moment(this.state.listDisponibilities[0].dateEnd, "D/MM/YYYY").isBefore(moment(this.state.listDisponibilities[0].dateStart, "D/MM/YYYY"))) {
                return alert("La date de fin  doit être après la date de début")
            }
            var disponibility = this.state.listDisponibilities[0]
            if (this.state.twoWeeks) {
                body.shifts = this.state.shifts
            } else {
                let myShifts = this.state.shifts.substr(0, 336)
                body.shifts = myShifts + myShifts
            }
            body.dateStart = new Date(disponibility.dateStart)
            if (disponibility.dateEnd) {
                body.dateEnd = new Date(disponibility.dateEnd)
            }
            body.contractType = disponibility.contractType
            body.salary = disponibility.salary
            body.position = disponibility.position
            body.standby_mode = this.state.standby_mode
            body.firstName = this.state.userName
            body.lastName = this.state.userLastName
            if (disponibility.shiftsFlexibility) {
                body.shiftsFlexibility = 1
            } else {
                body.shiftsFlexibility = 0
            }
            body.info = disponibility.info
            body.auto_entrepreneur = disponibility.autoEntrepreneur
            body.diploma = this.state.diploma
            body.cv = this.state.cv
            body.kbis = this.state.kbisTmp
            if (this.state.phone?.toString().length === 10 || this.state.phone?.toString().length === 0)
                body.phone = this.state.phone
            await candidateService.candidateEdit(body, this.state.id)
        } else {
            if (this.state.phone?.toString().length === 10 || this.state.phone?.toString().length === 0)
                body.phone = this.state.phone
            body.pharmacyName = this.state.userName
            body.firstName = this.state.ownerFirstname
            body.lastName = this.state.ownerLastname
            body.siret = this.state.siret
            body.latitude = this.state.lat
            body.longitude = this.state.lng
            body.software = this.state.softwareList.value
            body.logo = this.state.logo
            body.banner = this.state.banner
            await pharmacyService.pharmacyEdit(body, this.state.id)
        }
        //Displaying alert
        this.setState({ saveAlert: true })
        //automatically close the alert
        this.setState({
            saveTimeout: setTimeout(async function () {
                this.setState({ saveAlert: false })
            }.bind(this), 5000)
        })

    }

    async componentDidMount() {
        await this.retrieveUser()

        // Fetch subscription details
        const response = await stripeService.getSubscriptionDetails(this.state.email);
        // Ensure response is valid
        if (response) {
            // Check if the conditions are met to disable cancel button
            if (response.priceId === process.env.REACT_APP_STRIPE_ANNUAL_PRODUCT_PRICE_ID &&
                response.subscriptionsNumber <= 12) {
                // Set disableCancel state
                await this.setState({
                    disableCancel: true,
                    subscriptionsNumber: response.subscriptionsNumber,
                    subscription: response.subscription
                });
            }

            await this.setState({
                isCanceled: response.isCanceled,
                currentPeriodEnd: response.currentPeriodEnd,
                subscriptionsNumber: response.subscriptionsNumber,
                subscription: response.subscription
            });

        } else {
            console.log('No subscription details found');
        }
    }

    showDeleteConfirm = () => {
        confirm({
            title: 'Êtes-vous sûr de vouloir supprimer l\'abonnement ?',
            icon: <ExclamationCircleFilled />,
            centered: true,
            content: 'Cette action est irréversible et entraînera l\'annulation de votre abonnement.',
            okText: 'Oui, annuler l\'abonnement',
            okType: 'danger',
            cancelText: 'Non',
            width: 500,
            onOk: () => {
                console.log('OK');
                this.handleCancelSubscription(this.state.email);
            },
            onCancel() {
                console.log('Annulation');
            },
        });
    };

    handleReactivateSubscription = () => {
        this.props.history.push(`/pricingTable?email=${encodeURIComponent(this.state.email)}`);
    };

    handleSubscribe = () => {
        this.props.history.push(`/pricingTable?email=${encodeURIComponent(this.state.email)}`);
    };

    render() {
        const { saveAlert, listDisponibilities, userName, email, ownerFirstname, ownerLastname, phone, softwareList, twoWeeks, shifts, userLastName, moTimes, tuTimes, weTimes, thTimes, frTimes, saTimes, suTimes, moTimes2, tuTimes2, weTimes2, thTimes2, frTimes2, saTimes2, suTimes2, diploma, diplomaValidated, cv, cvValidated, kbis, kbisValidated, logo, banner, meetPoints, standby_mode, siret, disableCancel, isCanceled, currentPeriodEnd, subscription, subscriptionsNumber } = this.state

        // Compute statusMessage and actionButton based on subscription
        let statusMessage = '';
        let actionButton = null;

        if (subscription) {
            const { status, current_period_end, trial_end, priceId, cancel_at_period_end } = subscription;
            const formatDate = (timestamp) => moment.unix(timestamp).format("MMMM Do YYYY, h:mm:ss a");

            switch (status) {
                case "active":
                    const nextBillingDate = formatDate(current_period_end);
                    if (cancel_at_period_end === true) {
                        const cancelAtDate = formatDate(subscription.cancel_at);
                        statusMessage = `Votre abonnement a été annulé mais il est actif jusqu'à ${cancelAtDate}.`;
                    } else {
                        statusMessage = `Votre abonnement est actif.\nLa prochaine date de facturation est ${nextBillingDate}.`;

                        if (subscription.plan.id === process.env.REACT_APP_STRIPE_ANNUAL_PRODUCT_PRICE_ID && subscriptionsNumber < 12) {
                            statusMessage += "\nVous ne pouvez pas annuler votre abonnement premium juste après un an.";
                        }
                    }
                    actionButton = (
                        <AntButton onClick={this.showDeleteConfirm} disabled={disableCancel || cancel_at_period_end === true}>
                            Annuler l'abonnement
                        </AntButton>
                    );
                    break;
                case "canceled":
                    const endDate = formatDate(current_period_end);
                    statusMessage = `Votre abonnement a été résilié. Réactivez si vous le souhaitez.`;
                    actionButton = (
                        <AntButton type="primary" onClick={this.handleReactivateSubscription}>
                            Réactiver l'abonnement
                        </AntButton>
                    );
                    break;
                case "trialing":
                    const trialEndDate = formatDate(trial_end);
                    if (cancel_at_period_end === true) {
                        const cancelAtDate = formatDate(subscription.cancel_at);
                        statusMessage = `Votre abonnement a été annulé mais est actif jusqu'à ${cancelAtDate}.`;
                    } else {
                        statusMessage = `Vous êtes en période d'essai jusqu'à ${trialEndDate}.`;
                    }
                    // Ensure the user can cancel during trial regardless of other conditions
                    actionButton = (
                        <AntButton onClick={this.showDeleteConfirm} disabled={cancel_at_period_end === true}>
                            Annuler l'abonnement
                        </AntButton>
                    );
                    break;
                default:
                    statusMessage = "Le statut de votre abonnement n'est actuellement pas disponible.";
                    actionButton = (
                        <AntButton disabled>
                            Action indisponible
                        </AntButton>
                    );
            }
        } else {
            statusMessage = "Vous n'avez pas d'abonnement actif. Cliquez sur le bouton ci-dessous pour vous abonner.";
            actionButton = (
                <AntButton type="primary" onClick={this.handleReactivateSubscription}>
                    S'abonner
                </AntButton>
            );
        }

        return (<>
            <Toast className="position-fixed rounded mb-6 mb-lg-0" style={{ right: '10px', bottom: '10px', zIndex: 1080 }} color="info" isOpen={saveAlert}>
                <ToastHeader className="bg-gray-dark rounded-top p-1 text-white font-weight-light" toggle={() => this.setState({ saveAlert: false })}></ToastHeader>
                <ToastBody className="bg-gray-dark rounded-bottom p-4 pt-2 text-white font-weight-light"><i className="fa fa-check-circle fa-lg pr-2" />Les modifications ont bien été enregistrées</ToastBody>
            </Toast>
            <Row className="justify-content-md-center bg-banner">
                <Col className="my-3 col-12 col-lg-8 text-left h2 text-white " >Mon profil</Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col className="mt-5 col-12 col-md-5 col-lg-4 text-left">
                    {this.props.user.role === "candidat" &&
                        <Row>
                            <Col>
                                <div className="h1 text-primary mb-4 mt--2" style={{ lineHeight: '1.1' }}>Mes préférences de recherche</div>
                            </Col>
                            {/* <Col className="col-auto text-left">
                                <InputGroup className="cursor-pointer shadow" onClick={() => { this.props.history.push("/cagnotte") }}>
                                    <InputGroupAddon addonType="prepend">
                                        <div className="border border-primary py-1 rounded-left text-lg font-weight-bold px-4">
                                            <div className="mt--4 ml--2 pr-4">
                                                <Badge className=" shadow-sm" style={{ backgroundColor: '#fbfbfb' }}>
                                                    <span className="text-primary text-xs text-capitalize">MeetPoints</span>
                                                </Badge>
                                            </div>
                                            <CountUp
                                                start={0}
                                                end={meetPoints}
                                                delay={0}
                                                duration={2.75}
                                                separator=" "
                                                decimals={0}
                                            >
                                                {({ countUpRef }) => (
                                                    <div>
                                                        <span ref={countUpRef} />
                                                    </div>
                                                )}
                                            </CountUp>
                                        </div>
                                    </InputGroupAddon>
                                    
                                    <InputGroupAddon addonType="append">
                                        <Button color="primary py-1" style={{ lineHeight: "1.1" }}>Accéder à <br />ma cagnotte</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col> */}
                        </Row>
                    }
                    {this.props.user.role === "titulaire" &&
                        <Row>
                            <Col>
                                <div className="h1 text-primary mb-4 mt--2" style={{ lineHeight: '1.1' }}>Les informations<br />de ma pharmacie</div>
                            </Col>
                            {/* <Col className="col-auto text-left">
                                <InputGroup className="cursor-pointer shadow" onClick={() => { this.props.history.push("/subscription") }}>
                                    <InputGroupAddon addonType="prepend">

                                        <div className="border border-primary py-1 rounded-left text-lg font-weight-bold px-4">
                                            <div className="mt--4 ml--2 pr-4">
                                                <Badge href="/subscription" className=" shadow-sm" style={{ backgroundColor: '#fbfbfb' }}>
                                                    <span className="text-primary text-xs text-capitalize">Mon abonnement</span>
                                                </Badge>
                                            </div>
                                            {this.props.pharmacy.is_paid ? "Abonné" : "Non abonné"}
                                        </div>
                                    </InputGroupAddon>

                                    <InputGroupAddon addonType="append">
                                        <Button color="primary py-1" style={{ lineHeight: "1.1" }}>Gérer mon<br />Abonnement</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col> */}
                        </Row>}
                    {(this.props.user.role === "candidat" && listDisponibilities[0]) &&
                        <div><p className="font-weight-bold mb-1">Poste recherché</p>
                            <Input className="profile-field" type="select" value={listDisponibilities[0].position} onChange={this.handlePositionChange}>
                                {accountConfig.positionList && Object.keys(accountConfig.positionList).map((key, index) => {
                                    return <option key={index} value={key}>{accountConfig.positionList[key]}</option>
                                })}
                            </Input>
                        </div>
                    }
                    {(this.props.user.role === "candidat" && listDisponibilities[0]) &&
                        <div className="mt-2"><p className="font-weight-bold mb-1">Contrat recherché</p>
                            <Input className="profile-field" type="select" value={listDisponibilities[0].contractType} onChange={this.handleContractTypeChange} >
                                {accountConfig.contractTypes && accountConfig.contractTypes.map((contract, index) => {
                                    return <option key={index} value={contract}>{contract}</option>
                                })}
                            </Input>
                        </div>
                    }
                    {(this.props.user.role === "candidat" && listDisponibilities[0]) &&
                        <div className="mt-2"><p className="font-weight-bold mb-1">Taux horaire</p>
                            <Input className="profile-field" type="select" value={listDisponibilities[0].salary} onChange={this.handleSalaryChange} >
                                {accountConfig.salaryEnum && Object.keys(accountConfig.salaryEnum).map((key, index) => {
                                    return <option key={index} value={key}>{accountConfig.salaryEnum[key]}</option>
                                })}</Input>
                            <p className="text-right text-sm">
                                <a href="https://www.lemoniteurdespharmacies.fr/emploi/grille-des-salaires/consulter-la-grille.html" target="_blank" rel="noreferrer">Aide grille salariale</a>
                            </p>
                        </div>
                    }

                    {(this.props.user.role === "candidat" && listDisponibilities[0]) &&
                        <div className="mt-5">
                            <SliderToggle
                                label="Je souhaite que mon profil n'apparaisse plus dans les recherches
                                pharmaciens pour le moment"
                                value={standby_mode}
                                handleChange={this.checkStandBy}
                            />
                        </div>

                    }

                    {(this.props.user.role === "candidat" && listDisponibilities[0]) &&
                        <Row className="bg-lighter rounded text-left m-0 mt-2 py-3">
                            <Col className="col-12 mt--4"><Badge className=" bg-primary text-capitalize text-sm">Disponibilité</Badge></Col>
                            <Col>
                                <Row className="pt-2">
                                    <Col className="col-auto pt-2 pr-1"><span className="font-weight-bold">Du</span></Col>
                                    <Col>
                                        <Datetime className="profile-field" locale="fr" timeFormat={false} isValidDate={(e) => miscUtils.checkDate(e)} name="dateStart" value={listDisponibilities[0].dateStart} onChange={(e) => this.handleDateChange(e, "start")} />
                                    </Col>
                                    <Col className="col-auto pt-2 px-1"><span className="font-weight-bold">Au</span></Col>
                                    <Col>
                                        <Datetime className="profile-field rtl-calendar" locale="fr" timeFormat={false} isValidDate={(e) => miscUtils.checkDate(e)} name="dateEnd" value={listDisponibilities[0].dateEnd} onChange={(e) => this.handleDateChange(e, "end")} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                    {(this.props.user.role === "candidat" && listDisponibilities[0]) &&
                        <div className="mt-2">
                            <SliderToggle
                                label="Définir un planning différent pour les semaines paires et impaires"
                                value={twoWeeks}
                                handleChange={this.checkTwoWeeks}
                            />
                        </div>

                    }
                    {(this.props.user.role === "candidat" && listDisponibilities[0]) &&
                        <ShiftDisplay
                            ref={(instance) => { this.shiftDisplayRef = instance }}
                            parentTimes={{ moTimes, tuTimes, weTimes, thTimes, frTimes, saTimes, suTimes, moTimes2, tuTimes2, weTimes2, thTimes2, frTimes2, saTimes2, suTimes2 }}
                            parentShifts={shifts}
                            shiftsUpdate={this.shiftsUpdate}
                            twoWeeks={twoWeeks}
                        />
                    }
                    {(this.props.user.role === "candidat" && listDisponibilities[0]) &&
                        <div className="mt-2">
                            <SliderToggle
                                label="Je suis flexible sur les horaires"
                                value={listDisponibilities[0].shiftsFlexibility}
                                handleChange={this.checkFlexibility}
                            />
                        </div>
                    }

                    {this.props.user.role === "titulaire" &&
                        <Row>
                            <Col>
                                <div className="mt-2"><p className="font-weight-bold mb-1">Téléphone</p>
                                    <Input type="tel" name="phone" pattern="[0-9]{2}-[0-9]{2}-[0-9]{2}-[0-9]{2}-[0-9]{2}" value={phone} onChange={this.handleInputChange} />
                                </div>
                                <div className="mt-2"><p className="font-weight-bold mb-1">Nom du titulaire</p>
                                    <Input type="text" name="ownerLastname" value={ownerLastname} onChange={this.handleInputChange} />
                                </div>
                                <div className="mt-2"><p className="font-weight-bold mb-1">Prénom du titulaire</p>
                                    <Input type="text" name="ownerFirstname" value={ownerFirstname} onChange={this.handleInputChange} />
                                </div>
                                <div className="mt-2"><p className="font-weight-bold mb-1">SIRET</p>
                                    <Input type="text" name="siret" value={siret} onChange={this.handleInputChange} />
                                </div>
                                <div className="mt-2"><p className="font-weight-bold mb-1">LGO utilisé</p>
                                    <Select value={softwareList} name="softwareList" options={accountConfig.softwareList2} onChange={this.handleSoftwareChange} placeholder="Sélectionner" />
                                </div>
                            </Col>
                        </Row>
                    }
                    <Row className="mt-2"><Col><Button color="primary" onClick={this.saveEdit}>Sauvegarder les modifications</Button></Col></Row>
                    {this.props.user.role === "titulaire" && (
                        <Card
                            title="Détails de l'abonnement"
                            type="inner"
                            style={{ marginTop: "20px", marginBottom: "50px" }}
                            extra={<div>
                                {process.env.REACT_APP_STRIPE_ANNUAL_PRODUCT_PRICE_ID ===
                                    this.state.subscription?.plan?.id &&
                                    (this.state.subscription?.status === "active" || this.state.subscription?.status === "trialing") &&
                                    <Tag color="gold">Premium</Tag>
                                }
                                {process.env.REACT_APP_STRIPE_ANNUAL_PRODUCT_PRICE_ID !==
                                    this.state.subscription?.plan?.id &&
                                    (this.state.subscription?.status === "active" || this.state.subscription?.status === "trialing") &&
                                    <Tag color="processing">Basic</Tag>
                                }
                            </div>}
                        >
                            <Descriptions
                                column={1}
                                items={[{
                                    key: '2',
                                    label: 'Status',
                                    children: this.state.subscription?.status || "",
                                }, {
                                    key: '3',
                                    label: 'Status Message',
                                    children: statusMessage,
                                }]}
                            />
                            {/* <p>{statusMessage}</p> */}
                            {actionButton}
                            <Alert
                                style={{ marginTop: "20px" }}
                                description="Si vous avez des questions ou des problèmes avec votre abonnement, veuillez contacter le support à contact@meetpharma.fr."
                                type="info"
                                showIcon
                            />
                        </Card>
                    )}
                </Col>
                <Col className="my-5 ml-3 mr-3 px-4 px-md-4 ml-md-4 col-11 col-md-5 col-lg-4 bg-primary text-white rounded text-left">
                    <div className="mt--2 mb-2"><Badge className="text-capitalize bg-primary text-lg px-4 shadow">Mon profil</Badge></div>
                    {(this.props.user.role === "candidat" && listDisponibilities[0]) && <Row>
                        <Col className="pl-3 pr-1">
                            <div className="mt-2"><p className="font-weight-bold mb-1">Prénom</p>
                                <Input className="profile-field" type="text" name="userName" value={userName} onChange={this.handleInputChange} />
                            </div>
                        </Col>
                        <Col className="pr-3 pl-1">
                            <div className="mt-2"><p className="font-weight-bold mb-1">Nom</p>
                                <Input className="profile-field" type="text" name="userLastName" value={userLastName} onChange={this.handleInputChange} />
                            </div>
                        </Col>
                    </Row>
                    }
                    {(this.props.user.role === "candidat" && listDisponibilities[0]) &&
                        <div className="mt-2"><p className="font-weight-bold mb-1">Email</p>
                            <Input className="profile-field" type="text" value={email} disabled />
                        </div>
                    }
                    {(this.props.user.role === "candidat" && listDisponibilities[0]) &&
                        <div className="mt-2"><p className="font-weight-bold mb-1">Téléphone</p>
                            <Input className="profile-field" type="tel" name="phone" pattern="[0-9]{2}-[0-9]{2}-[0-9]{2}-[0-9]{2}-[0-9]{2}" value={phone} onChange={this.handleInputChange} />
                        </div>
                    }

                    {(this.props.user.role === "candidat" && listDisponibilities[0]) &&
                        <div className="mt-2"><p className="font-weight-bold mb-0">LGO maîtrisés</p>
                            <Select className="profile-field text-default" defaultValue={softwareList} closeMenuOnSelect={false} isMulti options={accountConfig.softwareList2} onChange={this.handleSoftwareChange} placeholder="Sélectionner" />
                        </div>
                    }
                    {(this.props.user.role === "candidat" && listDisponibilities[0]) &&
                        <div className="mt-2">
                            <SliderToggle
                                label="Je suis auto-entrepreneur"
                                color="white"
                                value={listDisponibilities[0].autoEntrepreneur}
                                handleChange={this.checkAutoEntrepreneur}
                            />
                        </div>
                    }

                    {(this.props.user.role === "candidat" && listDisponibilities[0]) &&
                        <div className="mt-2"><p className="font-weight-bold mb-1">Commentaire libre</p>
                            <Input type="textarea" name="info" rows="5" cols="" value={listDisponibilities[0].info} onChange={this.handleInfoChange} />

                            <p className="pt-2" style={{ lineHeight: 1.1, maxWidth: '440px' }}><span className="font-italic text-sm"><i className="font-italic fas fa-info-circle" /> Précisez ici toutes informations supplémentaires que vous jugez utile de partager avec les pharmaciens</span>
                            </p></div>
                    }
                    {(this.props.user.role === "candidat" && listDisponibilities[0]) &&
                        <div className="text-left mt-2">
                            <FileForm
                                name="diploma"
                                label="Votre dernier diplôme"
                                thumbnail
                                thumbnailLink={diploma}
                                value={diploma}
                                handleChange={this.handleFileChange}
                                handleReset={this.removeFileChange}
                            />
                            <span>{diploma && (diplomaValidated ? "Vérifié" : "En attente de vérification")}</span>
                        </div>
                    }
                    {(this.props.user.role === "candidat" && listDisponibilities[0]) &&
                        <div className="text-left mt-2">
                            <FileForm
                                name="cv"
                                label="Votre CV"
                                thumbnail
                                thumbnailLink={cv}
                                value={cv}
                                handleChange={this.handleFileChange}
                                handleReset={this.removeFileChange}
                            />
                            <span>{cv && (cvValidated ? "Vérifié" : "En attente de vérification")}</span>
                        </div>
                    }
                    {(this.props.user.role === "candidat" && listDisponibilities[0] && listDisponibilities[0].autoEntrepreneur) &&
                        <div className="text-left mt-2">
                            <FileForm
                                name="kbis"
                                label="Votre KBIS"
                                thumbnail
                                thumbnailLink={kbis}
                                value={kbis}
                                handleChange={this.handleFileChange}
                                handleReset={this.removeFileChange}
                            />
                            <span>{kbis && (kbisValidated ? "Vérifié" : "En attente de vérification")}</span>
                        </div>
                    }
                    {this.props.user.role === "candidat" && <p className="mt-1" style={{ lineHeight: 1.1, maxWidth: '440px' }}><span className="font-italic text-sm"><i className="font-italic fas fa-info-circle" /> Vos documents seront vérifiés par Meetpharma.</span>
                    </p>}

                    {this.props.user.role === "titulaire" &&
                        <div className="text-left mt-2">
                            <Cropper
                                label="Logo de votre pharmacie"
                                thumbnail
                                thumbnailLink={logo ? logo : null}
                                typeImage="logo"
                                handleInputChange={this.handleInputChange} />
                        </div>
                    }
                    {this.props.user.role === "titulaire" &&
                        <div className="text-left mt-2">
                            <Cropper
                                label="Bannière de votre pharmacie"
                                thumbnail
                                thumbnailLink={banner ? banner : null}
                                typeImage="banner"
                                handleInputChange={this.handleInputChange} />
                        </div>
                    }
                    <Button className="mt-3 mb-3" outline color="white" onClick={this.saveEdit}>Sauvegarder les modifications</Button>
                </Col>
            </Row>
            {(this.props.user.role === "candidat" && listDisponibilities[0]) &&
                <Row className="justify-content-md-center mt-6 mb-4">
                    <Col className="col-12 col-md-10 col-lg-8">
                        <div className="h2 text-left text-primary">Récapitulatif de vos disponibilités</div>
                        <hr className="my-2" />
                        <ShiftCalendarDisplay
                            ref={(instance) => { this.shiftCalendarRef = instance }}
                            parentShifts={shifts}
                            twoWeeks={twoWeeks}
                        />
                    </Col>
                </Row>
            }

        </>
        )
    }
}
