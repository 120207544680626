import React, { Component } from 'react'
// import OfferService from '../services/offer.service'
import Map from './map/map.component'
import Table from './map/table.component'
import OfferDataService from "../services/offer.service"
import CandidateService from '../services/candidate.service'
import AccountService from '../services/account.service'
import chatService from '../services/chat.service'
import cityService from '../services/city.service'
import candidateService from '../services/candidate.service'

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import {
    Row,
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Col,
    Badge
} from 'reactstrap'
import OfferDisplay from './offerDisplay.component'

export default class MapOffersInRadius extends Component {
    constructor(props) {
        super(props)
        this.retrieveOffers = this.retrieveOffers.bind(this)
        this.getRadius = this.getRadius.bind(this)
        this.setClickedOffer = this.setClickedOffer.bind(this)
        this.onSliderChange = this.onSliderChange.bind(this)
        this.onInputRadiusChange = this.onInputRadiusChange.bind(this)
        this.limitRange = this.limitRange.bind(this)
        this.onSearchCity = this.onSearchCity.bind(this)
        this.saveResearch = this.saveResearch.bind(this)
        this.findInArea = this.findInArea.bind(this)
        this.canUpdateMap = this.canUpdateMap.bind(this)
        this.deactivateOverlay = this.deactivateOverlay.bind(this)
        this.onDemandContact = this.onDemandContact.bind(this)
        this.tableRef = null
        this.user = null
        this.currentMapRef = null
        this.zoom = 0
        this.state = {
            user: {},
            content: "",
            activeMarker: null,
            offers: null,
            clickedOffer: null,
            radius: 10,
            city: "",
            cityBadge: "",
            displayRadius: false,
            candidateId: 0,
            shifts: "",
            editingSearch: false,
            center: {
                lat: null,
                lng: null,
            },
            mapMoved: false,
        }
    }

    async getCandidate() {
        await this.setState({ user: await AccountService.getCurrentUser() })
        var response = await CandidateService.getCandidateByAccount(this.state.user.id)
        this.setState({
            radius: response.radius * 1000,
            city: response.city,
            cityBadge: response.city,
            center: {
                lat: parseFloat(response.preferedLatitude),
                lng: parseFloat(response.preferedLongitude),
            }
        })
        this.setState({ candidateId: response.candidateId, shifts: response.shifts })
        return response
    }

    async retrieveOffers() {
        let response = await OfferDataService.getOffersInRadius(this.state.center.lat, this.state.center.lng, this.state.radius / 1000)
        await this.setState({ offers: response })
        this.tableRef.updateMarkers(response)
        this.currentMapRef.updateMarkers(response)
        this.setState({ mapMoved: false })
        return response
    }

    async findInArea() {
        await this.setState({ city: "Zone personnalisée", cityBadge: "Zone personnalisée", center: this.currentMapRef.state.center })
        await this.retrieveOffers()
        this.currentMapRef.updateMarkers(this.state.offers)
    }

    getZoom(radius) {
        var zoomLevel = this.zoom
        if (radius != null) {
            radius = radius + radius / 2
            var scale = radius / 1100
            zoomLevel = 16 - Math.log(scale) / Math.log(2);
        }
        return zoomLevel
    }
    getRadius(zoom) {
        var scale = Math.pow(10, (zoom - 16) / -3.321928)
        var radius = scale * 1100
        radius = radius * 2 / 3
        return radius
    }
    setActiveMarker = (childData) => {
        this.setState({ activeMarker: childData })
    }
    toggleRadius = () => {
        this.setState({ displayRadius: !this.state.displayRadius })
    }

    deactivateOverlay() {
        this.setState({ clickedOffer: null })
    }
    setClickedOffer(idOffer) {
        var clickedOffer = this.state.offers.find(s => s.offer.id === idOffer)
        this.setState({ clickedOffer })
    }

    onDemandContact = () => {
        chatService.initialize(this.state.clickedOffer.offer.pharmacy.id)
        this.props.history.push("/chats#" + this.state.clickedOffer.offer.pharmacy.id)
    }

    async onSliderChange(value) {
        this.setState({ radius: value * 1000 })

        this.zoom = await this.getZoom(this.state.radius)
        this.currentMapRef.updateMarkers(this.state.offers)

    }
    onInputRadiusChange(e) {
        this.setState({ radius: e.target.value * 1000 })
        this.zoom = this.getZoom(this.state.radius)
    }
    limitRange() {
        if (this.state.radius < 1) {
            this.setState({ radius: 1000 })
        } else if (this.state.radius > 80 * 1000) {
            this.setState({ radius: 80 * 1000 })
        }
    }

    async setPosition(e) {
        var coordinates = e.target.data_val.split('_')
        this.setState({
            editingSearch: false,
            city: e.target.innerText,
            cityBadge: e.target.innerText,
            center: {
                lat: parseFloat(coordinates[0]),
                lng: parseFloat(coordinates[1]),
            }
        })
        await this.currentMapRef._onChange({ center: this.state.center }, e.target.innerText)
        this.retrieveOffers()
    }


    async onSearchCity(e) {
        await this.setState({
            editingSearch: true,
            city: e.target.value
        })

        if (this.state.cityTimeout) {
            clearTimeout(this.state.cityTimeout)
        }
        this.setState({
            cityTimeout: setTimeout(async function () {
                var citiesBoxes = document.getElementsByClassName('citiesList')
                var cities = await cityService.findCities(e.target.value)
                for (var i = 0; i < citiesBoxes.length; i++) {
                    citiesBoxes[i].innerHTML = ''
                    if (e.target.value.length >= 3) {
                        if (cities.length > 0) {
                            citiesBoxes[i].textContent = '';
                            cities.forEach(city => {
                                var item = document.createElement('li')
                                item.className = 'list-group-item list-group-item-action cursor-pointer p-2 text-sm'
                                item.innerText = city.name + ' - ' + city.zip
                                item.data_val = city.latitude + '_' + city.longitude
                                item.onclick = (e) => {
                                    this.setPosition(e)
                                }
                                citiesBoxes[i].appendChild(item)
                            });
                        }
                    } else {
                        var item = document.createElement('li')
                        item.className = 'list-group-item  p-2 text-sm'
                        item.innerText = 'Veuillez saisir au moins 3 caractères'
                        citiesBoxes[i].appendChild(item)
                    }
                }
            }.bind(this)
                , 300)
        });
    }

    async saveResearch() {
        var body = { latitude: this.state.center.lat, longitude: this.state.center.lng, radius: this.state.radius / 1000, city: this.state.city }
        await candidateService.candidateEdit(body, this.state.candidateId)
        await this.retrieveOffers()
        this.currentMapRef.updateMarkers(this.state.offers)
    }

    canUpdateMap() {
        this.setState({ mapMoved: true })
    }

    async componentDidMount() {
        await this.getCandidate()
        await this.retrieveOffers()
    }
    render() {
        const { clickedOffer, radius, city, cityBadge, center } = this.state
        if (!this.state.offers) return <></>
        return (
            <Row>
                <Col className="col-12 col-lg-6 h-25 d-inline-block order-lg-1 order-2 p-4 p-lg-5" >
                    <Row className="mb-0">
                        <Col className="d-none d-lg-block col-12 col-lg-4 text-left h3 my-0">
                            Votre recherche
                        </Col>
                        <span className="d-inline-block d-lg-none text-left h3 my-0 bg-white mt--5 px-2 py-0 rounded-top" style={{ zIndex: 350, height: "24px" }}>
                            Votre recherche
                        </span>
                        <Col className="d-none d-lg-block col-8 text-right">
                            Affinez votre recherche en complétant <a href="/profile"><i className="far fa-user" /> votre profil</a>
                        </Col>
                        <Col className="d-block d-lg-none col-12  text-left text-xs mt--3">
                            Affinez votre recherche en complétant <a href="/profile"><i className="far fa-user" /> votre profil</a>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col className="d-none d-sm-block pt-1">
                            <form autoComplete="off">
                                <div className="autocomplete">
                                    <InputGroup className="search-input border border-default" onClick={(e) => {
                                        this.setState({ editingSearch: true });
                                        setTimeout(() => document.getElementById("myCity").select(), 1)
                                    }}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText className="border-white"><i className="fas fa-search text-primary" /></InputGroupText>
                                        </InputGroupAddon>
                                        {!this.state.editingSearch && cityBadge && cityBadge !== "" && <div className="p-2 text-secondary" style={{ borderLeft: 'none' }} ><Badge className="text-capitalize">{cityBadge}<span className="pl-2 cursor-pointer" onClick={() => {
                                            setTimeout(() => { document.getElementById("myCity").value = ""; this.setState({ city: null, cityBadge: null }) }, 1)
                                        }}>x</span></Badge></div>}
                                        <Input className="border-white text-secondary" id="myCity" placeholder="Rechercher sur la carte" value={city} onChange={this.onSearchCity} onBlur={(e) => setTimeout(() => this.setState({ editingSearch: false }), 200)} style={{ borderLeft: 'none', display: !this.state.editingSearch && cityBadge && cityBadge !== "" ? 'none' : 'block' }} onClick={(e) => { e.target.select(); this.onSearchCity(e) }} />
                                    </InputGroup>
                                    <input type="hidden" name="myLat" id="myLat" value={center.lat} readOnly />
                                    <input type="hidden" name="myLng" id="myLng" value={center.lng} readOnly />
                                    {this.state.editingSearch && <ul className="citiesList list-group list-group-flush bg-white shadow rounded position-absolute mt-2 w-100 p-3 text-left" style={{ zIndex: "1080" }}>
                                        <li className='list-group-item p-2 text-sm'>Veuillez saisir au moins 3 caractères</li>
                                    </ul>}
                                </div>
                            </form>
                        </Col>
                        <Col className="d-block d-sm-none pt-1 col-12">
                            <form autoComplete="off">
                                <div className="autocomplete">
                                    <InputGroup className="search-input border border-default" onClick={async(e) => {
                                        await this.setState({ editingSearch: true });
                                        document.getElementById("myCity-mobile").select()
                                        document.getElementById("myCity-mobile").focus()
                                    }}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText className="border-white"><i className="fas fa-search text-primary" /></InputGroupText>
                                        </InputGroupAddon>
                                        {!this.state.editingSearch && cityBadge && cityBadge !== "" && <div className="p-2 text-secondary" style={{ borderLeft: 'none' }} ><Badge className="text-capitalize">{cityBadge}<span className="pl-2 cursor-pointer" >x</span></Badge></div>}
                                        <Input className="border-white text-secondary" id="myCity-mobile" placeholder="Rechercher sur la carte" value={city} onChange={this.onSearchCity} onBlur={(e) => setTimeout(() => this.setState({ editingSearch: false }), 200)} style={{ borderLeft: 'none', display: !this.state.editingSearch && cityBadge && cityBadge !== "" ? 'none' : 'block' }} onClick={(e) => { e.target.select(); this.onSearchCity(e) }} />
                                    </InputGroup>
                                    <input type="hidden" name="myLat" id="myLat" value={center.lat} readOnly />
                                    <input type="hidden" name="myLng" id="myLng" value={center.lng} readOnly />
                                    {this.state.editingSearch && <ul className="citiesList list-group list-group-flush bg-white shadow rounded position-absolute mt-2 w-100 p-3 text-left" style={{ zIndex: "1080" }}>
                                        <li className='list-group-item p-2 text-sm'>Veuillez saisir au moins 3 caractères</li>
                                    </ul>}
                                </div>
                            </form>
                        </Col>
                        <Col className="d-block d-sm-none col-5 col-sm-auto pt-1 px-3 px-sm-0 text-left">
                            <Button outline={!this.state.displayRadius} color="default" onClick={this.toggleRadius}><i className="fas fa-map-pin" /><span>Rayon</span></Button>
                        </Col>
                        <Col className="col-7 col-sm-auto pt-1 text-right">
                            <Button outline={!this.state.searchChanged} color="primary" onClick={this.saveResearch}><i className="fas fa-save" /><span>Sauvegarder</span></Button>
                        </Col>
                    </Row>
                    {this.state.displayRadius && <Row className="d-block d-sm-none mt-4 ml--1">
                        <Col>
                            <Slider
                                min={1} max={80} step={1}
                                value={typeof radius === 'number' ? radius / 1000 : 0}
                                marks={{
                                    1: { label: '' },
                                    5: { label: '' },
                                    10: { label: '' },
                                    20: { label: '' },
                                    30: { label: '' },
                                    50: { label: '' },
                                    80: { label: '' }
                                }}
                                trackStyle={{ backgroundColor: '#e2025f' }}
                                handleStyle={{ backgroundColor: 'white', borderColor: '#e2025f' }}
                                railStyle={{ backgroundColor: 'rgb(239 202 218)' }}
                                dotStyle={{ backgroundColor: 'white', borderColor: '#e2025f' }}
                                activeDotStyle={{ backgroundColor: 'white', borderColor: '#e2025f' }}
                                onChange={(e) => this.onSliderChange(e)}
                            />
                        </Col>
                        <Col className="col-auto p-0 text-sm mt--1"><span>{radius / 1000} km </span></Col>
                    </Row>}
                    <Row className="d-none d-sm-block mt-4 ml--1">
                        <Col>
                            <Slider
                                min={1} max={80} step={1}
                                value={typeof radius === 'number' ? radius / 1000 : 0}
                                marks={{
                                    1: { label: '' },
                                    5: { label: '' },
                                    10: { label: '' },
                                    20: { label: '' },
                                    30: { label: '' },
                                    50: { label: '' },
                                    80: { label: '' }
                                }}
                                trackStyle={{ backgroundColor: '#e2025f' }}
                                handleStyle={{ backgroundColor: 'white', borderColor: '#e2025f' }}
                                railStyle={{ backgroundColor: 'rgb(239 202 218)' }}
                                dotStyle={{ backgroundColor: 'white', borderColor: '#e2025f' }}
                                activeDotStyle={{ backgroundColor: 'white', borderColor: '#e2025f' }}
                                onChange={(e) => this.onSliderChange(e)}
                            />
                        </Col>
                        <Col className="col-auto p-0 text-sm mt--1"><span>{radius / 1000} km </span></Col>
                    </Row>
                    <Row className="mt-4 pb-3">
                        <Col className="col-auto text-left">
                            <h1 className="text-secondary mb--1">Offres d'emploi à proximité</h1>
                            <span className="d-none d-sm-block  text-default text-sm"><i className="fas fa-info-circle" /> Sélectionné pour vous en fonction de <a href="/profile">votre profil</a></span>
                        </Col>
                    </Row>
                    <Table ref={(instance) => { this.tableRef = instance }}
                        parentDisponibilities={this.state.offers}
                        setActiveMarker={this.setActiveMarker}
                        activeMarker={this.state.activeMarker}
                        setClickedMarker={this.setClickedOffer}
                        mapType={'candidate'}
                    />
                </Col>
                <Col className="col-12 col-lg-6 order-lg-2 order-1 p-0" style={{ right: 0, position: (window.innerWidth > 991) ? "fixed" : "relative" }} >
                    {this.state.mapMoved && <div className="search-in-zone-container"><Button color="primary" className="search-in-zone m-3" onClick={this.findInArea}><i className="fas fa-search" /><span>Rechercher dans cette zone</span></Button></div>}
                    <Map
                        ref={(instance) => { this.currentMapRef = instance }}
                        centerFromParent={center}
                        zoomFromParent={this.getZoom(this.state.radius)}
                        radiusFromParent={this.state.radius}
                        gestureHandling='none'
                        parentDisponibilities={this.state.offers}
                        setActiveMarker={this.setActiveMarker}
                        activeMarker={this.state.activeMarker}
                        setClickedMarker={this.setClickedOffer}
                        canUpdateMap={this.canUpdateMap}
                        mapType={'candidate'}
                    />
                </Col>
                {clickedOffer && <OfferDisplay clickedOffer={clickedOffer} shiftsCandidate={this.state.shifts}
                    deactivateOverlay={this.deactivateOverlay}
                    onDemandContact={this.onDemandContact} />}
            </Row>
        )
    }
}