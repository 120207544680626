import React, { Component } from 'react'
import accountConfig from '../config/account.config'
import miscUtils from '../../utils/misc.utils'

import {
    Table,
    Button,
    Input
} from 'reactstrap'
export default class ShiftDisplay extends Component {
    constructor(props) {
        super(props)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleScheduleChange = this.handleScheduleChange.bind(this)
        this.updateTwoWeeks = this.updateTwoWeeks.bind(this)
        this.state = {
            timeFrom: { index: 0, value: "00:00" },
            timeTo: { index: 0, value: "00:00" },
            timeDay: { index: 0, value: "lundi" },
            moTimes: [],
            tuTimes: [],
            weTimes: [],
            thTimes: [],
            frTimes: [],
            saTimes: [],
            suTimes: [],
            moTimes2: [],
            tuTimes2: [],
            weTimes2: [],
            thTimes2: [],
            frTimes2: [],
            saTimes2: [],
            suTimes2: [],
            varName: this.props.parentVarName,
            myDayList: accountConfig.dayList1,
            // shifts: this.props.parentShifts,
        }
    }

    async handleScheduleChange(del) {
        if (this.state.timeFrom.index > this.state.timeTo.index && !del) {
            return false
        }
        var i
        var name
        var dayIndex
        if (del) {
            dayIndex = del
        } else {
            dayIndex = this.state.timeDay.index
        }
        switch (dayIndex) {
            case 0:
                name = "moTimes"
                i = 0
                break
            case 1:
                name = "tuTimes"
                i = 48
                break
            case 2:
                name = "weTimes"
                i = 48 * 2
                break
            case 3:
                name = "thTimes"
                i = 48 * 3
                break
            case 4:
                name = "frTimes"
                i = 48 * 4
                break
            case 5:
                name = "saTimes"
                i = 48 * 5
                break
            case 6:
                name = "suTimes"
                i = 48 * 6
                break
            case 7:
                name = "moTimes2"
                i = 48 * 7
                break
            case 8:
                name = "tuTimes2"
                i = 48 * 8
                break
            case 9:
                name = "weTimes2"
                i = 48 * 9
                break
            case 10:
                name = "thTimes2"
                i = 48 * 10
                break
            case 11:
                name = "frTimes2"
                i = 48 * 11
                break
            case 12:
                name = "saTimes2"
                i = 48 * 12
                break
            case 13:
                name = "suTimes2"
                i = 48 * 13
                break
            default:
                break
        }
        var myShifts = this.state[name]
        // /!\ not working
        if (myShifts.some(e => e.value === this.state.timeFrom.index) || myShifts.some(e => e.value === this.state.timeTo.index)) {
        }
        if (del === undefined) {
            await myShifts.push({ value: this.state.timeFrom.index, label: this.state.timeFrom.value })
            await myShifts.push({ value: this.state.timeTo.index, label: this.state.timeTo.value })
        }
        var shifts = "000000000000000000000000000000000000000000000000"
        for (var j = 1; j < myShifts.length; j = j + 2) {
            if (myShifts[j].value >= myShifts[j - 1].value) {
                var numberChanges = myShifts[j].value - myShifts[j - 1].value + 1
                var pos = myShifts[j - 1].value
                let replacement = ""
                for (var x = 0; x < numberChanges; x++) {
                    replacement = replacement + "1"
                }
                shifts = miscUtils.replaceAt(shifts, pos, replacement)
            }
        }
        this.setState({ [name]: myShifts })
        this.props.shiftsUpdate(shifts, myShifts, name, i)
    }

    async removeTimes(index, indexD, name) {
        const myTimes = this.state[name]
        await myTimes.splice(index - 1, 2)
        await this.setState({ [name]: myTimes })
        await this.handleScheduleChange(indexD)
    }

    handleInputChange(e) {
        const target = e.target
        const value = target.value
        const name = target.name

        this.setState({ [name]: { index: e.target.selectedIndex, value } })
    }

    updateTwoWeeks() {
        if (this.props.twoWeeks) {
            this.setState({ myDayList: accountConfig.dayList2 })
        } else {
            this.setState({ myDayList: accountConfig.dayList1 })
        }
    }

    setup() {
        this.updateTwoWeeks()
        this.setState({
            moTimes: this.props.parentTimes.moTimes,
            tuTimes: this.props.parentTimes.tuTimes,
            weTimes: this.props.parentTimes.weTimes,
            thTimes: this.props.parentTimes.thTimes,
            frTimes: this.props.parentTimes.frTimes,
            saTimes: this.props.parentTimes.saTimes,
            suTimes: this.props.parentTimes.suTimes,
            moTimes2: this.props.parentTimes.moTimes2,
            tuTimes2: this.props.parentTimes.tuTimes2,
            weTimes2: this.props.parentTimes.weTimes2,
            thTimes2: this.props.parentTimes.thTimes2,
            frTimes2: this.props.parentTimes.frTimes2,
            saTimes2: this.props.parentTimes.saTimes2,
            suTimes2: this.props.parentTimes.suTimes2,

        })
    }

    componentDidMount() {
        this.setup()
        this.setState({ times: this.props.moTimes })
        this.setState({ shifts: this.props.parentShifts })
    }
    render() {
        const { myDayList, moTimes, tuTimes, weTimes, thTimes, frTimes, saTimes, suTimes, moTimes2, tuTimes2, weTimes2, thTimes2, frTimes2, saTimes2, suTimes2 } = this.state
        var days = [moTimes, tuTimes, weTimes, thTimes, frTimes, saTimes, suTimes, moTimes2, tuTimes2, weTimes2, thTimes2, frTimes2, saTimes2, suTimes2]

        return (
            <Table size="sm" className="table-borderless w-100 text-left">
                <thead>
                    <tr>
                        <th>Créneau</th>
                        <th>De</th>
                        <th>à</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="pt-2">
                    {
                        days.map((times, indexD) =>
                            times.map((time, index) => {
                                return myDayList[indexD] && (index + 1) % 2 === 0 &&
                                    <tr key={index}>
                                        <th className="align-middle text-sm px-0">{myDayList[indexD].label}</th>
                                        <td className="align-middle pl-1 pr-0 text-center">{times[(index - 1)].label}</td>
                                        <td className="align-middle pl-1 pr-0 text-center">{accountConfig.timeEnum[time.value + 1].label}</td>
                                        <td className="align-middle pl-1 pr-0"><Button color="primary" outline className="btn-sm"
                                            timeday={myDayList[indexD]}
                                            onClick={() => this.removeTimes(index, indexD, myDayList[indexD].value)}
                                        ><i className="fa fa-trash-alt" /></Button></td>
                                    </tr>
                            })
                        )}
                    <tr>
                        <td className="align-middle text-sm px-0" ><Input
                            className="profile-field"
                            type="select"
                            name="timeDay"
                            onChange={this.handleInputChange}
                        >{
                                myDayList.map((day, index) =>
                                    <option key={day.value}>{day.label}</option>)
                            }</Input></td>
                        <td className="align-middle text-sm  pl-1 pr-0"><Input
                            className="profile-field"
                            type="select"
                            name="timeFrom"
                            onChange={this.handleInputChange}
                        >{
                                accountConfig.timeEnum.map((hour, index) => {
                                    if (index < 48) {
                                        return <option key={hour.value}>{hour.label}</option>
                                    } else {
                                        return null
                                    }
                                }
                                )
                            }</Input></td>
                        <td className="align-middle text-sm pl-1 pr-0"><Input
                            className="profile-field"
                            type="select"
                            name="timeTo"
                            options={accountConfig.timeEnum}
                            onChange={this.handleInputChange}
                        >{
                                accountConfig.timeEnum.map((hour, index) => {
                                    if (index > 0) {
                                        return <option key={hour.value}>{hour.label}</option>
                                    } else {
                                        return null
                                    }
                                })
                            }</Input></td>
                        <td className="align-middle text-sm pl-1 pr-0"><Button
                            color="primary"
                            className="btn-sm"
                            onClick={() => this.handleScheduleChange()}
                        ><i className="fa fa-plus" /></Button></td>
                    </tr>
                </tbody>
            </Table>
        )
    }
}