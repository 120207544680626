import { Component } from "react";
import { isIOS, isSafari } from "react-device-detect";
import PWAPrompt from "react-ios-pwa-prompt";

export default class IosPWAPrompt extends Component {
  render() {
    return (
      <div>
        {(isIOS || isSafari) && (
          <PWAPrompt
            timesToShow={3}
            copyTitle="Ajouter à l'écran d'accueil"
            copyBody="Ajoutez MeetPharma à l'écran d'accueil."
            copyShareButtonLabel="1) Appuyez sur le bouton de partage"
            copyAddHomeButtonLabel="2) Appuyez sur 'Ajouter à l'écran d'accueil'"
            copyClosePrompt="Fermer"
            permanentlyHideOnDismiss={false}
          />
        )}
      </div>
    );
  }
}
