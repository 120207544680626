import React, { Component } from "react"
import { Badge, Button, Card, Col, InputGroup, InputGroupAddon, NavLink, Row } from "reactstrap"
import offerService from "../services/offer.service"

import { ReactComponent as PublishOffersIcon } from '../assets/img/icons/publish-offers.svg';
import { ReactComponent as SearchCandidatesIcon } from '../assets/img/icons/search-candidates.svg';
import candidateService from "../services/candidate.service";
import accountConfig from "./config/account.config";
import MeetCodeButton from "./mp/meetCodeButton.component";

import CountUp from 'react-countup';

export default class Stats extends Component {
    constructor(props) {
        super(props)
        this.loadOffers = this.loadOffers.bind(this)
        this.copyLink = this.copyLink.bind(this)
        this.state = {
            copied: false,
            offers: [],
            validations: [],
            totalHours: 0,
            pharmacy: this.props.pharmacy,
            candidate: this.props.candidate
        }
    }

    async loadOffers() {
        var result
        if (this.props.user.role === 'titulaire') {
            result = await offerService.getAllOffers()
            if (result) {
                this.setState({ offers: result.offers })
            }
        } else if (this.props.user.role === 'candidat') {
            result = await candidateService.getCandidateInfo(this.props.candidate.candidateId, true, true)
            var totalHours = Math.round(result.points_histories.filter(a => a.type === 'credit').map(x => x?.points).reduce((p, n) => p + n, 0) / 2)
            if (result) {
                this.setState({ validations: result.validations, totalHours })
            }
        }
    }

    async addSponsorLink() {
        if (this.props.user.role === 'titulaire') {
            var pharmacy = this.props.pharmacy
            pharmacy.sponsorLink = process.env.REACT_APP_APP_URL + "?phsp=" + btoa(pharmacy.pharmacyId)
            this.setState({ pharmacy })
        } else {
            var candidate = this.props.candidate
            candidate.sponsorLink = process.env.REACT_APP_APP_URL + "?casp=" + btoa(candidate.candidateId)
            this.setState({ candidate })
        }
    }

    async copyLink(e) {
        var input = this.sponsorInput;
        input.focus();
        input.setSelectionRange(0, input.value.length);

        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(this.state.pharmacy ? this.state.pharmacy.sponsorLink : this.state.candidate.sponsorLink);
        } else {
            document.execCommand('copy', true, this.state.pharmacy ? this.state.pharmacy.sponsorLink : this.state.candidate.sponsorLink);
        }

        this.setState({ copied: true })
    }

    componentDidMount() {
        this.loadOffers()
        this.addSponsorLink()
    }
    render() {
        var { pharmacy, candidate, offers, validations, totalHours } = this.state
        var profil = pharmacy ? pharmacy : candidate
        return (
            <Row className="p-3 p-lg-4 p-xl-6">
                <Col className="col-12">
                    <div className="text-left display-2 text-white">Bienvenue sur Meetpharma</div>
                </Col>
                <div className="stat-col-1 text-left">
                    <Card style={{ height: this.props.isPharmacy ? "500px" : "530px" }}>
                        <div className="text-primary h2"><i className="fas fa-user-circle pr-1" />Votre profil<br />{profil.firstName} {profil.lastName}</div>
                        <div className="text-secondary font-weight-bold">{profil.pharmacyName} {profil.candidateName} {profil.candidateLastName}</div>
                        <div className="text-default">{profil.email}</div>
                        {this.props.isPharmacy && <div className="text-primary mt-3 font-weight-bold">
                            <div className="border border-primary text-center rounded-circle d-inline-block mr-3" style={{ width: '54px', height: '54px', lineHeight: '54px' }}>{offers.filter(a => a.active).length}</div> Offres d'emploi en cours
                        </div>}
                        {this.props.isPharmacy && <div className="text-default mt-2 font-weight-bold">
                            <div className="border border-default text-center rounded-circle d-inline-block mr-3" style={{ width: '54px', height: '54px', lineHeight: '54px' }}>{offers.filter(a => !a.active).length}</div> Offres d'emploi terminées
                        </div>}
                        {this.props.isCandidate && <div className="text-primary mt-3 font-weight-bold cursor-pointer"><a href="/missions">
                            <div className="border border-primary text-center rounded-circle d-inline-block mr-3" style={{ width: '54px', height: '54px', lineHeight: '54px' }}>{validations.filter(a => a.validation_date).length}</div> Missions accomplies</a>
                        </div>}
                        {this.props.isCandidate && <div className="text-default mt-2 font-weight-bold">
                            <div className="border border-default text-center rounded-circle d-inline-block mr-3" style={{ width: '54px', height: '54px', lineHeight: '54px' }}>{totalHours}</div> Heures au total
                        </div>}

                        {this.props.isCandidate && <>
                            <div className="text-primary font-weight-bold mt-3">Votre recherche actuelle</div>
                            <div className="text-secondary font-weight-bold mt-2">Poste recherché : <span className="font-weight-normal">{accountConfig.positionList[profil.position]}</span></div>
                            <div className="text-secondary font-weight-bold mt-1">Zone de recherche : <span className="font-weight-normal">{profil.city}</span></div>
                            <div className="text-default mt-3 text-sm" style={{ lineHeight: '1.1' }}>Pensez à compléter vos préférences de recherche sur votre profil pour affiner vos résultats.</div>
                        </>
                        }

                        <NavLink href={"/profile"} className={this.props.isPharmacy ? "px-0 mt-5" : "px-0 mt-3"}>
                            <Button outline color={this.props.isPharmacy ? "secondary" : "primary"} className="mt-2 py-2 px-3 shadow-none text-md"><i className="far fa-user" />
                                <span>Compléter mon profil</span>
                            </Button>
                        </NavLink>

                        {/* {this.props.isPharmacy && <NavLink href={"/subscription"} className="px-0 mt-5">
                            <InputGroup className="cursor-pointer">
                                <InputGroupAddon addonType="prepend">
                                    <div className="border border-primary py-0 rounded-left text-md font-weight-bold px-2 pl-4">
                                        <div className="mt--3 ml--2 pr-4">
                                            <Badge href="/subscription" className=" shadow-sm" style={{ backgroundColor: '#fbfbfb' }}>
                                                <span className="text-primary text-xs text-capitalize">Mon abonnement</span>
                                            </Badge>
                                        </div>
                                        {this.props.pharmacy ? "Abonné" : "Non abonné"}
                                    </div>
                                </InputGroupAddon>

                                <InputGroupAddon addonType="append">
                                    <Button color="primary py-1" style={{ lineHeight: "1.1" }}>Gérer mon<br />Abonnement</Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </NavLink>
                        } */}
                        {this.props.isCandidate &&
                            <NavLink href={"/chats"} className="px-0 mt-0">
                                <Button outline color="secondary" className="mt-2 py-2 px-3 shadow-none text-md"><i className="far fa-comments" />
                                    <span>Messages</span>
                                </Button>
                            </NavLink>}
                    </Card>
                </div>
                <div className="stat-col-2 text-left" >
                    {this.props.isPharmacy ?
                        <Card className="bg-alternative-2 text-white" style={{ height: this.props.isPharmacy ? "500px" : "530px" }}>
                            <div className="text-white h1 mt-2" style={{ maxWidth: "320px", lineHeight: "1.1" }}>Créez votre annonce et trouvez vos collaborateurs !</div>
                            <div className="mt-2" style={{ lineHeight: "1.1" }}>Définissez en quelques clics le profil du candidat recherché, le planning de vos disponibilités, le taux horaire et plus.</div>

                            <NavLink href={"/myOffers"} className="mt-6 mainlink-dashboard">
                                <span className="mainlink-dashboard-img"><PublishOffersIcon width="100%" height="auto" /></span>
                                <span className="mainlink-dashboard-title">Gérer mes <br />offres d'emploi</span>
                                <span className="mainlink-dashboard-icon"><i className="fas fa-chevron-right fa-3x"></i></span>
                            </NavLink>
                            <NavLink href={"/chats"} className="mt-4 mainlink-dashboard">
                                <span className="mainlink-dashboard-img"><SearchCandidatesIcon width="100%" height="auto" /></span>
                                <span className="mainlink-dashboard-title">Discuter avec les candidats</span>
                                <span className="mainlink-dashboard-icon"><i className="fas fa-chevron-right fa-3x"></i></span>
                            </NavLink>
                        </Card>
                        :
                        <>
                            <Card className="bg-alternative-2 text-white" style={{ height: "180px" }}>
                                <div className="text-white h3" >Découvrez nos dernières offres d'emploi</div>
                                <NavLink href={"/offersInRadius"} className="mt-3 mainlink-dashboard">
                                    <span className="mainlink-dashboard-img"><SearchCandidatesIcon width="100%" height="auto" /></span>
                                    <span className="mainlink-dashboard-title">Rechercher une<br />offre d'emploi</span>
                                    <span className="mainlink-dashboard-icon"><i className="fas fa-chevron-right fa-3x"></i></span>
                                </NavLink>
                            </Card>

                            <Card className="bg-primary text-white mt-5" style={{ height: "303px", display: "none" }}>
                                <InputGroup className="cursor-pointer position-absolute shadow" style={{ width: "262px", top: '-20px' }} onClick={() => { this.props.history.push("/cagnotte") }}>
                                    <InputGroupAddon addonType="prepend">
                                        <div className="border border-primary py-1 rounded-left text-lg font-weight-bold px-4 bg-white text-dark">
                                            <div className="mt--4 ml--2 pr-4">
                                                <Badge className=" shadow-sm" style={{ backgroundColor: '#fbfbfb' }}>
                                                    <span className="text-primary text-xs text-capitalize">MeetPoints</span>
                                                </Badge>
                                            </div>
                                            <CountUp
                                                start={0}
                                                end={profil.meetPoints}
                                                delay={0}
                                                duration={2.75}
                                                separator=" "
                                                decimals={0}
                                            >
                                                {({ countUpRef }) => (
                                                    <div >
                                                        <span ref={countUpRef} />
                                                    </div>
                                                )}
                                            </CountUp>
                                        </div>
                                    </InputGroupAddon>

                                    <InputGroupAddon addonType="append">
                                        <Button color="primary py-1" style={{ lineHeight: "1.1" }}>Accéder à <br />ma cagnotte</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                                <div className="text-white h1 mt-4" >Gagnez des bonus grâce aux MeetPoints</div>
                                <div className="text-white mt-2" >Chaque semaine, vous reçevez 1 MeetPoint par demi-heure travaillée dans le cadre d'une mise en relation validée sur Meetpharma.</div>
                                <MeetCodeButton color="white" className="mt-4" style={{ maxWidth: '200px' }} />
                            </Card>
                        </>}
                </div>

                <div className="stat-col-3 text-left" style={{ display: "none"}}>
                    {this.props.isCandidate && <Card className="bg-alternative text-white" style={{ height: this.props.isPharmacy ? "500px" : "530px" }}>
                        <img className="position-absolute" alt="Offre de parrainage" style={{ width: '300px', maxWidth: "80%", top: '-50px' }} src={require("../assets/img/meetpharma-offre-parrainage.png").default} />
                        <div className="text-white display-3" style={{ marginTop: '180px' }}>Offre parrainage</div>
                        <div className={this.props.isPharmacy ? "text-white h2" : "text-white h1 mt-3"} >{this.props.isPharmacy ? 'Économisez 1 mois d’abonnement.' : 'Gagnez 50 MeetPoints'}</div>
                        <span className="mt-2" style={{ maxWidth: "520px", lineHeight: "1.1" }}>{this.props.isPharmacy ? "Pour parrainer une pharmacie envoyez lui votre lien de parrainage ci-dessous, vous bénéficirez tous les deux d'un mois offert sur votre abonnement." : "Pour chaque candidat que vous parrainez via votre lien de parrainage ci-dessous, vous obtenez 50 MeetPoints supplémentaires dès qu'il valide sa première mission."}</span>

                        <InputGroup className="cursor-pointer shadow mt-5" style={{ maxWidth: "650px", lineHeight: "1.1" }} onClick={this.copyLink}>
                            {profil?.sponsorLink && <input ref={elem => this.sponsorInput = elem} type="text" className="cursor-pointer border-primary text-dark form-control" value={profil.sponsorLink} readOnly/>}
                            <InputGroupAddon addonType="append">
                                <Button color="primary" disabled={this.state.copied}>{this.state.copied ? "Copié dans le presse-papier" : "Copier le lien"}</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Card>}
                </div>
            </Row>
        )
    }
}