import React, { useState } from "react";
// reactstrap components
import {
    FormGroup,
    Input,
    Col,
    Form,
    Card
} from "reactstrap";
import accountService from "../../services/account.service";


function ContactForm() {
    const [message, setMessage] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const handleSubmit = (e) => {
        setDisabled(true)
        setMessage('<i class="fas fa-spinner fa-spin fa-3x text-primary"></i>')
        e.preventDefault()
        var data = new FormData(e.target);
        accountService.sendContact(Object.fromEntries(data.entries()))
            .then(() => setMessage("Votre message vient d'être envoyé. Nous vous avons envoyé une confirmation par email."))
            .catch(() => { setMessage("Une erreur s'est produite. Veuillez réessayer."); setDisabled(false) })
        return false
    }

    return (
        <Card className="p-5">
            <Form className="text-left" onSubmit={handleSubmit}>
                <h3 className="display-3 mb-2 text-primary">Contacter Meetpharma</h3>
                <p className="mb-4" style={{ lineHeight: 1.3 }}>Vous pouvez nous contacter via le formulaire de contact ci-dessous.<br />Votre demande sera traitée dans les meilleurs délais.</p>
                <h4 className="h2 mb-4 text-secondary">Vos coordonnées</h4>
                <FormGroup className="mb-3 row">
                    <Col className="col-6">
                        <Input
                            type="text"
                            id="contact_lastname"
                            name="contact_lastname"
                            placeholder="Nom *"
                            required
                        />
                    </Col>
                    <Col className="col-6">
                        <Input
                            type="text"
                            id="contact_firstname"
                            name="contact_firstname"
                            placeholder="Prénom *"
                            required
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3">
                    <Input
                        type="text"
                        id="contact_phone"
                        name="contact_phone"
                        placeholder="Numéro de téléphone"
                    />
                </FormGroup>
                <FormGroup className="mb-3">
                    <Input
                        type="text"
                        id="contact_email"
                        name="contact_email"
                        placeholder="Email *"
                        required
                    />
                </FormGroup>
                <h4 className="h2 mb-4 mt-4 text-secondary">Votre demande</h4>
                <FormGroup className="mb-3">
                    <Input
                        type="text"
                        id="contact_subject"
                        name="contact_subject"
                        placeholder="Sujet *"
                        required
                    />
                </FormGroup>
                <FormGroup className="mb-3">
                    <Input
                        type="textarea"
                        id="contact_message"
                        name="contact_message"
                        placeholder="Message *"
                        required
                        rows="5"
                    />
                </FormGroup>

                <div className="text-right">
                    {message && <span className="pr-2" dangerouslySetInnerHTML={{ __html: message }}></span>}
                    {!disabled && <Input type="submit" className="btn btn-primary" style={{ width: 'auto' }} value="Envoyer"></Input>}

                </div>
            </Form>
        </Card>
    );
}

export default ContactForm;