import { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import meetCodeService from '../../services/meetCode.service'

export default class MeetCodeButton extends Component {
    constructor(props) {
        super(props)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.toggle = this.toggle.bind(this)
        this.claim = this.claim.bind(this)
        this.state = {
            openPopup: false,
            code: "",
            loading: false,
            message: ""
        }
    }

    toggle() {
        this.setState({ openPopup: !this.state.openPopup, code: "", message: "" })
    }

    handleInputChange(e) {
        const target = e.target
        const value = target.value
        const name = target.name
        this.setState({ [name]: value })
    }

    claim() {
        if (this.state.code) {
            this.setState({ loading: true })
            meetCodeService.claim(this.state.code)
                .then((response) => {
                    this.setState({
                        message: "Félicitations, vous venez d'obtenir " + response?.data?.points + " MeetPoints.",
                        loading: false
                    })
                    window.location.reload()
                })
                .catch(error => {
                    if (error?.response?.data?.code === 'UNAUTHORIZED') {
                        return this.setState({
                            message: "Vous n'êtes pas autorisé à utiliser ce code.",
                            loading: false
                        })
                    }
                    if (error?.response?.data?.code === 'CODE_MANDATORY') {
                        return this.setState({
                            message: "Merci de saisir un code.",
                            loading: false
                        })
                    }
                    if (error?.response?.data?.code === 'NOT_FOUND') {
                        return this.setState({
                            message: "Merci de saisir un code valide.",
                            loading: false
                        })
                    }
                    if (error?.response?.data?.code === 'LIMIT_USE') {
                        return this.setState({
                            message: "Ce code a atteint son maximum d'utilisation.",
                            loading: false
                        })
                    }
                    if (error?.response?.data?.code === 'LIMIT_DATE') {
                        return this.setState({
                            message: "Ce code est expiré.",
                            loading: false
                        })
                    }
                    if (error?.response?.data?.code === 'ALREADY_USED') {
                        return this.setState({
                            message: "Vous avez déjà utilisé ce code.",
                            loading: false
                        })
                    }
                    return this.setState({
                        message: "Erreur interne: veuillez réessayer plus tard.",
                        loading: false
                    })
                });
        } else {
            this.setState({ message: "Merci de saisir un code." })
        }
    }

    componentDidMount() {
    }

    render() {
        const { openPopup, code, message, loading } = this.state
        return (
            <>
                <Button {...this.props} color={this.props.color ? this.props.color : 'primary'} onClick={this.toggle}>
                    <i className="fas fa-plus"></i><span>J'ai un MeetCode</span>
                </Button>
                <Modal
                    isOpen={openPopup}
                    centered
                    scrollable
                    toggle={this.toggle}
                >
                    <ModalHeader toggle={this.toggle}>
                        Ajouter un MeetCode
                    </ModalHeader>
                    <ModalBody>
                        <p>
                            Vous avez reçu un MeetCode ?<br />
                            Saisissez le ici pour profiter de vos MeetPoints.
                        </p>
                        <Input className="profile-field mt-3 py-2" type="text" name="code" value={code} placeholder="MeetCode" onChange={this.handleInputChange} />
                        {message && <p className="text-primary mt-3">{message}</p>}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.toggle}>
                            Annuler
                        </Button>
                        {' '}
                        <Button
                            color="primary"
                            disabled={!code || loading}
                            onClick={this.claim}
                        >
                            Valider
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}