import React, { Component } from "react"
import candidateService from "../services/candidate.service"
import offerService from "../services/offer.service"
import '../assets/css/meetChat.css'
import Avatar from 'react-avatar'
import { overlayStyle, informationStyle } from "../styles/mapPage.style"
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import {
    Row,
    Col,
    Button,
    Badge,
} from 'reactstrap'
import pharmacyService from "../services/pharmacy.service"
import accountConfig from "./config/account.config"
import ShiftCalendarDisplay from "./shift/shiftCalendarDisplay.component"
import miscConfig from "./config/misc.config"
import OfferDisplay from "./offerDisplay.component"
import chatService from "../services/chat.service"
import miscUtils from "../utils/misc.utils"
import moment from "moment"
import AccountService from "../services/account.service"
import stripeService from "../services/stripe.service"

export default class Chat extends Component {
    constructor(props) {
        super(props)
        this.onChangeMessage = this.onChangeMessage.bind(this)
        this.onSendMessage = this.onSendMessage.bind(this)
        this.retrieveMessages = this.retrieveMessages.bind(this)
        this.chooseButtonAction = this.chooseButtonAction.bind(this)
        this.displayProfile = this.displayProfile.bind(this)
        this.setClickedOffer = this.setClickedOffer.bind(this)
        this.onDemandContact = this.onDemandContact.bind(this)
        this.closeOffer = this.closeOffer.bind(this)
        this.socket = this.props.socket
        this.sender = this.props.currentUser
        //Needed to detect clicks outside of confirmation menu
        this.confirmationMenuRef = React.createRef()
        this.confirmationButtonRef = React.createRef()
        this.setConfirmationMenuRef = this.setConfirmationMenuRef.bind(this)
        this.setConfirmationButtonRef = this.setConfirmationButtonRef.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.messageBox = []
        const currentUser = AccountService.getCurrentUser()
        this.state = {
            displayedProfile: {},
            clickedProfile: false,
            clickedOffer: null,
            acceptationDate: this.props.currentChat.acceptation_date,
            viewReceiver: false,
            interlocutorId: 0,
            listOffers: [],
            listOffersOpen: false,
            search: "",
            autoScrolled: false,
            atBottom: false,
            atLastRead: false,
            listMessages: this.props.currentChat.messages,
            currentIndex: -1,
            senderName: this.props.currentUserName,
            shifts: this.props.shifts,
            currentMessage: {
                id: null,
                text: "",
                read: false,
            },
            newMessageAdded: false,
            user: currentUser,
            isAdmin: currentUser && currentUser.role === "admin",
            showButton: false
        }
    }


    onDemandContact = () => {
        chatService.initialize(this.state.clickedOffer.offer.pharmacy.id)
        this.props.history.push("/chats#" + this.state.clickedOffer.offer.pharmacy.id)
        window.location.reload();
    }

    async setInterlocutor() {
        if (this.sender.role === 'titulaire') {
            await this.setState({ interlocutorId: this.props.currentChat.candidate_id })
        } else {
            await this.setState({ interlocutorId: this.props.currentChat.pharmacy_id })
        }
    }
    onChangeMessage(data) {
        // var currentMessage= this.state.currentMessage
        const text = data

        this.setState(function (prevState) {
            return {
                currentMessage: {
                    ...prevState.currentMessage,
                    text: text
                }
            }
        })
    }

    onSendMessage = async (e) => {
        var data = {
            id: this.state.currentMessage.id,
            text: this.state.currentMessage.text,
            read: this.state.currentMessage.read,
            sender: this.state.senderName,
        }
        e.preventDefault()
        if (data.text.trim().length > 0) {
            let storedUser = localStorage.getItem('user');
            if (storedUser) {
                storedUser = JSON.parse(storedUser);
            }
            await this.socket.emit('chat message', { chatRoomId: this.props.currentChat.id, text: data.text, sender: this.sender, sender_name: this.state.senderName, date: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '') }, storedUser)
            this.setState({ currentMessage: await { text: '' } })
        }
        this.socket.emit('read message', { chatId: this.props.currentChat.id, token: JSON.parse(localStorage.getItem('user'))['accessToken'], readerId: this.sender.id })
    }

    scrollToBottom() {
        const { scrollHeight, clientHeight } = this.messageBox;
        this.messageBox.scrollTop = scrollHeight - clientHeight;
    }

    chooseButtonAction(e, action, data, msgId) {
        if (action === "accept" || action === "reject") {
            data = data.split('-')
            console.log("this.props.currentChat.candidate_id", this.props.currentChat.candidate_id)
            this.socket.emit(action + " offer", { token: JSON.parse(localStorage.getItem('user'))['accessToken'], idCandidate: this.props.currentChat.candidate_id, idOffer: data[1], idMessage: msgId, chatRoomId: this.props.currentChat.id, sender_name: this.state.senderName, sender: this.sender })
            for (let i = 0; i < e.target.parentElement.children.length; i++) {
                if (e.target.parentElement.children[i].tagName === 'BUTTON') {
                    e.target.parentElement.children[i].disabled = true
                }
            }
        }
    }

    async displayMessage(msg) {
        var beforeItem = document.createElement('div')
        var afterItem = document.createElement('div')
        var item = document.createElement('li')
        var boldSenderName = document.createElement('span')
        boldSenderName.innerText = (msg.sender_name === "*system*" ? "Meetpharma" : msg.sender_name) + "\n"
        if (this.state.senderName === msg.sender_name) {
            boldSenderName.className = "text-xs text-white font-weight-bold font-italic"
            beforeItem.className = "right-arrow-chat border-primary"
            afterItem.className = "right-arrow-chat-after border-white"
            item.className = "from-me bg-primary text-white"
        } else if (msg.sender_name === "*system*") {
            boldSenderName.className = "text-xs text-light font-weight-medium font-italic"
            beforeItem.className = "left-arrow-chat border-secondary"
            afterItem.className = "left-arrow-chat-after border-white"
            item.className = "from-system bg-secondary text-white"
        } else {
            boldSenderName.className = "text-xs text-default font-weight-bold font-italic"
            beforeItem.className = "left-arrow-chat border-info"
            afterItem.className = "left-arrow-chat-after border-white"
            item.className = "from-them bg-info text-dark"
        }
        item.appendChild(beforeItem)
        item.appendChild(boldSenderName)
        var text = document.createElement('div')
        text.innerHTML = msg.text
        if (this.sender.role === "candidat" && msg.textCandidate) {
            text.innerHTML += " " + msg.textCandidate
        }
        if (this.sender.role === "titulaire" && msg.textPharmacy) {
            text.innerHTML += " " + msg.textPharmacy
        }
        item.appendChild(text)
        //IF message from *system* add buttons
        if (msg.sender_name === "*system*" && this.sender.role === "titulaire" && msg.buttonsPharmacy) {
            msg.buttonsPharmacy.forEach(button => {
                let btn = document.createElement('button')
                btn.classList = "btn btn-outline-white text-capitalize mt-2"
                btn.innerHTML = button.innerHTML
                btn.onclick = (e) => {
                    this.chooseButtonAction(e, button.action, button.data, msg.id)
                }
                // if button has no action -> disabled
                if (button.action === "none") {
                    btn.disabled = true
                }
                item.appendChild(btn)
            })
        }
        var date = document.createElement('span')
        date.style.fontSize = "10px"
        if (!msg.time)
            date.innerText = new Date(msg.time).toISOString().replace(/T/, " ").replace(/\..+/, "")
        item.appendChild(date)
        item.appendChild(afterItem)
        await this.messageBox.appendChild(item)
        this.setState({ newMessageAdded: true });
        return item
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.newMessageAdded && !prevState.newMessageAdded) {
            this.scrollToBottom();
            this.setState({ newMessageAdded: false }); // Reset the trigger
        }
    }


    async retrieveMessages() {
        this.setState({ listOffersOpen: false, listOffers: [] })
        if (this.props.offersVisible) {
            this.openListOffers()
        }
        this.setState({ acceptationDate: this.props.currentChat.acceptation_date })
        this.setState({ archived: this.props.currentChat.archived })
        //when linked to database
        this.setState({ listMessages: this.props.currentChat.messages })
        this.messageBox.textContent = '' // deletes instant messages when changing chat
        this.setState({
            currentMessage: {
                id: null,
                text: "",
                read: false,
            }
        })
        var previousDay = false
        var newMessage = true
        this.state.listMessages.forEach(msg => {
            if (!previousDay || !miscUtils.checkSameDate(previousDay, new Date(msg.time))) {
                previousDay = new Date(msg.time)

                let date = moment(new Date(msg.time)).format("D/MM/YYYY")
                let item = document.createElement('li')
                item.className = "d-contents"
                item.innerHTML = "<div class='strike'><span class='text-light'>" + date + "</span></div>"
                this.messageBox.appendChild(item)
            }
            if (newMessage && msg.sender_name !== this.state.senderName && !msg.read) {
                newMessage = false
                let item = document.createElement('li')
                item.className = "d-contents"
                item.innerHTML = "<div class='strike strike-primary'><span class='text-primary'>Nouveau(x) message(s)</span></div>"
                this.messageBox.appendChild(item)
            }
            this.displayMessage(msg)
            //Scroll to last unread message
            if (msg.sender_name !== this.state.senderName && msg.read === false && this.state.atLastRead === false) {
                this.scrollToBottom()
                this.setState({ atLastRead: true })
            }
        })
        if (!this.state.atLastRead) {
            this.messageBox.scrollTo(0, this.messageBox.scrollHeight)
        }
        this.setState({ atLastRead: false })
        if (this.messageBox.scrollHeight - this.messageBox.scrollTop === this.messageBox.clientHeight) {
            this.socket.emit('read message', { chatId: this.props.currentChat.id, token: JSON.parse(localStorage.getItem('user'))['accessToken'], readerId: this.sender.id })
            this.props.markChatRead(this.props.currentChat.id)
        }
    }

    onScroll(e) {
        const threshold = 1;
        const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < threshold;
        if (bottom) {
            if (!this.state.autoScrolled) {
                this.props.markChatRead(this.props.currentChat.id)
                this.socket.emit('read message', { chatId: this.props.currentChat.id, token: JSON.parse(localStorage.getItem('user'))['accessToken'], readerId: this.sender.id })
                this.setState({ atBottom: true })
            } else {
                this.setState({ autoScrolled: false })
            }
        } else {
            this.setState({ atBottom: false })
        }
    }

    async openListOffers() {
        if (this.state.listOffersOpen) {
            this.setState({ listOffers: [], listOffersOpen: false })
        } else {
            var response, newListOffers
            this.setState({ listOffers: [], listOffersOpen: true })
            if (this.sender.role === "candidat") {
                var candidate = await candidateService.getCandidateInfo(this.props.currentChat.candidate_id)
                response = await offerService.getOffersInRadius(candidate.preferedLatitude, candidate.preferedLongitude, candidate.radius * 1000)
                newListOffers = []
                response.forEach(response => {
                    if (response.offer.pharmacy_id === this.props.currentChat.pharmacy_id && response.offer.active) {
                        newListOffers.push(response.offer)
                    }
                })
            } else {
                response = await offerService.getAllOffers()
                newListOffers = []
                response.offers.forEach(offer => {
                    if (offer.active) {
                        newListOffers.push(offer)
                    }
                })
            }
            this.setState({ listOffers: newListOffers, listOffersOpen: true })
        }
    }

    validateOffer(offerId, index, e) {
        if (this.sender.role === "candidat") {
            this.socket.emit('request validation', {
                offerId: offerId,
                userToken: this.sender.accessToken,
                chatRoomId: this.props.currentChat.id,
                offerName: e.target.textContent,
                sender_name: this.state.senderName,
                idCandidate: this.sender.id,
                idOffer: e.target.id
            })
            e.target.style.backgroundColor = "LightBlue"
            e.target.innerHTML = "*" + e.target.innerHTML + " - Demande envoyée*"
            e.target.removeAttribute("onclick") //Doesn't work
            e.target.onclick = () => { alert("demande déjà envoyée") }
        } else if (this.sender.role === "titulaire") {
            this.socket.emit("accept offer", {
                chatRoomId: this.props.currentChat.id,
                token: JSON.parse(localStorage.getItem('user'))['accessToken'],
                idCandidate: this.props.currentChat.candidate_id,
                idOffer: offerId,
                idMessage: null,
                sender: this.sender
            })
            var newListOffers = this.state.listOffers
            newListOffers.splice(index, 1)
            this.setState({ listOffers: newListOffers })
        }
    }

    acceptRequest() {
        this.socket.emit('chat accept', ({ chatRoomId: this.props.currentChat.id, token: this.sender.accessToken }))
    }
    async displayProfile() {
        await this.setInterlocutor()
        let { interlocutorId } = this.state
        if (interlocutorId !== this.state.displayedProfile.id) {
            var DisplayedProfile
            if (this.sender.role === 'titulaire') {
                let response = await candidateService.getCandidateInfo(interlocutorId)
                DisplayedProfile = response
                DisplayedProfile.id = response.candidateId
                delete DisplayedProfile.account
                delete DisplayedProfile.city
                delete DisplayedProfile.preferedLatitude
                delete DisplayedProfile.preferedLongitude
                delete DisplayedProfile.radius
                DisplayedProfile.softwareName = []
                DisplayedProfile.software.forEach(software => {
                    DisplayedProfile.softwareName.push(accountConfig.softwareList[software])
                })
                DisplayedProfile.diploma_validated = response.diplomaValidated
                DisplayedProfile.cv_validated = response.cvValidated
                DisplayedProfile.kbis_validated = response.kbisValidated
            } else {
                let response = await pharmacyService.getPharmacyInfo(interlocutorId)
                DisplayedProfile = response
                DisplayedProfile.id = response.pharmacyId
                delete DisplayedProfile.subcriptionStatus
            }
            await this.setState({ displayedProfile: await DisplayedProfile })
        }
        this.setState({ clickedProfile: true })
        if (this.state.listOffers.length < 1) {
            this.openListOffers()
        }
    }

    deactivateOverlay() {
        this.setState({ clickedProfile: false })
        if (this.state.listOffers) {
            this.setState({ listOffers: [] })
        }
        if (this.state.clickedOffer) {
            this.setState({ clickedOffer: false })
        }
        if (this.state.listOffersOpen) {
            this.setState({ listOffersOpen: false })
        }
    }
    closeOffer() {
        this.setState({ clickedOffer: null })
    }
    async setClickedOffer(idOffer) {
        var clickedOffer = await offerService.getOneOffer(idOffer)
        this.setState({ clickedOffer })
    }

    setConfirmationMenuRef(node) {
        this.confirmationMenuRef = node
    }
    setConfirmationButtonRef(node) {
        this.confirmationButtonRef = node
    }
    handleClickOutside(e) {
        //isMenuOpen && ref.current && !ref.current.contains(e.target)
        if (this.state.listOffersOpen && !this.state.clickedProfile && this.confirmationMenuRef && !this.confirmationMenuRef.contains(e.target) && this.confirmationButtonRef && typeof this.confirmationButtonRef.contains === "function" && !this.confirmationButtonRef.contains(e.target)) {
            this.deactivateOverlay()
        }
    }

    async handleSubscriptionStatus() {
        const subscriptionStatus = await stripeService.checkSubscriptionStatus(
            this.state.user.email
        );
        console.log('=============== subscriptionStatus', subscriptionStatus);
        console.log('============ this.state.user.email', this.state.user.email);


        if (subscriptionStatus.free !== true && subscriptionStatus.subscribed !== true && subscriptionStatus.trialEnd !== true) {
            console.log('==== We are not subscribed');
            setTimeout(() => {
                this.props.history.push(`/pricingTable?email=${encodeURIComponent(this.state.user.email)}`);
            }, 1 * 1000); // 1 second
        }
    }

    async componentDidMount() {
        if (this.state.user && !this.state.isAdmin) {
            const subscriptionPromise = this.handleSubscriptionStatus();
            await Promise.all([subscriptionPromise]);
        }
        this.setState({ showButton: true });
        this.messageBox = document.getElementById('messages')
        this.setInterlocutor()

        this.socket.on('chat accepted', () => {
            this.props.markChatAccepted(this.props.currentChat.id)
            this.setState({ acceptationDate: this.props.currentChat.acceptation_date })
            // this.setState({acceptationDate : new Date()})
        })
        this.socket.on('chat archived', () => {
            this.props.markChatArchived(this.props.currentChat.id)
            this.setState({ archived: this.props.currentChat.archived })
            // this.setState({acceptationDate : new Date()})
        })

        this.socket.on('chat received', async ({ msg }) => {
            if (msg.chatRoomId === this.props.idChat) {
                this.displayMessage(msg)
            }
        })

        document.addEventListener('mouseup', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleClickOutside)
    }

    render() {
        const { currentMessage, listOffers, listOffersOpen, acceptationDate, clickedProfile, displayedProfile, clickedOffer, shifts, archived } = this.state
        return (
            <div className='chat-app'>
                {archived && <div className="overlay-blocker bg-light opacity-4 rounded d-flex align-items-center">
                    <div className="text-center w-100">
                        <span>Conversation archivée</span>
                    </div>
                </div>
                }
                {!acceptationDate && <div className="overlay-blocker bg-light opacity-4 rounded d-flex align-items-center">
                    <div className="text-center w-100">
                        {this.props.currentChat.initiator === this.state.senderName &&
                            <span>Invitation envoyée ...<br /><br /><i className="fa fa-paper-plane fa-2x" /></span>
                        }
                    </div>
                </div>
                }
                {!acceptationDate && this.props.currentChat.initiator !== this.state.senderName && (
                    <div className="overlay-blocker d-flex align-items-center" style={{ maxHeight: '500px' }}>
                        <div className="text-center w-100">
                            <Button color="primary" className="opacity-10" onClick={() => this.acceptRequest()}>
                                <i className="far fa-user-check" /> Accepter la conversation
                            </Button>
                        </div>
                    </div>
                )}
                <Row className="position-fixed mt--5 chat-title" style={{ zIndex: 300 }}>
                    <Col className="text-left pt-2 position-relative">
                        <Row>
                            {!this.props.chatListVisible && <Col className="mobile-list-button d-block d-md-none col-auto pl-2 pr-0">
                                <span className="fa-stack" onClick={this.props.toggleChatList}>
                                    <i className="fas fa-circle fa-stack-2x text-primary"></i>
                                    <i className="fas fa-angle-left fa-stack-1x text-white"></i>
                                </span>
                            </Col>}
                            <Col className="col-auto cursor-pointer text-primary pl-2 p-0" onClick={() => this.displayProfile()}>
                                {this.props.currentChat.contactImage ? <img className="rounded-circle border-light border" src={this.props.currentChat.contactImage} style={{ height: "32px" }} alt="Avatar du contact" /> : <Avatar name={this.props.currentChat.contact} size="32" round="32px" />}
                            </Col>
                            <Col className="col-auto cursor-pointer text-primary p-0" onClick={() => this.displayProfile()}>
                                <span className="h3 text-primary px-2 text-lg d-none d-lg-inline-block">{this.props.currentChat.contact} {this.props.currentChat.contactPosition ? (' (' + accountConfig.positionList[this.props.currentChat.contactPosition] + ')') : ''}</span>
                                <span className="h3 text-primary px-2 text-md d-inline-block d-lg-none" style={{ maxWidth: "150px", lineHeight: "1.0" }}>{this.props.currentChat.contact} {this.props.currentChat.contactPosition ? (' (' + accountConfig.positionList[this.props.currentChat.contactPosition] + ')') : ''}</span>
                            </Col>
                            {/* {(!archived && acceptationDate) && <Col ref={this.setConfirmationButtonRef}>
                                <Button className="float-right text-lg px-2 py-0" color="primary" size="md" onClick={() => this.openListOffers()} title={
                                    (this.sender.role === 'titulaire') ? "Valider le recrutement" : "Demander une confirmation de recrutement"}><i className="fa fa-handshake" /><span className="d-none d-lg-inline-block text-sm">{
                                        (this.sender.role === 'titulaire') ? "Valider le recrutement" : "Demander une confirmation de recrutement"}</span><i className="fa fa-angle-down" /></Button>
                            </Col>} */}
                        </Row>


                        {(this.sender.role === 'candidat') && <Row style={{ fontSize: "14px" }}><Col className="mx-4 my-2 py-2 bg-secondary text-white rounded">
                            <Row><Col className="col-auto pt-1"><i className="fa fa-exclamation-triangle fa-2x" /></Col><Col>Vous prenez un risque en envoyant votre numéro de téléphone / email<br />
                                Ici nous garantissons la sécurité de vos échanges, c’est pourquoi nous vous conseillons de rester sur la
                                messagerie pour discuter</Col></Row>
                        </Col></Row>}
                        {listOffersOpen && ((listOffers && listOffers.length > 0) ?
                            <ul ref={this.setConfirmationMenuRef} className="list-group bg-white position-absolute mt-3 rounded shadow p-3" style={{ left: "20px", right: "20px", zIndex: 100 }} id="listOffers">
                                {(this.sender.role === 'candidat') ? <h3>Veuillez sélectionner l'offre pour laquelle vous demandez une confirmation de recrutement :</h3> : <h3>Veuillez séléctionner l'offre pour laquelle vous souhaitez valider le recrutement de {this.props.currentChat.contact} :</h3>}
                                {/* {(this.sender.role === 'candidat') ? <h5>Nous envoyerons ensuite un message à  pour vérifier que votre recrutement est bien validé.</h5> : <h5>Votre offre sera ensuite archivée et attribuée à {this.props.currentChat.contact}.</h5>} */}
                                {listOffers.map((offer, index) => {
                                    return <li
                                        className="dropdown-item cursor-pointer border-bottom border-ligth"
                                        onClick={(e) => this.validateOffer(offer.id, index, e)}
                                        key={index}
                                        id={offer.id}
                                    >
                                        <i className="far fa-handshake pr-2" />
                                        <span className="text-capitalize">{accountConfig.positionList[offer.disponibility.position]}</span>
                                        {offer.disponibility.date_end ?
                                            " du " + new Date(offer.disponibility.date_start).toLocaleString().split(',')[0] + " au" + new Date(offer.disponibility.date_end).toLocaleString().split(',')[0]
                                            :
                                            " à partir du " + new Date(offer.disponibility.date_start).toLocaleString().split(',')[0]
                                        }
                                    </li>
                                })}</ul>
                            : <ul ref={this.setConfirmationMenuRef} className="list-group bg-white position-absolute mt-3 rounded shadow p-3" style={{ left: "20px", right: "20px", zIndex: 100 }} id="listOffers">
                                <span>Actuellement aucune offre n'est disponible</span>
                            </ul>)
                        }

                    </Col>
                </Row>
                <div className="d-flex flex-column chat-container">
                    <ul id="messages"
                        className="imessage"
                        onScroll={(e) => this.onScroll(e)}>
                    </ul>
                    <Row className="align-items-end position-relative">
                        <Col style={{ zIndex: 100, position: "absolute", top: "-55px" }}>
                            {this.state.atBottom ? <></> : <Button className="rounded-circle shadow-none p-0" style={{ width: "38px", height: "38px" }} color="light" onClick={(e) => { e.preventDefault(); this.scrollToBottom() }}><i className="fa fa-angle-down fa-lg text-default  mt-2" /></Button>}
                        </Col>
                        <Col className="col-9">
                            <CKEditor
                                config={{
                                    toolbar: ['bold', 'italic', 'undo', 'redo',]
                                }}
                                editor={ClassicEditor}
                                data={currentMessage.text}
                                onChange={(event, editor) => {
                                    this.onChangeMessage(editor.getData())
                                }}
                            />
                        </Col>
                        <Col className="col-3">
                            <Button
                                onClick={(e) => this.onSendMessage(e)}
                                color="primary" size="xl"
                                type="submit"
                                disabled={!acceptationDate}
                            ><i className="fas fa-paper-plane"></i><span className="d-none d-lg-inline-block">Envoyer</span></Button>
                        </Col>
                    </Row>
                </div>
                {clickedProfile ? <>
                    <div className="overlay" id="overlay" style={overlayStyle} onClick={() => this.deactivateOverlay()} />
                    <div className="information p-3 p-md-5" id="informationPage" style={informationStyle}>
                        <Row>
                            <Col className="text-right">
                                <Button outline color="primary" onClick={() => this.deactivateOverlay()} className="mb--4 mr--4 bg-white style" style={{ zIndex: "1080" }}><i className="fas fa-times fal-lg" /></Button>
                            </Col>
                        </Row>
                        <Row className="position-relative">
                            <Col style={{ minHeight: "220px" }}>
                                <div alt="bannière"
                                    className="rounded-lg w-100 h-100"
                                    style={{
                                        backgroundImage: "url('" + (displayedProfile.banner ? displayedProfile.banner : require("../assets/img/default-banner.jpg").default) + "')",
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                    }}
                                ></div>
                            </Col>
                            <div className="rounded-lg position-absolute shadow bg-white" style={{ maxWidth: '250px', height: '130px', width: '100%', bottom: '-40px', left: '40px', overflow: 'hidden' }}>
                                <div alt='logo' className="rounded-lg border-white w-100 h-100"
                                    style={{
                                        border: "2px solid",
                                        backgroundImage: "url('" + (displayedProfile.logo ? displayedProfile.logo : require("../assets/img/default-thumbnail-profil.jpg").default) + "')",  // add pharmacy logo
                                        backgroundPosition: "center",
                                        backgroundSize: "cover"
                                    }}
                                ></div>
                            </div>
                        </Row>
                        <Row style={{ marginTop: '50px' }}>
                            <Col className="text-left text-primary h1">
                                <span>{displayedProfile.candidateId ? displayedProfile.candidateName : displayedProfile.pharmacyName}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>Liste des offres</span>
                                {listOffersOpen && ((listOffers && listOffers.length > 0) ?
                                    <ul className="list-group bg-white mt-3 rounded shadow p-3" style={{ left: "20px", right: "20px", zIndex: 100 }} id="listOffers">
                                        {listOffers.map((offer, index) => {
                                            return <li
                                                className="dropdown-item cursor-pointer border-bottom border-ligth"
                                                // onClick={}
                                                key={index}
                                                id={offer.id}
                                                onClick={() => this.setClickedOffer(offer.id)}
                                            >
                                                <i className="far fa-handshake pr-2" />
                                                <span className="text-capitalize">{accountConfig.positionList[offer.disponibility.position]}</span>
                                                {offer.disponibility.date_end ?
                                                    " du " + new Date(offer.disponibility.date_start).toLocaleString().split(',')[0] + " au" + new Date(offer.disponibility.date_end).toLocaleString().split(',')[0]
                                                    :
                                                    " à partir du " + new Date(offer.disponibility.date_start).toLocaleString().split(',')[0]
                                                }
                                            </li>
                                        })}</ul>
                                    : <ul className="list-group bg-white mt-3 rounded shadow p-3" style={{ left: "20px", right: "20px", zIndex: 100 }} id="listOffers">
                                        <span>Actuellement aucune offre n'est disponible</span>
                                    </ul>)
                                }
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col className="text-left">
                                <span className="text-gray">Détails du compte</span>
                                <hr className="mt-2 mb-3" />
                            </Col>
                        </Row>
                        {displayedProfile.candidateId ? <>
                            <Row className="h1">
                                <Col className="col-auto pl-0 pr-0">
                                    <Badge className="bg-white text-primary px-3 text-lowercase"><span className="text-capitalize font-weight-light">Poste:</span><span>{displayedProfile.position}</span>
                                    </Badge>
                                </Col>
                                <Col className="col-auto pr-0">
                                    <Badge color="secondary" className="px-3"><span className="text-capitalize font-weight-light">Type</span><span className="font-weight-light text-lowercase"> de contrat : </span><span>{displayedProfile.contractType}</span></Badge>
                                </Col>
                                <Col className="col-auto pr-0">
                                    <Badge className="bg-gray px-3 text-lowercase"><span className="text-capitalize font-weight-light">Rémunération : </span><span>{displayedProfile.salary} € / h</span></Badge>
                                </Col>
                                <Col className="col-auto pr-0">
                                    <Badge className="bg-light text-gray-dark">
                                        <span className="text-capitalize font-weight-light">LGO</span><span className="font-weight-light text-lowercase"> connus :</span>{displayedProfile.softwareName.map((software) => (
                                            <span key={software}>
                                                &nbsp;{software}&nbsp;
                                            </span>
                                        ))}
                                    </Badge>
                                </Col>
                                {displayedProfile.cv_validated &&
                                    <Col className="col-auto pr-0">
                                        <Badge className="bg-default px-3 text-lowercase"><i className="fas fa-user-check" /><span className="pl-2 text-capitalize font-weight-light">CV vérifié</span></Badge>
                                    </Col>
                                }
                                {displayedProfile.diploma_validated &&
                                    <Col className="col-auto pr-0">
                                        <Badge className="bg-default px-3 text-lowercase"><i className="fas fa-clipboard-check" /><span className="pl-2 text-capitalize font-weight-light">Diplôme vérifié</span></Badge>
                                    </Col>
                                }
                                {displayedProfile.kbis_validated &&
                                    <Col className="col-auto pr-0">
                                        <Badge className="bg-default px-3 text-lowercase"><i className="fas fa-building" /><span className="pl-2 text-capitalize font-weight-light">Kbis vérifié</span></Badge>
                                    </Col>
                                }
                            </Row>
                            {displayedProfile.info && <>
                                <Row className="mt-3">
                                    <Col className="text-left">
                                        <span className="text-gray">Informations supplémentaires</span>
                                        <hr className="mt-2 mb-2" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-left">
                                        {displayedProfile.info}
                                    </Col>
                                </Row>
                            </>}
                            <Row className="mt-5">
                                <Col className="text-left">
                                    <span className="text-gray">Horaires du candidat</span>
                                    <hr className="mt-2 mb-2" />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p>Flexibilité des horaires : {displayedProfile.shiftsFlexibility ? "oui" : "non"}</p>
                                    <ShiftCalendarDisplay
                                        parentShifts={displayedProfile.shifts}
                                    />
                                </Col>
                            </Row>
                        </> : <>
                            <Row><Col className="text-left">Addresse :&nbsp;
                                {displayedProfile.city} - {displayedProfile.zip}
                            </Col></Row>
                            <Row><Col className="text-left">
                                {displayedProfile.address}
                            </Col></Row>
                            <Row className="h1">
                                <Col className="col-auto pr-0">
                                    <Badge className="bg-light text-gray-dark">
                                        <span className="text-capitalize font-weight-light">LGO</span><span className="font-weight-light text-lowercase"> utilisé :</span><span>
                                            {displayedProfile.software}</span>
                                    </Badge>
                                </Col>
                            </Row>
                        </>}
                        <Col className="text-left">
                            <Button outline color="default" onClick={() => this.deactivateOverlay()} className="border-info"><i className="fas fa-angle-left pr-1 fa-lg text-primary" /> Retourner au chat</Button>
                        </Col>

                    </div>
                </> : <>

                </>}
                {/* OFFER OPEN */}

                {clickedOffer && <OfferDisplay
                    clickedOffer={clickedOffer}
                    shiftsCandidate={shifts}
                    deactivateOverlay={this.closeOffer}
                    onDemandContact={this.onDemandContact}

                />}
            </div>
        )
    }
}