import http from '../http-common'
import { checkPermissionAndSubscribe, subscribeToPush } from '../serviceWorkerRegistration';
import AccountHeader from "./account-header"

class AccountService {
    login(email, password) {
        return http
            .post(
                `/accounts/login`,
                { email, password }, {}
            )
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data))
                    checkPermissionAndSubscribe();
                }

                return response.data
            })
    }

    logout() {
        localStorage.removeItem("user")
        document.location.reload()
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'))
    }

    findOne(id) {
        return http.get(`/accounts/${id}`, { headers: AccountHeader() })
    }

    requestReset(email) {
        return http.post("/accounts/requestReset", { email: email }, { headers: AccountHeader() }).catch((err) => {
            return false
        })
    }

    checkActivationCode(code) {
        return http.post("/accounts/checkActivation", { activationLink: code }).catch((err) => {
            console.log(err)
            return false
        })
    }

    activatePassword(code, password) {
        return http.post("/accounts/activation", { activationLink: code, password: password }, {})
    }

    checkResetCode(code) {
        return http.post("/accounts/checkReset", { resetLink: code }, {}).catch((err) => {
            console.log(err)
            return false
        })
    }

    resetPassword(resetCode, password) {
        return http.post("/accounts/reset", { password: password, resetLink: resetCode }, {})
    }

    sendContact(data) {
        return http.post("/accounts/sendContact", data)
    }

    saveFrom(data) {
        return http.post("/accounts/saveFrom", data)
    }
}

export default new AccountService()