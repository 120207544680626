const overlayStyle = {
    position: "fixed", /* Sit on top of the page content */
    width: "100%", /* Full width (cover the whole page) */
    height: "100%", /* Full height (cover the whole page) */
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "black", /* Black background with opacity */
    opacity: 0.3,
    zIndex: 300, /* Specify a stack order in case you're using a different order for other elements */
    cursor: "pointer", /* Add a pointer on hover */
}

const informationStyle = {
    overflowY: 'scroll',
    position: "fixed",
    width:"100%",
    maxWidth: "1000px",
    height: "calc(100% - 78px)",
    bottom: 0,
    left: 0,
    backgroundColor: "white",
    zIndex: 400,
}

const returnButtonStyle = {
    marginRight: "80%",
    position: "relative",
    top: 0,
    left: 0,
}
const exitButtonStyle = {
    marginLeft: "10%",
    position: "fixed",
    top: 0,
    fontSize: "xx-large",
}

const deleteButton = {
    backgroundColor: "Brown",
    verticalAlign: "top",
}

const editButton = {
    backgroundColor: "DodgerBlue",
    verticalAlign: "top",
}

export { overlayStyle, informationStyle, returnButtonStyle, exitButtonStyle, deleteButton, editButton }