import React, { Component } from "react";
import { requestNotificationPermission } from "../serviceWorkerRegistration";
import { Col, Modal, Button } from 'reactstrap'
import { overlayStyle } from "../styles/mapPage.style"

class Prompt extends Component {
  constructor(props) {
    super(props);
    const promptClicked = localStorage.getItem('promptClicked') === 'true';


    this.state = {
      isVisible: ('Notification' in window),
      permission: "default", // default, granted, or denied
      promptClicked: promptClicked
    };
  }

  componentDidMount() {
    if ('Notification' in window) {
      const permission = Notification.permission;
      this.setState({
        isVisible: this.state.isVisible,
        permission: permission,
        promptClicked: this.state.promptClicked
      });
    }
  }

  confirmAction = async () => {
    this.setState({
      isVisible: false,
    });
    localStorage.setItem('promptClicked', 'true'); // Save that the prompt has been clicked
    const permission = await requestNotificationPermission();
    this.setState({
      permission: permission
    });
  };

  render() {
    const { isVisible, permission, promptClicked } = this.state;

    const promptContainerStyle = {
      backgroundColor: "rgba(255, 255, 255, 0.95)",
      width: "17em",
      borderRadius: "11px",
      padding: "2em",
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: isVisible ? "block" : "none",
      zIndex: 9999999,
      overflow: "hidden", // Add this line to disable scrolling within the modal
    };
    return (
      <>
        {/* Render the prompt only the first time before it gets clicked */}
        {(isVisible && permission === "default" && !promptClicked) && (
          <Modal
            isOpen={true}
            centered
            scrollable={false}
            toggle={this.toggle}
          >
            <div style={promptContainerStyle}>
              <div style={{ display: "flex-col", textAlign: "center", justifyContent: "center", gap: "16px" }}>
                <Col>
                  <div className="h3 text-primary mb-4 mt--2" style={{ lineHeight: '1.1' }}>Activez les notifications pour ne rien manquer ! 📣</div>
                </Col>
                <span className='text-default'>Nous aimerions vous tenir au courant des dernières mises à jour et offres excitantes. En activant les notifications, vous ne manquerez aucune nouvelle importante ou offre spéciale.</span>

                <Button {...this.props}
                  color={this.props.color ? this.props.color : 'primary'}
                  className="mt-4"
                  onClick={this.confirmAction}>
                  Activer notification
                </Button>
              </div>
            </div>
          </Modal>
        )
        }
      </>
    );
  }
}

export default Prompt;
