import http from "../http-common"
import accountHeader from './account-header'

class City {
    findCities(content) {
        return http.get(`/cities/${content}`, { headers: accountHeader() }).then(response=>{
            return response.data
        }).catch((err) => {
            return console.log(err)
        })
    }
}

export default new City()


/*var idCandidate = await axios.get(API_URL + `byAccount/${idAccount}`, { headers: accountHeader() }) */