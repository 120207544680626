import React from "react";
// reactstrap components
import {
    Col,
    Row
} from "reactstrap";
import _uniqueId from 'lodash/uniqueId';

function FacileToggle({ label, labelOff = "Non", labelOn = "Oui", color = "primary", id, handleChange, type = "checkbox", value = "" }) {
    if (!id) id = _uniqueId('input_')

    return (
        <Row>
            <Col className="col-auto">
                <label className={"d-inline-block custom-toggle align-middle custom-toggle-" + color}>
                    <input checked={value}
                        id={id}
                        type={type}
                        onChange={handleChange}
                    />
                    <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off={labelOff}
                        data-label-on={labelOn}
                    />
                </label>
            </Col>

            {label && <Col><label className="d-inline-block my-0" htmlFor={id}
            >{label}</label></Col>}
        </Row>
    );
}

export default FacileToggle;