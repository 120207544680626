import React, {
  Component
} from 'react'
import GoogleMapReact from 'google-map-react'
import config from '../config/misc.config'


import MarkerOnMap from './markerOnMap.component'
import miscConfig from '../config/misc.config'

const options = {
  zoomControl: false,
  scrollwheel: false,
  scaleControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  keyboardShortcuts: false,
  gestureHandling: "cooperative",
  disableDoubleClickZoom: true,
  styles: [{
    "elementType": "geometry",
    "stylers": [{
      "color": "#f5f5f5"
    }]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#616161"
    }]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [{
      "color": "#f5f5f5"
    }]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#bdbdbd"
    }]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [{
      "color": "#eeeeee"
    }]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#757575"
    }]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [{
      "color": "#e5e5e5"
    }]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#9e9e9e"
    }]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [{
      "color": "#ffffff"
    }]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#757575"
    }]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [{
      "color": "#dadada"
    }]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#616161"
    }]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#9e9e9e"
    }]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [{
      "color": "#e5e5e5"
    }]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [{
      "color": "#eeeeee"
    }]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [{
      "color": "#c9c9c9"
    }]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#9e9e9e"
    }]
  }
  ]
}

function createMapOptions(maps) {
  return {
    ...options,
    mapTypeControlsOptions: {
      position: maps.ControlPosition.TOP_RIGHT
    }
  }
}

function pharmacyMapOptions(maps) {
  return {
    ...options,
    draggable: false,
    mapTypeControlsOptions: {
      position: maps.ControlPosition.TOP_RIGHT
    }
  }
}

// return map bounds based on list of points
function getMapBounds(map, maps, points, center) {
  const bounds = new maps.LatLngBounds()
  var lat, lng
  if (points) {
    points.forEach((point) => {
      if (point.offer) {
        lat = point.offer.pharmacy.latitude
        lng = point.offer.pharmacy.longitude
      } else {
        lat = point.candidate.latitude
        lng = point.candidate.longitude
      }
      bounds.extend(new maps.LatLng(
        lat,
        lng,
      ))
    })
    bounds.extend(new maps.LatLng(
      center.lat,
      center.lng
    ))
  } else {
    bounds.extend(new maps.LatLng(
      center.lat,
      center.lng
    ))
    bounds.extend(new maps.LatLng(
      bounds.getNorthEast().lat() + 0.001,
      bounds.getNorthEast().lng() + 0.001
    ))
    bounds.extend(new maps.LatLng(
      bounds.getNorthEast().lat() - 0.002,
      bounds.getNorthEast().lng() - 0.002
    ))
    return bounds
  }
  return bounds
}

// Re-center the map when resizing the window
function bindResizeListener(map, maps, bounds) {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'rezise', () => {
      map.fitBounds(bounds)
    })
  })
}

// Fit map to its bounds after the api is loaded
function apiIsLoaded(map, maps, points, center) {
  if (map && maps && points && center) {
    // get bounds by our points
    const bounds = getMapBounds(map, maps, points, center)
    // fit map to bounds
    map.fitBounds(bounds)
    //bind resize listener
    bindResizeListener(map, maps, bounds)

  }
}

function getBoundsZoomLevel(height, width, bounds, mapDim) {
  var worldDim = {
    height,
    width
  }
  var zoomMax = 16

  function latRad(lat) {
    var sin = Math.sin(lat * Math.PI / 180)
    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2
    return Math.max(Math.min(radX2, Math.PI), -Math.LN2) / 2
  }

  function zoom(mapPx, worldPx, fraction) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
  }

  var ne = bounds.getNorthEast()
  var sw = bounds.getSouthWest()

  var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI

  var lngDiff = ne.lng() - sw.lng()
  var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360
  var latZoom = zoom(mapDim.height, worldDim.height, latFraction)
  var lngZoom = zoom(mapDim.width, worldDim.width, lngFraction)
  return Math.min(latZoom, lngZoom, zoomMax)
}

export default class Map extends Component {
  constructor(props) {
    super(props)
    this._onChange = this._onChange.bind(this)
    this.setClickedMarker = this.setClickedMarker.bind(this)
    this.updateMarkers = this.updateMarkers.bind(this)
    this.state = {
      center: this.props.centerFromParent,
      zoom: this.props.zoomFromParent,
      info: {},
      isRefreshingMap: false,
      myPoints: this.props.parentDisponibilities,
      searchRadius: "",
      map: "",
      maps: ""

    }
  }
  static defaultProps = {
    center: {
      lat: 48.5,
      lng: 0.2851
    },
    zoom: 11,
  }
  changeOffer() {
    apiIsLoaded(this.state.map, this.state.maps, this.props.parentDisponibilities, this.state.center)
  }

  _onChange = async (info, city) => { //to retrieve the center AND the bounds
    this.setState({
      center: info.center,
      info
    })
    if (this.state.center.lat.toFixed(6) !== this.props.centerFromParent.lat.toFixed(6) && this.state.center.lng.toFixed(6) !== this.props.centerFromParent.lng.toFixed(6)) {
      this.props.canUpdateMap()
    }
    if (this.state.circleLeeway && this.state.mapH && this.state.mapW) {
      var zoom = getBoundsZoomLevel(256, 256, this.state.circleLeeway.getBounds(), {
        height: this.state.mapH,
        width: this.state.mapW
      })
      this.setState({
        zoom
      })
    }
    if (this.state.maps) {
      var center = {
        lat: this.state.map.center.lat(),
        lng: this.state.map.center.lng()
      }
      this.setState({
        center
      })
    }
  }

  setActiveMarker = (childData) => {
    this.props.setActiveMarker(childData)
    this.setState({
      searchRadius: this.props.zoomFromParent
    })
  }

  updateMarkers(myPoints) {
    this.setState({
      myPoints
    })
    if (this.state.circle) {
      this.state.circleLeeway.setMap(null)
      this.state.circle.setMap(null)
      this.setState({
        circle: new this.state.maps.Circle({
          strokeColor: '#aaa',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillOpacity: 0.0,
          map: this.state.map,
          // center: this.state.center,
          center: this.props.centerFromParent,
          radius: this.props.radiusFromParent,
          draggableCursor: "hand",
        })
      })
      this.setState({
        circleLeeway: new this.state.maps.Circle({
          strokeOpacity: 0.0,
          fillOpacity: 0.0,
          map: this.state.map,
          // center: this.state.center,
          center: this.props.centerFromParent,
          radius: this.props.radiusFromParent * (1 + miscConfig.searchDistanceLeeway),
          draggableCursor: "hand",
        })
      })
      this.state.map.fitBounds(this.state.circleLeeway.getBounds())
    }
  }

  setClickedMarker(idOffer) {
    this.props.setClickedMarker(idOffer)
  }

  render() {
    var Markers
    if (this.props.mapType === 'candidate') {
      Markers = this.state.myPoints && this.state.myPoints.map((point, index) => (
        <MarkerOnMap
          key={point.offer.id}
          lat={point.offer.pharmacy.latitude}
          lng={point.offer.pharmacy.longitude}
          nompharma={point.offer.pharmacy.name}
          id={point.offer.id}
          distance={point.similarities.distance}
          shifts={point.similarities.shifts}
          contract={point.offer.disponibility.contract_type}
          remuneration={point.offer.disponibility.salary}
          address={point.offer.pharmacy.address}
          city={point.offer.pharmacy.city}
          zip={point.offer.pharmacy.zip}
          activeMarker={this.props.activeMarker}
          setActiveMarker={this.setActiveMarker}
          setClickedMarker={this.setClickedMarker}
          mapType={this.props.mapType}
        />
      ))
    } else if (this.props.mapType === 'pharmacy') {
      Markers = this.props.parentDisponibilities && this.props.parentDisponibilities.map((point, index) => (
        <MarkerOnMap
          key={point.candidate.id}
          lat={point.candidate.latitude}
          lng={point.candidate.longitude}
          name={point.candidate.name}
          id={point.candidate.id}
          distance={point.similarities.distance}
          shifts={point.similarities.shifts}
          contract={point.candidate.disponibility.contract_type}
          remuneration={point.candidate.disponibility.salary}
          activeMarker={this.props.activeMarker}
          setActiveMarker={this.setActiveMarker}
          setClickedMarker={this.setClickedMarker}
          mapType={this.props.mapType}
        />
      ))
    } else {
      Markers = this.props.parentDisponibilities && this.props.parentDisponibilities.map((point, index) => (
        <MarkerOnMap
          key={point.candidate.id}
          lat={point.candidate.latitude}
          lng={point.candidate.longitude}
          name={point.candidate.name}
          id={0}
          distance={point.similarities.distance}
          shifts={point.similarities.shifts}
          contract={point.candidate.disponibility.contract_type}
          remuneration={point.candidate.disponibility.salary}
          activeMarker={null}
          setActiveMarker={null}
          setClickedMarker={null}
          noActions={true}
          mapType={this.props.mapType}
        />
      ))
    }
    var center =
      <MarkerOnMap
        key={0}
        lat={this.state.center.lat}
        lng={this.state.center.lng}
        name={"center"}
        id={"center"}
        distance={null}
        shifts={null}
        contract={null}
        remuneration={null}
        activeMarker={null}
        setActiveMarker={null}
        setClickedMarker={null}
        noActions={true}
        mapType={'center'}
      />

    var mapSize
    if (this.props.mapType === "positionTest") {
      mapSize = "180px"
    } else if (this.props.mapType === "pharmacy") {
      mapSize = "min(30vh, 100vw)"
    } else {
      mapSize = (window.innerWidth > 991) ? "calc(100vh - 98px)" : "min(30vh, 100vw)"
    }
    return (
      <div style={{ boxShadow: "inset 13px 0px 13px -6px #878787" }}>
        {/* Important! Always set the container height explicitly */}
        <div style={{ height: mapSize, width: '100%' }}>
          {/* <div className="col-md-6"> */}
          {this.props.mapType === 'candidate' && <>
            <h5 name="center" className="text-blue" style={{ position: "absolute", zIndex: 100, width: "50px", height: "50px", top: '50%', left: '50%', margin: "-25px 0 0 -24px", pointerEvents: "none" }}>
              <i className="fas fa-map-pin text-primary fa-2x"></i>{/* center markeur */}
            </h5>
          </>
          }
          {!this.isRefreshingMap && (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: config.mapApiKey,
                language: 'fr'
              }}
              center={this.state.center}
              defaultZoom={12}
              yesIWantToUseGoogleMapApiInternals
              onChildMouseEnter={this.onChildMouseEnter}
              onChildMouseLeave={this.onChildMouseLeave}
              onGoogleApiLoaded={({ map, maps }) => {
                this.setState({ map, maps })
                if (this.props.mapType === 'candidate') {
                  this.setState({
                    circle: new maps.Circle({
                      strokeColor: '#aaa',
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillOpacity: 0.0,
                      map,
                      center: this.props.centerFromParent,
                      radius: this.props.radiusFromParent,
                    })
                  })
                  this.setState({
                    circleLeeway: new this.state.maps.Circle({
                      strokeOpacity: 0.0,
                      fillOpacity: 0.0,
                      map: this.state.map,
                      center: this.props.centerFromParent,
                      radius: this.props.radiusFromParent * (1 + miscConfig.searchDistanceLeeway),
                    })
                  })
                  let mapW = map.getDiv().offsetWidth
                  let mapH = map.getDiv().offsetHeight
                  this.setState({ mapW, mapH })

                  let myZoom = getBoundsZoomLevel(256, 256, this.state.circleLeeway.getBounds(), { height: mapH, width: mapW })
                  this.setState({ zoom: myZoom })
                } else {
                  apiIsLoaded(map, maps, this.props.parentDisponibilities, this.state.center)
                }
              }}

              onChange={this.props.mapType === 'candidate' ? this._onChange : null}
              zoom={this.state.zoom}
              options={this.props.mapType === 'candidate' ? createMapOptions : pharmacyMapOptions}
            >
              {Markers}
              {this.props.mapType !== 'candidate' && center}
            </GoogleMapReact>
          )}

        </div>
      </div>
    )
  }
}