import React, { Component } from 'react'
import CandidateDataService from "../services/candidate.service"
import chatService from '../services/chat.service'
import OfferDataService from "../services/offer.service"
import { overlayStyle, informationStyle } from "../styles/mapPage.style"
import '../assets/css/meetChat.css'
import moment from 'moment'
import accountConfig from './config/account.config'
import miscConfig from './config/misc.config'
import Datetime from "react-datetime"
import "moment/locale/fr"
import miscUtils from '../utils/misc.utils'
import SliderToggle from "../components/forms/sliderToggle"
import { Link, withRouter } from 'react-router-dom'
import {
    Row,
    Col,
    Button,
    Input,
    Badge,
} from 'reactstrap'
import ShiftDisplay from './shift/shiftDisplay.component'
import ShiftCalendarDisplay from './shift/shiftCalendarDisplay.component'
import { ReactComponent as ListIcon } from './../assets/img/icons/list.svg';
import classnames from "classnames"

export default withRouter(class offersList extends Component {
    constructor(props) {
        super(props)
        this.retrieveCandidates = this.retrieveCandidates.bind(this)
        this.setClickedOffer = this.setClickedOffer.bind(this)
        this.toggleOfferList = this.toggleOfferList.bind(this)
        this.startEditing = this.startEditing.bind(this)
        this.remove = this.remove.bind(this)
        this.validateRemoval = this.validateRemoval.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.saveOffer = this.saveOffer.bind(this)
        this.checkFlexibility = this.checkFlexibility.bind(this)
        this.checkTwoWeeks = this.checkTwoWeeks.bind(this)
        this.shiftsUpdate = this.shiftsUpdate.bind(this)
        this.clickedElement = null
        this.mapCandidatesRef = null
        this.center = {
            lat: null,
            lng: null,
        }
        this.state = {
            currentIndex: null,
            myOffers: [],
            myPastOffers: [],
            matches: [],
            clickedMatch: false,
            offerUpdated: false,
            clickedOffer: false,
            clickedCreate: false,
            clickedRemove: false,
            offerListVisible: true,
            clickedEdit: false,
            idOffer: 0,
            twoWeeks: true,
            dateStart: "",
            dateEnd: "",
            contractType: "",
            salary: '',
            position: '',
            shifts: '',
            shiftsFlexibility: true,
            info: '',
            removalReason: 'validation',
            moTimes: [],
            tuTimes: [],
            weTimes: [],
            thTimes: [],
            frTimes: [],
            saTimes: [],
            suTimes: [],
            moTimes2: [],
            tuTimes2: [],
            weTimes2: [],
            thTimes2: [],
            frTimes2: [],
            saTimes2: [],
            suTimes2: [],
        }
    }

    async retrieveCandidates(id) {
        if (!id /*&& this.props.pharmacy.is_paid */) {
            return
        }
        let response = await CandidateDataService.getCandidateByOffer(id)
        var newCandidates = []
        if (response) {
            response.forEach(data => {
                newCandidates.push(data)
            });
            this.setState({ matches: newCandidates })
        }
        if (this.mapCandidatesRef) {
            this.mapCandidatesRef.refreshCandidates(newCandidates)
            if (this.mapCandidatesRef.mapRef) {
                this.mapCandidatesRef.mapRef.changeOffer()
            }
        }
        return newCandidates
    }
    async retrieveOffers() {
        var response = await OfferDataService.getOffersByPharmacy(this.props.match.params.pharmacyAccountId)
        var myOffers = []
        var myPastOffers = []
        var clickedOffer = response.offers[0]?.id
        response.offers.forEach(async offer => {
            if (offer.active) {
                myOffers.push(offer)
            } else {
                if (offer.close_reason === "validation") {
                    offer.validations.forEach(async validation => {
                        if (validation.validation_date) {
                            let candidate = await CandidateDataService.getCandidateInfo(validation.id_candidate)
                            offer.candidate = candidate
                        }
                    });
                }
                myPastOffers.push(offer)
            }
        })
        this.setState({ clickedOffer, myOffers, myPastOffers })
    }

    async setClickedOffer(e, id) {
        await this.retrieveOffer(id)
        this.setState({
            clickedOffer: id,
            clickedEdit: true,
            idOffer: id,
            offerUpdated: false,
            offerListVisible: false
        })
        this.clickedElement = e.target
        //this.retrieveCandidates(id)
        //this.retrieveOffer(id)
    }

    _onMouseEnter(e) {
        if (e.target !== this.clickedElement) {
            e.target.style.background = '#ADADAD'
        }
    }
    _onMouseLeave(e) {
        if (e.target !== this.clickedElement) {
            e.target.style.background = 'white'
        }
    }
    deactivateOverlay(force = false) {
        if (force || !this.state.offerUpdated)
            this.setState({ clickedMatch: null, clickedCreate: false, clickedEdit: false, clickedRemove: false })
    }
    selectCandidate(match) {
        this.setState({ clickedMatch: match })
    }
    onDemandContact() {
        chatService.initialize(this.state.clickedMatch.candidate.id)
        this.props.history.push("/chats#" + this.state.clickedMatch.candidate.id)
    }


    async refreshCandidates() {
        return await this.retrieveCandidates()
    }

    async retrieveOffer(offerId) {
        let response = await OfferDataService.getOneOffer(offerId)
        let dateStart = moment(response.dateStart).format('D/MM/YYYY')
        let dateEnd
        if (response.dateEnd) {
            dateEnd = moment(response.dateEnd).format('D/MM/YYYY')
        } else {
            dateEnd = null
        }
        if (response.shifts.substr(0, 336) === response.shifts.substr(336)) {
            this.setState({ twoWeeks: false })
        } else {
            this.setState({ twoWeeks: true })
        }
        if (response.shifts.substr(0, 336) === response.shifts.substr(336)) {
            this.setState({ twoWeeks: false })
        } else {
            this.setState({ twoWeeks: true })
        }

        var shiftsList = response.shifts.match(/.{1,48}/g)
        var nbShifts = 0
        shiftsList.forEach(day => {
            let nameList = accountConfig.dayEnum[nbShifts]
            nbShifts++
            var listShifts = []
            let found1 = false
            for (let i = 0; i < day.length; i++) {
                if (day[i] === "1" && found1 === false) {
                    found1 = true
                    listShifts.push(accountConfig.timeEnum[i])
                } else if (day[i] === "0" && found1 === true) {
                    found1 = false
                    listShifts.push(accountConfig.timeEnum[i - 1])
                } else if (i === 47 && found1 === true) {
                    listShifts.push(accountConfig.timeEnum[i])
                }
            }
            this.setState({ [nameList]: listShifts })
        })

        this.setState({ offerId: response.offerId, dateStart: dateStart, dateEnd: dateEnd, contractType: response.contractType, salary: response.salary, position: response.position, shifts: response.shifts, shiftsFlexibility: response.shiftsFlexibility, info: response.info })
    }

    async startEditing(idOffer) {
        await this.retrieveOffer(idOffer)
        this.setState({ clickedEdit: true })
        this.setState({ idOffer })
    }
    remove(idOffer) {
        this.setState({ clickedRemove: true })
        this.setState({ idOffer })
    }
    async validateRemoval() {
        await OfferDataService.archiveOffer(this.state.idOffer, this.state.removalReason)
        this.retrieveOffers()
        this.setState({ clickedRemove: false })

    }

    async startCreating() {
        this.setState({ position: Object.keys(accountConfig.positionList)[0], contractType: accountConfig.contractTypes[0], salary: Object.keys(accountConfig.salaryEnum)[0], dateStart: new Date(), checkFlexibility: true, shifts: "000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000" })
        this.setState({ currentIndex: "", clickedMatch: null, clickedOffer: null, idOffer: 0, clickedCreate: true })

        accountConfig.dayEnum.forEach(day => {
            // let nameList = accountConfig.dayEnum[nbShifts]
            this.setState({ [day]: [] })
        });
    }

    toggleOfferList() {
        this.setState({ offerListVisible: !this.state.offerListVisible })
    }

    shiftsUpdate(shifts, daySchedule, dayName, pos) {
        let newShifts = miscUtils.replaceAt(this.state.shifts, pos, shifts)
        this.setState({ [dayName]: daySchedule, shifts: newShifts })
        this.shiftCalendarRef.turnShiftsIntoDays(newShifts)
    }

    async saveOffer() {
        var body = {}
        body.shifts = this.state.shifts
        if (this.state.shiftsFlexibility) {
            body.shiftsFlexibility = 1
        } else {
            body.shiftsFlexibility = 0
        }
        body.dateStart = moment(this.state.dateStart, 'D/MM/YYYY')
        if (this.state.dateEnd) {
            body.dateEnd = moment(this.state.dateEnd, 'D/MM/YYYY')
        } else {
            body.dateEnd = "never"
        }
        body.contractType = this.state.contractType
        body.salary = this.state.salary
        body.position = this.state.position
        body.info = this.state.info
        if (this.state.twoWeeks) {
            body.shifts = this.state.shifts
        } else {
            let myShifts = this.state.shifts.substr(0, 336)
            body.shifts = myShifts + myShifts
        }
        if (this.state.idOffer === 0) {
            body.pharmacyId = this.props.match.params.pharmacyAccountId
            await OfferDataService.createOffer(body)
        } else {
            await OfferDataService.editOffer(this.state.idOffer, body)
        }
        this.setState({ clickedCreate: false })
        // window.location.reload()
        this.retrieveOffers()
        this.deactivateOverlay(true)
    }


    handleInputChange(e) {
        const target = e.target
        const value = target.value
        const name = target.name
        if (name === 'contractType' && value === 'CDI') {
            this.setState({ dateEnd: undefined })
        }
        this.setState({ [name]: value, offerUpdated: true })
    }
    handleDateChange(e, name) {
        const value = e._d
        this.setState({ [name]: value })
    }
    checkFlexibility() {
        this.setState({ shiftsFlexibility: !this.state.shiftsFlexibility })
    }
    async checkTwoWeeks() {
        await this.setState({ twoWeeks: !this.state.twoWeeks })
        this.shiftDisplayRef.updateTwoWeeks()
        this.shiftCalendarRef.checkTwoWeeks()
    }
    async resetDate(name) {
        // this.setState({[name]:0})
        await this.setState({ dateEnd: "never" })
    }

    async componentDidMount() {
        this.retrieveOffers()
    }
    render() {

        const { myOffers, myPastOffers,/* matches,*/ clickedCreate, clickedOffer, idOffer, offerListVisible, clickedEdit, dateStart, dateEnd, contractType, salary, position, shiftsFlexibility, shifts, info, clickedRemove, removalReason, twoWeeks, moTimes, tuTimes, weTimes, thTimes, frTimes, saTimes, suTimes, moTimes2, tuTimes2, weTimes2, thTimes2, frTimes2, saTimes2, suTimes2, } = this.state
        return (
            <div className="">
                {clickedRemove ?
                    <div>
                        <div className="overlay" id="overlay" style={overlayStyle} onClick={() => this.deactivateOverlay()} />
                        <div className="centered_overlay p-4 rounded">
                            <span className='font-weight-bold text-default'>Pouvez-vous nous indiquer la raison pour laquelle vous souhaitez archiver cette offre ?</span>
                            <Input type="select" className="profile-field mt-3" name="removalReason" value={removalReason} onChange={this.handleInputChange} >
                                {miscConfig.offerCloseReason && Object.keys(miscConfig.offerCloseReason).map((key, index) => {
                                    return <option key={index} value={key}>{miscConfig.offerCloseReason[key]}</option>
                                })}
                            </Input>
                            {removalReason === 'validation' ? <>
                                <div className="bg-primary text-white rounded p-2 mt-3">Pour valider une offre, rendez-vous sur votre <Link className="text-white font-weight-bold" to="/chats">conversation</Link> avec le candidat retenu.</div>
                            </> :
                                <Button className="mt-2" color='primary' onClick={this.validateRemoval}>Valider</Button>}
                        </div>
                    </div> : <></>}
                <Row>
                    <Col className={classnames(
                        "d-md-block col-12 col-md-4",
                        { "p-absolute": offerListVisible },
                        { "d-none ": !offerListVisible }
                    )}>
                        <Row className="mt-2 p-3">
                            <Col className="col-12 text-left">
                                <Button color='primary' href="javascript:history.back()">Retour à la liste des pharmacies</Button>
                            </Col>
                            <Col className="col-12 h2 text-left text-primary col-auto text-xl">
                                <span>Offres en cours</span>
                            </Col>
                            <ul className="list-group text-left w-100" style={{ listStyleType: 'none' }}>
                                {myOffers && myOffers.map((anOffer, index) =>
                                (
                                    <li key={anOffer.id} className={
                                        "cursor-pointer list-group-item " + (anOffer.id === clickedOffer ? "active" : "")} onClick={(e) => this.setClickedOffer(e, anOffer.id)} id={anOffer.id} >
                                        <Row>
                                            <Col className="col-auto pr-2 text-lg mb-2">
                                                <ListIcon fill={(anOffer.id === clickedOffer ? "white" : "#525f7f")} className="mr-2" style={{ height: "28px", width: "28px" }} />
                                                <span className="font-weight-bold">{accountConfig.positionList[anOffer.disponibility.position]}</span>
                                                {anOffer.disponibility.date_end ?
                                                    <span> du {moment(anOffer.disponibility.date_start).format("D/MM/YYYY")} au {moment(anOffer.disponibility.date_end).format("D/MM/YYYY")}</span>
                                                    : <span> à partir du {moment(anOffer.disponibility.date_start).format("D/MM/YYYY")}</span>
                                                }</Col>
                                            {/* <Col className="col-12">
                                                <Button outline color={anOffer.id === clickedOffer ? "white" : "primary"} id={"btn-edit-" + index}
                                                    onClick={() => {
                                                        this.startEditing(anOffer.id)
                                                    }}><i className="fa fa-edit fa-lg" /></Button>
                                                <UncontrolledTooltip delay={0}
                                                    placement="bottom"
                                                    target={"btn-edit-" + index}> Modifier mon offre </UncontrolledTooltip>
                                                <Button outline color={anOffer.id === clickedOffer ? "white" : "primary"} id={"btn-arch-" + index}
                                                    onClick={() => {
                                                        this.remove(anOffer.id)
                                                    }}><i className="fa fa-archive fa-lg" /></Button>
                                                <UncontrolledTooltip delay={0}
                                                    placement="bottom"
                                                    target={"btn-arch-" + index}> Archiver cette offre </UncontrolledTooltip>

                                            </Col> */}
                                        </Row>
                                    </li>
                                ))}
                            </ul>
                        </Row>
                        <Button color="primary" onClick={() => this.startCreating()}>Créer une nouvelle offre</Button>
                        {/*<Card color="lighter shadow-none text-left py-3 mt-4">
                            <CardBody className="p-0 text-sm">
                                <i className="fa fa-info-circle" /> Vous pouvez gérer ici vos offres d'emplois. Pensez à modifier votre offre <i className="fa fa-edit" /> si celle-ci évolue ou à l'archiver <i className="fa fa-archive" /> si elle n'est plus d'actualité.
                            </CardBody>
                        </Card> */}

                        {myPastOffers && myPastOffers.length > 0 && <>
                            <Col className="col-12 h2 text-left text-primary col-auto text-xl mt-4">
                                <span>Archives</span>
                            </Col>
                            <ul className="list-group text-left w-100" id="listOldOffers" style={{ listStyleType: 'none' }}>
                                {myPastOffers.map((aPastOffer, index) => {
                                    var candidateFullName = aPastOffer.validations[0]?.candidate?.name
                                    if (aPastOffer.validations[0]?.candidate?.last_name) {
                                        candidateFullName += ' ' + aPastOffer.validations[0]?.candidate?.last_name
                                    }
                                    return (
                                        <li
                                            className="list-group-item "
                                            key={aPastOffer.id}
                                            id={aPastOffer.id} >
                                            <Row className="text-gray">
                                                <Col className="col-auto pr-2 text-sm mb-2">
                                                    <ListIcon fill={(aPastOffer.id === clickedOffer ? "white" : "#525f7f")} className="mr-2" style={{ height: "28px", width: "28px" }} />
                                                    <span className="font-weight-bold">{accountConfig.positionList[aPastOffer.disponibility.position]}</span>
                                                    {aPastOffer.disponibility.date_end ?
                                                        <span> du {moment(aPastOffer.disponibility.date_start).format("D/MM/YYYY")} au {moment(aPastOffer.disponibility.date_end).format("D/MM/YYYY")}</span>
                                                        : <span> à partir du {moment(aPastOffer.disponibility.date_start).format("D/MM/YYYY")}</span>
                                                    } <br />
                                                    <span>
                                                        {aPastOffer.close_reason === "validation" ? "validée et attribuée à " + (aPastOffer.validations[0] ? candidateFullName : "Candidat non trouvé") : miscConfig.offerCloseReason[aPastOffer.close_reason]}
                                                    </span>
                                                </Col>
                                                {/* <Col className="col-col-aut text-right mt-1">
                                                <Button outline color="primary" id={"btn-edit-arch-" + index}
                                                    onClick={() => this.remove(aPastOffer.id)}><i className="fa fa-edit fa-lg" /></Button>
                                                <UncontrolledTooltip delay={0}
                                                    placement="bottom"
                                                    target={"btn-edit-arch-" + index}> Modifier la raison d'archivage </UncontrolledTooltip>
                                            </Col> */}
                                            </Row>
                                        </li>
                                    )
                                })}
                            </ul>
                        </>
                        }
                    </Col>
                </Row>

                {clickedCreate || clickedEdit ?
                    <div>
                        <div className="overlay" id="overlay" style={overlayStyle} onClick={() => this.deactivateOverlay()} />


                        <div className="creation overflow-auto text-left p-4" id="creation" style={informationStyle}>
                            <Row>
                                <Col className="text-right">
                                    <Button outline color="primary" onClick={() => this.deactivateOverlay(true)} className="mb--3 mr--3 bg-white" style={{ zIndex: "1080", border: "none" }}><i className="fas fa-times fa-lg" /></Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="h1 text-primary mb-4 mt--2" style={{ lineHeight: '1.1' }}>{idOffer !== 0 ? 'Modifier mon offre' : 'Créer une nouvelle offre'}</div>
                                </Col>
                            </Row>



                            <Row>
                                <Col className="font-weight-bold mb-1 col-12 mt-2">Poste recherché</Col>
                                <Col>
                                    <Input className="profile-field" type="select" name="position" value={position} onChange={this.handleInputChange}>
                                        {accountConfig.positionList && Object.keys(accountConfig.positionList).map((key, index) => {
                                            return <option key={index} value={key}>{accountConfig.positionList[key]}</option>
                                        })}
                                    </Input>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="font-weight-bold mb-1 col-12 mt-2">Type de contrat proposé</Col>
                                <Col>
                                    <Input className="profile-field" type="select" name="contractType" value={contractType} onChange={this.handleInputChange}>
                                        {accountConfig.contractTypes && accountConfig.contractTypes.map((contract, index) => {
                                            return <option key={index} value={contract}>{contract}</option>
                                        })}
                                    </Input>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="font-weight-bold mb-1 col-12 mt-2">Taux horaire envisagé</Col>
                                <Col>
                                    <Input className="profile-field" type="select" name="salary" value={salary} onChange={this.handleInputChange}>
                                        {accountConfig.salaryEnum && Object.keys(accountConfig.salaryEnum).map((key, index) => {
                                            return <option key={index} value={key}>{accountConfig.salaryEnum[key]}</option>
                                        })}
                                    </Input>
                                    <p className="text-right text-sm">
                                        <a href="https://www.lemoniteurdespharmacies.fr/emploi/grille-des-salaires/consulter-la-grille.html" target="_blank" rel="noreferrer">Aide grille salariale</a>
                                    </p>
                                </Col>
                            </Row>
                            <Row className="bg-lighter rounded text-left m-0 mt-5 py-3">
                                <Col className="col-12 mt--4"><Badge className=" bg-primary text-capitalize text-sm">Période du contrat</Badge></Col>
                                <Col>
                                    <Row className="pt-2">
                                        <Col className="col-auto pt-2 pr-1"><span className="font-weight-bold">Du</span></Col>
                                        <Col>
                                            <Datetime className="profile-field" locale="fr" timeFormat={false} isValidDate={(e) => miscUtils.checkDate(e)} name="dateStart" value={dateStart} onChange={(e) => this.handleDateChange(e, "dateStart")} />
                                        </Col>
                                        {contractType !== 'CDI' &&
                                            <Col className="col-auto pt-2 px-1"><span className="font-weight-bold">Au</span></Col>
                                        }
                                        {contractType !== 'CDI' &&
                                            <Col><Datetime
                                                className="profile-field rtl-calendar" locale="fr" timeFormat={false} isValidDate={(e) => miscUtils.checkDate(e)} name="dateEnd" value={dateEnd} onChange={(e) => this.handleDateChange(e, "dateEnd")} />
                                                <p className="text-right text-sm">
                                                    <span>Laisser vide si CDI</span>
                                                </p>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                            <div className="mt-2">
                                <SliderToggle
                                    label="Définir un planning différent pour les semaines paires et impaires"
                                    value={twoWeeks}
                                    handleChange={this.checkTwoWeeks}
                                />
                            </div>

                            <div className="h2 text-left text-primary">Planning prévu (obligatoire)</div>
                            <ShiftDisplay
                                ref={(instance) => { this.shiftDisplayRef = instance }}
                                parentTimes={{ moTimes, tuTimes, weTimes, thTimes, frTimes, saTimes, suTimes, moTimes2, tuTimes2, weTimes2, thTimes2, frTimes2, saTimes2, suTimes2 }}
                                parentShifts={shifts}
                                shiftsUpdate={this.shiftsUpdate}
                                twoWeeks={twoWeeks}
                            />

                            <div className="mt-2">
                                <SliderToggle
                                    label="Je suis flexible sur les horaires"
                                    value={shiftsFlexibility}
                                    handleChange={this.checkFlexibility}
                                />
                            </div>

                            <div className="h2 text-left text-primary">Récapitulatif de vos disponibilités</div>
                            <hr className="my-2" />
                            <ShiftCalendarDisplay
                                ref={(instance) => { this.shiftCalendarRef = instance }}
                                parentShifts={shifts}
                                twoWeeks={twoWeeks}
                            />


                            <div className="mt-2"><p className="font-weight-bold mb-1">Commentaire libre</p>
                                <Input type="textarea" name="info" rows="5" cols="" value={info} onChange={this.handleInputChange} />

                                <p className="pt-2" style={{ lineHeight: 1.1, maxWidth: '440px' }}><span className="font-italic text-sm"><i className="font-italic fas fa-info-circle" /> Précisez ici toutes informations supplémentaires que vous jugez utile de partager avec les candidats. Avantages, logement, ...</span>
                                </p>
                            </div>
                            <Row className="mt-3 mb-6"><Col>
                                {idOffer !== 0 ?
                                    <Button disabled={shifts.indexOf('1') === -1 || position === "null"} color="primary" onClick={this.saveOffer}>Sauvegarder les modifications</Button> :
                                    <Button disabled={shifts.indexOf('1') === -1 || position === "null"} color="primary" onClick={this.saveOffer}>Publier cette offre</Button>
                                }
                                {position === "null" && <span class="text-primary">Veuillez définir le poste recherché. </span>}
                                {shifts.indexOf('1') === -1 && <span class="text-primary">Veuillez définir le planning approximatif de l'offre.</span>}</Col></Row>

                        </div>
                    </div>
                    : <></>}
            </div>
        )
    }
})