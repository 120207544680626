import React, { Component } from "react"
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button, Card, Col, Container, Form, Input, Row } from "reactstrap"
import candidateService from "../../services/candidate.service"
import moment from 'moment';
import 'moment/locale/fr';
import { fromList, positionList, salaryEnum } from "../config/account.config";

const booleanFormatter = (cell) => <span>{cell ? <i className="fas fa-check fa-lg text-success" /> : <i className="fas fa-ban fa-lg text-primary" />}</span>
const dateTimeFormatter = (cell) => <span className="invoice-date">{moment(cell).format('llll')}</span>
const activationLinkFormatter = (cell, row) => {
    if (row.account?.account_connexion?.active) return <></>
    else return <a href={process.env.REACT_APP_APP_URL + '/activate/' + cell} rel="noreferrer" target="_blank">lien</a>
}

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Afficher{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                candidats.
            </label>
        </div>
    ),
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            &nbsp;Affichage des candidats {from} à {to} sur un total de {size} candidats.
        </span>
    )
});

const { SearchBar } = Search;


const ReactBSTables = ({ componentRef, alert, data, loading }) =>
    <>
        {alert}
        <Container className="mt--4" fluid>
            <Row>
                <div className="col">
                    <Card>
                        <ToolkitProvider
                            data={data}
                            keyField="id"
                            columns={[
                                {
                                    dataField: "id",
                                    text: "ID",
                                    style: {
                                        width: "1%",
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "account.account_connexion.active",
                                    text: "Actif",
                                    formatter: booleanFormatter,
                                    style: {
                                        width: "1%",
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "account.account_connexion.activation_link",
                                    text: "Lien d'activation",
                                    formatter: activationLinkFormatter,
                                    style: {
                                        width: "1%",
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "account.account_connexion.email",
                                    text: "Email",
                                    style: {
                                        width: "1%",
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "phone",
                                    text: "Tel",
                                    style: {
                                        width: "1%",
                                        minWidth: '100px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "creation_date",
                                    text: "Inscris le",
                                    formatter: dateTimeFormatter,
                                    style: {
                                        width: "1%",
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "name",
                                    text: "Prénom",
                                    style: {
                                        width: "1%",
                                        minWidth: '120px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "last_name",
                                    text: "Nom",
                                    style: {
                                        width: "1%",
                                        minWidth: '100px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "city",
                                    text: "Zone",
                                    style: {
                                        width: "1%",
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "radius",
                                    text: "Rayon",
                                    formatter: (cell) => cell + ' km',
                                    style: {
                                        width: "1%",
                                        minWidth: '120px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "auto_entrepreneur",
                                    text: "AE ?",
                                    formatter: booleanFormatter,
                                    style: {
                                        width: "1%",
                                        minWidth: '100px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "diploma",
                                    text: "Diplôme env ?",
                                    formatter: booleanFormatter,
                                    style: {
                                        width: "1%",
                                        minWidth: '160px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "diploma_validated",
                                    text: "Diplôme vérif ?",
                                    formatter: booleanFormatter,
                                    style: {
                                        width: "1%",
                                        minWidth: '160px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "cv",
                                    text: "CV env ?",
                                    formatter: booleanFormatter,
                                    style: {
                                        width: "1%",
                                        minWidth: '160px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "cv_validated",
                                    text: "CV vérif ?",
                                    formatter: booleanFormatter,
                                    style: {
                                        width: "1%",
                                        minWidth: '160px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "kbis",
                                    text: "K-bis env ?",
                                    formatter: booleanFormatter,
                                    style: {
                                        width: "1%",
                                        minWidth: '160px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "kbis_validated",
                                    text: "K-bis vérif ?",
                                    formatter: booleanFormatter,
                                    style: {
                                        width: "1%",
                                        minWidth: '160px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "meet_points",
                                    text: "Meetpoints",
                                    sort: true,
                                    style: {
                                        width: "1%",
                                        minWidth: '140px'
                                    },
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "sponsor_id",
                                    text: "Parrain",
                                    formatter: (cell, row) => cell ? row.sponsor?.name + " " + row.sponsor?.last_name + " (ID: " + cell + ")" : "aucun",
                                    style: {
                                        width: "1%",
                                        minWidth: '140px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "sponsor_rewarded",
                                    text: "récompensé ?",
                                    formatter: booleanFormatter,
                                    style: {
                                        width: "1%",
                                        minWidth: '160px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "disponibility.contract_type",
                                    text: "Type de contrat",
                                    style: {
                                        width: "1%",
                                        minWidth: '180px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "disponibility.salary",
                                    text: "Salaire",
                                    formatter: (cell) => cell ? salaryEnum[cell] : '?',
                                    style: {
                                        width: "1%",
                                        minWidth: '160px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "disponibility.position",
                                    text: "Poste",
                                    formatter: (cell) => cell ? positionList[cell] : '?',
                                    style: {
                                        width: "1%",
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "account.from",
                                    text: "Découverte",
                                    formatter: (cell) => cell ? fromList[cell] : '?',
                                    style: {
                                        width: "1%",
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                }
                            ]}
                            search
                            exportCSV
                            remote
                            loading={loading}
                        >
                            {(props) => (
                                <div className="py-1 table-responsive">
                                    <Container fluid>
                                        <Row>
                                            <Col xs={12} className="pb-2 text-left">
                                                <SearchBar
                                                    placeholder="Rechercher..." className="form-control" {...props.searchProps}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                    {loading &&
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        > <i className="fas fa-spinner fa-spin fa-3x text-primary"></i>
                                        </div>
                                    }
                                    {!loading && <BootstrapTable
                                        hover
                                        ref={componentRef}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        pagination={pagination}
                                        bordered={false}
                                        id="react-bs-table"
                                    />}
                                </div>
                            )}
                        </ToolkitProvider>
                    </Card>
                </div>
            </Row>
        </Container>
    </>




export default class candidats extends Component {
    constructor(props) {
        super(props)
        this.getCandidats = this.getCandidats.bind(this)
        this.deleteCandidate = this.deleteCandidate.bind(this)
        this.state = {
            candidats: [],
            page: 1,
            loading: true,
            sizePerPage: 25,
            idToDelete: null
        }
    }

    async getCandidats() {
        var candidats = await candidateService.getCandidateAll({ withDisponibility: true, withSponsor: true, withInactive: true })
        this.setState({ candidats, loading: false })
    }

    async deleteCandidate(id) {
        // display confirm popup
        if (!window.confirm("Êtes-vous sûr de vouloir supprimer ce candidat ?")) return
        else candidateService.deleteCandidate(id).then(() => {
            this.getCandidats()
        })
    }

    componentDidMount() {
        this.getCandidats()
    }

    render() {
        const { candidats, page, loading, idToDelete } = this.state
        return (
            <Row className="mt-2 p-0 p-lg-3 mx-auto">
                <Col className="col-12 px-0 px-lg-3">
                    <Row>
                        <Col className="h2 text-left text-primary col-auto text-xl">
                            <span>Candidats</span>
                        </Col>
                        <Col className="col-6 ml-auto text-right">
                            <Form className="d-inline-block row">
                            <label>Supprimer un candidat :
                            <Input style={{width: '150px' }}className="d-inline-block mx-2" placeholder="ID à supprimer" type="number" value={idToDelete ? idToDelete : ""}
                                onChange={e => this.setState({
                                    idToDelete: e.target.value
                                })}
                            /></label>
                            <Button type="submit" color="primary" onClick={() => this.deleteCandidate(idToDelete)}><i className="fas fa-trash"></i></Button>
                            </Form>
                        </Col>
                    </Row>
                    <ReactBSTables
                        data={candidats}
                        page={page}
                        loading={loading}
                    />
                </Col>
            </Row >
        )
    }
}