import http from '../http-common'
import accountHeader from './account-header'


class PharmacyService {
    
    checkPharmacy(idPharmacy) {
        return http.get(`/pharmacies/${idPharmacy}/check`)
        .then(result=>{
            return result.data
        })
    }
    getAllPharmacies() {
        return http.get(`/pharmacies`, { headers: accountHeader() })
        .then(result=>{
            return result.data
        })
    }
    getPharmacyByAccount = async (idAccount, refreshSubscription) => {
        var queryParams =""
        if(refreshSubscription){
            queryParams = "?refreshSubscription=1"
        }
        return http.get(`/pharmacies/byAccount/${idAccount}`+queryParams, { headers: accountHeader() })
        .then(result=>{
            return result.data
        })
    }

    getPharmacyInfo(idPharmacy, refreshSubscription) {
        var queryParams =""
        if(refreshSubscription){
            queryParams = "?refreshSubscription=1"
        }
        return http.get(`/pharmacies/${idPharmacy}`+queryParams, { headers: accountHeader() })
        .then(result=>{
            return result.data
        })
    }
    pharmacyEdit(body, idPharmacy) {
        return http.post(
            `/pharmacies/${idPharmacy}`,
            body,
            { headers: accountHeader() }
        )
    }
    pharmacyRegister(body) {
        return http.post(`/pharmacies`, body, {}
        ).then(response => {
            return response.data
        }).catch((err) => {
            return err
        })
    }
    deletePharmacy(id) {
      return http.delete(
        `pharmacies/${id}`,
        { headers: accountHeader() }
      )
    }
}

export default new PharmacyService()