import queryString from "query-string";
import React, { Component } from "react";
import stripeService from "../services/stripe.service";

export default class PricingTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sessionObj: {}
    }
  }

  async componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search);
    const session = await stripeService.checkAndCreateSubscription(queryParams.email);

    console.log('-------- this is session ', session);
    this.setState({ sessionObj: session });
  }


  render() {
    const queryParams = queryString.parse(this.props.location.search);
    const userEmail = queryParams.email || "";

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "90vh",
          alignItems: "center",
          overflow: 'auto',
          borderRadius: '12px'
        }}
      >
        <div
          style={{
            width: "41rem",
            height: "38rem",
            borderRadius: "18px",
            backgroundColor: "white",
            display: "flex-inline",
            justifyContent: "center",
            padding: '1rem 0',
            alignItems: "center",

          }}
        >
          {this.state.sessionObj.user_exist ?
            <stripe-pricing-table
              pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID_WITHOUT_FREE_TRIAL}
              publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
              customer-session-client-secret={this.state.sessionObj.id_session}
            ></stripe-pricing-table>
            :
            <stripe-pricing-table
              pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
              publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
              customer-email={userEmail}
            ></stripe-pricing-table>
          }
        </div>
      </div>
    );
  }
}
