import React, { Component } from "react"
import Chat from "./chat.component"
import '../assets/css/meetChat.css'
import classnames from "classnames"

import socketClient from "socket.io-client"
import { Button, Row, Col, Input, InputGroup, InputGroupAddon, InputGroupText, UncontrolledTooltip, CardBody, Card } from "reactstrap"
import accountConfig from "./config/account.config"
const SERVER = process.env.REACT_APP_SERVER


export default class ChatsList extends Component {
    constructor(props) {
        super(props)
        this.onChangeSearch = this.onChangeSearch.bind(this)
        this.setCurrentChat = this.setCurrentChat.bind(this)
        this.markChatRead = this.markChatRead.bind(this)
        this.toggleChatList = this.toggleChatList.bind(this)
        this.markChatAccepted = this.markChatAccepted.bind(this)
        this.markChatArchived = this.markChatArchived.bind(this)
        this.socket = null
        this.currentChatRef = null
        this.userName = this.props.user.role === "titulaire" ? this.props.pharmacy?.pharmacyName : this.props.candidate?.candidateName
        this.shifts = (this.props.user.role === "titulaire" ? this.props.pharmacy : this.props.candidate).shifts
        this.state = {
            search: "",
            currentChat: null,
            currentIndex: -1,
            chatList: [],
            chatListVisible: true,
            offersVisible: false,
            user: null
        }
    }

    //TO DO
    onChangeSearch(str) {
        this.setState({ search: str })
    }

    setCurrentChat = async (chat, index, offers = false) => {
        // var currentChat = chat
        await this.setState({
            offersVisible: offers,
            currentChat: chat,
            currentIndex: index,
            chatListVisible: false
        })
        if (this.currentChatRef) this.currentChatRef.retrieveMessages()
    }
    async markChatAccepted(chatId) {
        var element = this.state.chatList?.find(s => s.id === chatId)
        element.acceptation_date = new Date()
    }
    async markChatArchived(chatId) {
        var element = this.state.chatList?.find(s => s.id === chatId)
        element.archived = true
    }

    async markChatRead(chatId) {
        var element = this.state.chatList?.find(s => s.id === chatId)
        element.messages[element.messages.length - 1].read = true
        // this.state.chatList[chatId].read=true
    }

    archiveRequest(chat) {
        this.socket.emit('chat archive', ({ chatRoomId: chat.id, token: this.props.user.accessToken }))
    }

    loadUserData() {
        const userData = localStorage.getItem('user');
        if (userData) {
            this.setState({ user: JSON.parse(userData) });
        }
    }

    async componentDidMount() {
        // await this.setupUser()
        let { user } = this.props;

        if (!user) {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                user = JSON.parse(storedUser);
                this.props.setUser(user); // Assuming setUser updates the global state/context
            }
        }

        if (this.props.user) {
            var socket = socketClient(SERVER)
            //emit login
            socket.emit('login', this.props.user)

            socket.on('connect', () => {
                console.log('Connected to server in chatList');
                socket.emit('login', this.props.user);
            });
            //on 'logged'
            socket.on('logged', async (chatList) => {
                chatList?.sort(function (a, b) {
                    return new Date(b.messages[b.messages.length - 1].creation_date) - new Date(a.messages[a.messages.length - 1].creation_date)
                })
                await this.setState({ chatList: chatList })
                if (this.state.chatList?.length > 0) {
                    this.setCurrentChat(this.state.chatList[0], 0)
                }


                //select chat if #idReceiver
                if (window.location.hash.slice(1)) {
                    var idContact = window.location.hash.slice(1)
                    var reload = false
                    if (idContact.endsWith('reload')) {
                        reload = true
                        idContact = idContact.substring(0, idContact.length - 6);
                    }
                    var chat = {}
                    var chatIndex
                    if (this.props.user.role === "candidat") {
                        chat = this.state.chatList?.find(s => s.pharmacy_id === parseInt(idContact))
                        chatIndex = this.state.chatList?.findIndex(s => s.pharmacy_id === parseInt(idContact))
                    } else {
                        chat = this.state.chatList?.find(s => s.candidate_id === parseInt(idContact))
                        chatIndex = this.state.chatList?.findIndex(s => s.candidate_id === parseInt(idContact))
                    }

                    setTimeout(() => {
                        if (!chat) { if (!reload) { window.location.href += 'reload'; window.location.reload() } } else { this.setCurrentChat(chat, chatIndex) }
                    }, 1000)
                }
            })
            socket.on('chat received', ({ msg }) => {
                // Add new message to the list/ replace the oldest one
                var newChatList = this.state.chatList
                for (var i = 0; i < newChatList.length; i++) {

                    if (newChatList[i].id !== msg.chatRoomId) {
                        continue
                    }
                    let newMsg = { sender_name: msg.sender_name, text: msg.text, time: msg.time, textCandidate: msg.textCandidate, textPharmacy: msg.textPharmacy, buttonsCandidate: msg.buttonsCandidate, buttonsPharmacy: msg.buttonsPharmacy }
                    newChatList[i].messages.push(newMsg)
                    // newChatList[i].messages[0].sender_name = msg.sender_name

                    if (msg.sender_name !== this.state.myName) {
                        let nbMessages = newChatList[i].messages.length
                        newChatList[i].messages[nbMessages - 1].read = false
                        // newChatList[i].messages[0].text = msg.text
                    }
                    if (newChatList[i].id === msg.chatRoomId && i !== 0) {
                        let myChat = newChatList[i]
                        newChatList.splice(i, 1)
                        newChatList.unshift(myChat)
                    }
                    if (this.state.ChatList > 0 && msg.chatRoomId !== this.state.chatList[0].id) {
                        newChatList = newChatList.filter(item => item.id !== msg.chatRoomId)
                        newChatList.unshift(msg.chatRoomId)
                    }
                }
                this.setState({ chatList: newChatList })
            })
            this.socket = socket
            socket.on('confirm read', (info) => {
                var newChatList = this.state.chatList
                for (var i = 0; i < newChatList; i++) {
                    if (newChatList[i].id !== info.chatId) {
                        continue
                    }
                    let nbMessages = newChatList[i].messages.length
                    newChatList[i].messages[nbMessages - 1].read = true
                }
                this.setState({ chatList: newChatList })
            })
        }
    }

    loadUserData() {
        const userData = localStorage.getItem('user');
        if (userData) {
            this.setState({ user: JSON.parse(userData) });
        }
    }
    toggleChatList() {
        this.setState({ chatListVisible: !this.state.chatListVisible })
    }

    render() {
        const { chatList, currentChat, currentIndex, chatListVisible, offersVisible } = this.state

        return (
            <Row className="p-3">
                <Col className={classnames(
                    "d-md-block col-12 col-md-4",
                    { "p-absolute": chatListVisible },
                    { "d-none ": !chatListVisible }
                )}>
                    <Row className="mt-2">
                        <Col className="h2 text-left text-primary col-auto text-xl">
                            <span>Vos conversations</span>
                        </Col>
                    </Row>

                    <InputGroup className="search-input pb-3">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText className="border-default"><i className="fas fa-search text-primary" /></InputGroupText>
                        </InputGroupAddon>
                        <Input
                            type="text"
                            className="border-default text-secondary"
                            placeholder="Rechercher une conversation"
                            value={this.state.search}
                            onChange={e => this.onChangeSearch(e.target.value)}
                            style={{ borderLeft: 'none' }}
                        />
                    </InputGroup>

                    <ul className="list-group text-left" id="listChat">
                        {chatList &&
                            chatList.map((chat, index) => {
                                if (chat.archived) return null
                                if (!(chat.contact).toLowerCase().includes(this.state.search.toLowerCase())) return null
                                return <li className={
                                    "cursor-pointer list-group-item " + (currentChat && chat.id === currentChat.id ? "active" : "")}
                                    onClick={() => this.setCurrentChat(chat, index)}
                                    key={index}
                                ><Row><Col className="col-auto pr-6">
                                    {chat.messages[chat.messages.length - 1]?.read || chat.messages[chat.messages.length - 1]?.sender_name === this.userName ?
                                        <i className="far fa-comments fa-lg pr-2" />
                                        :
                                        <i className="fas fa-exclamation fa-lg text-primary" />
                                    }

                                    <b className="text-lg">{chat.contact} {chat.contactPosition ? (' (' + accountConfig.positionList[chat.contactPosition] + ')') : ''}</b><br />
                                    <div className="chat-preview" style={chat.messages[chat.messages.length - 1].read || this.userName === chat.messages[chat.messages.length - 1].sender_name ? {} : { fontWeight: "bold" }}>
                                        {chat.messages[chat.messages.length - 1].text.includes('<p>') && <span dangerouslySetInnerHTML={{
                                            __html: chat.messages[chat.messages.length - 1].text
                                                .replace('</p><p>', ' ')
                                                .replace('<p>', '#!?!#<p>')
                                                .split('#!?!#')[1]
                                                .replace('</p>', '</p>#!?!#')
                                                .split('#!?!#')[0]
                                        }} />}
                                    </div>
                                </Col>
                                        <Col className="col-12">
                                            {chat.acceptation_date && <><Button outline color={index === currentIndex ? "white" : "primary"} id={"btn-validate-" + index}
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    this.setCurrentChat(chat, index, true)
                                                }}><i className="fa fa-handshake fa-lg" /></Button>
                                                <UncontrolledTooltip delay={0}
                                                    placement="bottom"
                                                    target={"btn-validate-" + index}> Demander une confirmation de recrutement </UncontrolledTooltip></>}
                                            <Button outline color={index === currentIndex ? "white" : "primary"} id={"btn-arch-" + index}
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    this.archiveRequest(chat, index, true)
                                                }}><i className="fa fa-archive fa-lg" /></Button>
                                            <UncontrolledTooltip delay={0}
                                                placement="bottom"
                                                target={"btn-arch-" + index}> Archiver cette conversation </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </li>
                            })}
                    </ul>
                    <Card color="lighter shadow-none text-left py-3 mt-4">
                        <CardBody className="p-0 text-sm">
                            <i className="fa fa-info-circle" /> À la suite de vos échanges, pensez à demander une confirmation de recrutement <i className="fa fa-handshake" /> ou archivez votre conversation <i className="fa fa-archive" /> si vous ne souhaitez pas poursuivre vos échanges.
                        </CardBody>
                    </Card>

                    <Row className="mt-2">
                        <Col className="h2 text-left text-primary col-auto text-xl">
                            <span>Archives</span>
                        </Col>
                    </Row>
                    <ul className="list-group text-left" id="listChatArchived">
                        {chatList &&
                            chatList.map((chat, index) => {
                                if (!chat.archived) return null
                                if (!(chat.contact).toLowerCase().includes(this.state.search.toLowerCase())) return null
                                return <li className={
                                    "cursor-pointer list-group-item " + (currentChat && chat.id === currentChat.id ? "active" : "")}
                                    onClick={() => this.setCurrentChat(chat, index)}
                                    key={index}
                                ><Row><Col className="col-auto pr-6">
                                    {chat.messages[chat.messages.length - 1].read || chat.messages[chat.messages.length - 1].sender_name === this.userName ?
                                        <i className="far fa-comments fa-lg pr-2" />
                                        :
                                        <i className="fas fa-exclamation fa-lg text-primary" />
                                    }

                                    <b className="text-lg">{chat.contact} {chat.contactPosition ? (' (' + accountConfig.positionList[chat.contactPosition] + ')') : ''}</b><br />
                                    <div className="chat-preview" style={chat.messages[chat.messages.length - 1].read || this.userName === chat.messages[chat.messages.length - 1].sender_name ? {} : { fontWeight: "bold" }}>
                                        {chat.messages[chat.messages.length - 1].text.includes('<p>') && <span dangerouslySetInnerHTML={{
                                            __html: chat.messages[chat.messages.length - 1].text
                                                .replace('</p><p>', ' ')
                                                .replace('<p>', '#!?!#<p>')
                                                .split('#!?!#')[1]
                                                .replace('</p>', '</p>#!?!#')
                                                .split('#!?!#')[0]
                                        }} />}
                                    </div>
                                </Col>
                                    </Row>
                                </li>
                            })}
                    </ul>
                </Col>
                {!this.state.chatListVisible &&
                    <Col className={classnames(
                        "col-12 col-md-8 p-0",
                    )}>
                        {currentChat ? (
                            <Chat
                                ref={(instance) => { this.currentChatRef = instance }}
                                idChat={currentChat.id}
                                history={this.props.history}
                                socket={this.socket}
                                currentChat={currentChat}
                                currentUser={this.props.user}
                                currentUserName={this.userName}
                                toggleChatList={(e) => this.toggleChatList(e)}
                                chatListVisible={this.state.chatListVisible}
                                markChatRead={(chatId) => this.markChatRead(chatId)}
                                markChatAccepted={(chatId) => this.markChatAccepted(chatId)}
                                markChatArchived={(chatId) => this.markChatArchived(chatId)}
                                offersVisible={offersVisible}
                                shifts={this.shifts}
                            />
                        ) : (
                            <div>
                                <br />
                                <p>Veuillez sélectioner une discution...</p>
                            </div>
                        )}
                    </Col>
                }
            </Row>
        )
    }
}
