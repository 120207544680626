import React from "react";
// reactstrap components
import {
    FormGroup,
    Button,
    Input,
    Row,
    Col
} from "reactstrap";
import _uniqueId from 'lodash/uniqueId';
import miscConfig from "../config/misc.config";

function FacileFile({ label, name, thumbnail = false, thumbnailLink, className = "col col-2 border mt-2 ml-3", disabled, id, handleChange, handleReset = () => { }, type = "file", value = "" }) {
    if (thumbnailLink) {
        if (thumbnailLink.includes("data:application/pdf;base64,")) {
            fetch(thumbnailLink
            ).then(
                res => thumbnailLink = (res.blob())
            )
        } else {
            thumbnailLink = thumbnailLink
        }
    }
    if (!id) id = _uniqueId('input_')
    return (
        <Row name={label}>
            {thumbnail && thumbnailLink && <a className={className}
                style={{
                    backgroundImage: "linear-gradient(rgba(255,255,255,.1), rgba(255,255,255,.1)), url('" + value + "')",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    cursor: thumbnailLink ? "pointer" : "default"
                }}
                target="blank"
                href={thumbnailLink}><i className=" text-white position-absolute center pt-1 fa-3x fas fa-file-pdf" />
            </a>}
            <Col>
                <FormGroup className="mb-1">
                    {label && <label className="font-weight-bold mb-1" htmlFor={id}>{label}</label>}
                    {value ? <><br /><Button color="primary" type="button" id={id} onClick={() => handleReset(name)} style={{ padding: "0.15rem 0.4rem" }} target="_blank" rel="noreferrer">
                        <span>
                            <i className="fas fa-undo-alt" /> Réinitialiser le fichier
                        </span>
                    </Button></>
                        :
                        <Input
                            type={type}
                            id={id}
                            name={name}
                            className="btn"
                            style={{ background: "#f6f6f6" }}
                            onChange={handleChange}
                            disabled={disabled}
                        />
                    }
                </FormGroup>
            </Col>
        </Row>
    );
}

export default FacileFile;