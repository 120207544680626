import React, { Component, useEffect, useRef } from "react";
import { Row, Col, Button, Input, ModalBody, Modal } from "reactstrap";
import accountService from "../services/account.service";
import candidateService from "../services/candidate.service";
import cityService from "../services/city.service";
import pharmacyService from "../services/pharmacy.service";
import geocodingUtils from "../utils/geocoding.utils";
import miscUtils from "../utils/misc.utils";
import Map from "./map/map.component";

import Typewriter from 'typewriter-effect';
import ContactForm from "./forms/contactForm";
import { positionList } from "./config/account.config";


const welcomeQ = (answers = {}) => {
  return {
    id: 'welcome',
    q: <span>Bonjour,<br />Je suis Sarah, laissez-moi vous aider dans votre recherche. <b>Que recherchez-vous ?</b></span>,
    a: {
      type: "buttons",
      options: [
        {
          label: "J'ai une offre d'emploi à pourvoir",
          value: 'pharmacy',
          next: firstnameQ
        },
        {
          label: "Je suis à la recherche d'un emploi en officine",
          value: 'candidat',
          next: firstnameQ
        }
      ]
    }
  }
}

const firstnameQ = (answers = {}) => {
  return {
    id: 'firstName',
    q: (answers.welcome === 'pharmacy' ?
      <span>Parfait, nous avons hâte de vous présenter des candidats.<br />Puis-je vous demander <b>votre prénom ?</b></span>
      :
      <span>Parfait, nous avons hâte de vous présenter à nos pharmaciens titulaires.<br /><b>Comment dois-je vous appeler ?</b></span>
    ),
    a: (answers.welcome === 'pharmacy' ?
      {
        type: "firstName",
        label: "Votre prénom",
        next: pharmacyNameQ
      } : {
        type: "firstName",
        label: "Votre prénom",
        next: locationQ
      })
  }
}
const pharmacyNameQ = (answers = {}) => {
  return {
    id: 'pharmacyName',
    q: <span>Bonjour {answers.firstName}, je suis très heureuse de pouvoir vous accompagner dans votre recherche. Pouvez-vous m'indiquer le <b>nom de votre officine ?</b></span>,
    a:
    {
      type: "pharmacyName",
      label: "Nom de la pharmacie",
      next: locationQ
    }
  }
}

const locationQ = (answers = {}) => {
  return {
    id: 'location',
    q: (answers.welcome === 'pharmacy' ?
      <span>Parfait afin de vous présenter des candidats pour l'officine <i>{answers.pharmacyName}</i>, pouvez-vous m'indiquer <b>l'adresse de votre officine ?</b></span>
      :
      <span>Bonjour {answers.firstName}, je suis très heureuse de pouvoir vous accompagner dans votre recherche. Afin de vous présenter des offres qui vous correspondent, pouvez-vous m'indiquer <b>votre secteur de recherche ?</b></span>
    ),
    a: (answers.welcome === 'pharmacy' ?
      {
        type: "address",
        next: verificationQ
      }
      :
      {
        type: "zone",
        label: "Ville ou code postal",
        next: positionQ
      }
    )
  }
}

const positionQ = (answers = {}) => {
  return {
    id: 'position',
    q: <span>Quel poste recherchez type de poste recherchez vous ?</span>,
    a: {
      type: 'buttons',
      options: Object.keys(positionList).filter(v => v !== 'null').map((value) => {
        return {
          label: positionList[value],
          value,
          next: emailQ
        }
      })
    }

  }
}

const verificationQ = (answers = {}) => {
  return {
    id: 'verification',
    q: <span>Votre pharmacie se trouve-t-elle bien à cet emplacement ?</span>,
    a: {
      type: 'check',
      next: emailQ
    }
  }
}

const emailQ = (answers = {}) => {
  return {
    id: 'email',
    q: (answers.welcome === 'pharmacy' ?
      <span>Parfait {answers.firstName}, merci pour ces précisions.<br />J'ai de nombreux candidats disponibles sur votre secteur.<br /><br />Afin de vous mettre en relation, communiquez nous <b>une adresse email</b>, nous vous enverrons uniquement des propositions pertinentes en fonction de vos critères de recherche, c'est promis !</span>
      :
      <span>Parfait {answers.firstName}, merci pour ces précisions.<br />J'ai de nombreuses pharmacies dans cette zone.<br /><br />Afin de vous mettre en contact, communiquez nous <b>une adresse email</b>, nous vous enverrons uniquement des propositions pertinentes en fonction de vos critères de recherche, c'est promis !</span>
    ),
    a: {
      type: "email",
      label: "Votre adresse email",
      next: saveRedirect
    }
  }
}

const saveRedirect = (answers = {}) => {
  let body = {}
  body.email = answers.email
  body.city = answers.location.city
  body.latitude = answers.location.lat
  body.longitude = answers.location.lng
  if (answers.welcome === 'pharmacy') {
    body.address = answers.location.address
    body.zip = answers.location.zip
    body.pharmacyName = answers.pharmacyName
    if (answers.sponsor?.type === 'pharmacy') {
      body.sponsor = answers.sponsor.id
    }
    pharmacyService.pharmacyRegister(body).then(() => {
      //window.location.reload()
    })
  } else {
    body.position = 'aucun'
    body.contractType = 'CDD'
    body.firstName = answers.firstName
    body.radius = '50'
    body.position = answers.position
    if (answers.sponsor?.type === 'candidat') {
      body.sponsor = answers.sponsor.id
    }
    candidateService.candidateRegister(body)
  }

  return {
    id: 'from',
    q: <span>Merci {answers.firstName}, Nous vous avons envoyé un email à l'adresse {answers.email} afin que vous puissiez définir votre mot de passe et accéder à la plateforme. Permettez-moi une dernière question, comment avez-vous découvert Meetpharma ?</span>,
    a: {
      type: "buttons",
      options: [
        {
          label: "Réseaux sociaux (Linkedin / Instagram / Facebook)",
          value: 'rs',
          next: saveFrom
        },
        {
          label: "Recommandation d'un proche",
          value: 'proche',
          next: saveFrom
        },
        {
          label: "Recommandé par une officine",
          value: 'officine',
          next: saveFrom
        },
        {
          label: "Presse spécialisée",
          value: 'presse',
          next: saveFrom
        },
        {
          label: "Moteur de recherche",
          value: 'recherche',
          next: saveFrom
        },
        {
          label: "Autres",
          value: 'autre',
          next: saveFrom
        }
      ]
    }
  }
}


const saveFrom = (answers = {}) => {
  let body = {}
  body.email = answers.email
  body.from = answers.from

  accountService.saveFrom(body)

  return {
    id: 'redirect',
    q: <span>Super ! Votre inscription est terminée, rendez-vous vite sur votre boîte mail pour continuer. Merci de vérifier également dans vos <b>SPAMS</b>.</span>,
  }
}

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView({ behavior: 'smooth', block: "end" }));
  return <div ref={elementRef} />;
};

export default class Home extends Component {
  constructor(props) {
    super(props)

    this.inputText = React.createRef()
    this.user = accountService.getCurrentUser()
    this.onSearchCity = this.onSearchCity.bind(this)
    this.toggle = this.toggle.bind(this)
    this.toggleGame = this.toggleGame.bind(this)
    this.state = {
      openPopup: false,
      openGamePopup: false,
      currentUser: {},
      answers: {},
      listCountries: [],
      questions: [welcomeQ],
      lat: "",
      lng: "",
      city: "",
      tmpFirstname: "",
      tmpPharmacyName: "",
      tmpAddress: "",
      tmpZip: "",
      tmpCity: "",
      tmpEmail: "",
      tmpZone: "",
    };
  }

  answer = (id, value, next, position) => {
    if (!value || value === '') {
      return
    }
    var newState = this.state
    newState.answers[id] = value
    newState.questions[position] = next
    this.setState(newState)
  }

  async onSearchCity(e) {
    this.citiesBox = document.getElementById('citiesList')
    this.citiesBox.innerHTML = ''
    this.setState({ city: e.target.value })
    if (e.target.value.length < 3) {
      var item = document.createElement('li')
      item.className = 'list-group-item  p-2 text-sm'
      item.innerText = "Veuillez écrire au moins 3 charactères"
      item.disabled = true
      this.citiesBox.appendChild(item)
    }
    if (e.target.value.length >= 3) {
      this.setState({ listCountries: ["My countries"] })
      var countries = await cityService.findCities(e.target.value)
      if (countries && countries.length > 0) {
        countries.forEach(city => {
          var item = document.createElement('li')
          item.className = 'list-group-item list-group-item-action cursor-pointer p-2 text-sm'
          item.key = city.id
          item.innerText = city.name + ' - ' + city.zip
          item.data_val = city.latitude + '_' + city.longitude
          item.onclick = (e) => {
            this.setPosition(e)
          }
          this.citiesBox.appendChild(item)
        });
      }
    }
  }

  toggle() {
    this.setState({ openPopup: !this.state.openPopup })
  }
  toggleGame() {
    this.setState({ openGamePopup: !this.state.openGamePopup })
  }

  setPosition(e) {
    var coordinates = e.target.data_val.split('_')
    let tmpAnswers = this.state.answers
    tmpAnswers.location = {}
    tmpAnswers.location.city = e.target.innerHTML
    tmpAnswers.location.lat = coordinates[0]
    tmpAnswers.location.lng = coordinates[1]
    this.setState({
      city: e.target.innerText,
      lat: parseFloat(coordinates[0]),
      lng: parseFloat(coordinates[1]),
      answers: tmpAnswers
    })
  }

  removePosition() {
    let tmpAnswers = this.state.answers
    tmpAnswers.location = null
    this.setState({ city: "", lat: "", lng: "", answers: tmpAnswers })
  }

  removeLastAnswer = () => {
    var newState = this.state
    if (newState.answers.email) delete newState.answers.email
    else if (newState.answers.verification) delete newState.answers.verification
    else if (newState.answers.location) delete newState.answers.location
    else if (newState.answers.pharmacyName) delete newState.answers.pharmacyName
    else if (newState.answers.firstName) delete newState.answers.firstName
    else if (newState.answers.welcome) delete newState.answers.welcome

    newState.questions.splice(newState.questions.length - 1, 1)
    this.setState(newState)
  }


  // Can replace all that with one function instead of all of these
  changeTmpFirstname = (e) => this.setState({ tmpFirstname: e.target.value })
  changeTmpPharmacyName = (e) => this.setState({ tmpPharmacyName: e.target.value })
  changeTmpAddress = (e) => this.setState({ tmpAddress: e.target.value })
  changeTmpZip = (e) => this.setState({ tmpZip: e.target.value })
  changeTmpCity = (e) => this.setState({ tmpCity: e.target.value })
  changeTmpEmail = (e) => this.setState({ tmpEmail: e.target.value })
  changeTmpZone = (e) => this.setState({ tmpZone: e.target.value })

  async componentDidMount() {
    /*setTimeout(() => {
      this.setState({ openGamePopup: true })
    }, 1500)*/
    const queryParams = new URLSearchParams(window.location.search)
    var phsp, casp
    try {
      phsp = queryParams.get("phsp")
      if (phsp) pharmacyService.checkPharmacy(atob(phsp)).then(res => {
        var sponsor = { type: 'pharmacy', name: res.pharmacyName, id: atob(phsp) }
        this.setState({ sponsor, openPopup: true })
        this.answer('sponsor', sponsor, welcomeQ, 0)
        this.answer('welcome', 'pharmacy', firstnameQ, 1)
      }).catch(e => { })
    } catch (e) {

    }
    try {
      casp = queryParams.get("casp")
      if (casp) candidateService.checkCandidate(atob(casp)).then(res => {
        var sponsor = { type: 'candidat', name: res.candidateName, id: atob(casp) }
        this.setState({ sponsor, openPopup: true })
        this.answer('sponsor', sponsor, welcomeQ, 0)
        this.answer('welcome', 'candidat', firstnameQ, 1)
      }).catch(e => { })
    } catch (e) {

    }
    if (this.user) {
      this.setState({ currentUser: accountService.getCurrentUser() })
    }
  }

  render() {
    return (
      <>
        {this.user ? <>
          {/* {this.state.currentUser.role === "admin" ? <FileValidation /> : <Stats />} */}
        </> : <>
          <Modal
            isOpen={this.state.openGamePopup}
            centered
            size="xl"
            scrollable
            backdrop={true}
            backdropClassName="opacity-7"
            toggle={this.toggleGame}
          >
            <ModalBody
              style={{
                backgroundImage: "url('" + require("../assets/img/background-homepage.jpg").default + "')",
                backgroundSize: "cover"
              }}
              className="text-center text-white"
            >

              <img
                alt="Meetpharma Logo"
                className="w-100 rounded"
                src={require("../assets/img/popup.jpg").default} onClick={this.toggleGame}/>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={this.state.openPopup}
            centered
            size="lg"
            scrollable
            backdrop="static"
            backdropClassName="opacity-7"
            toggle={this.toggle}
          >
            <ModalBody
              style={{
                backgroundImage: "url('" + require("../assets/img/background-homepage.jpg").default + "')",
                backgroundSize: "cover"
              }}
              className="text-center text-white"
            >

              <img
                alt="Meetpharma Logo"
                className="navbar-brand-img-big"
                src={require("../assets/img/logo-meetpharma-white.png").default} />

              <h3 className="h3 text-white">Bienvenue sur Meetpharma.fr ! </h3>

              <div className="text-lg text-white mt-4 mx-auto" style={{ lineHeight: '1.1', maxWidth: "500px" }}>{this.state.sponsor?.name} vous invite à découvrir notre application en vous faisant profiter d'un avantage de parrainage exclusif : </div>

              <h1 className="h1 text-white mt-4" style={{ lineHeight: '1.1' }}>{this.state.sponsor?.type === 'pharmacy' ? <span>1 mois gratuit pour vous et votre parrain <br />({this.state.sponsor?.name})</span> : "Commencez dès maintenant avec 50 MeetPoints offerts par " + this.state.sponsor?.name}</h1>

              <p className="mt-4" >
                <Button outline className="bg-white text-primary border-primary" onClick={this.toggle}>Inscrivez-vous maintenant</Button>
              </p>
            </ModalBody>
          </Modal>
          <Row className="mt-5">
            <Col className="text-center">
              <h1 className="text-white text-center display-2" style={{ lineHeight: 1.2 }}>Bienvenue sur Meetpharma</h1>
              <h1 className="text-white text-center d-none d-sm-block font-weight-normal" style={{ lineHeight: 1.2 }}>Le site de mise en relation entre pharmaciens et candidats</h1>
              <h1 className="text-white text-center d-block d-sm-none font-weight-normal h3 mx-2" style={{ lineHeight: 1.2 }}>Le site de mise en relation entre pharmaciens et candidats</h1>
              <h1 className="text-white text-center font-weight-normal h4 mx-2" style={{ lineHeight: 1.2, height: '45px' }}>
                <Typewriter
                  options={{
                    strings: [
                      'Pharmacien titulaire, je trouve un collaborateur quand je veux et en fonction de mes besoins.',
                      'Préparateur, je trouve un employeur en un clic.',
                      'Pharmacien adjoint, je trouve un employeur pour quelques heures ou plus.',
                      'Étudiant, je trouve un job ou un stage en fonction de mon temps libre.',
                    ],
                    autoStart: true,
                    delay: 35,
                    deleteSpeed: 10,
                    loop: true,
                  }}
                /*onInit={(typewriter) => {
                  typewriter.typeString('Pharmacien titulaire')
                    .pauseFor(500)
                    .typeString(', je trouve un collaborateur quand je veux et en fonction de mes besoins.')
                    .deleteAll()
                    .start();
                }}*/
                /></h1>
            </Col>
          </Row>
          <Row className="index-chat-bot-container justify-content-center mt-2 pb-5">
            <img src={require("../assets/img/top-background-chat.png").default} className="position-absolute" style={{ width: '80%' }} alt="" />
            <Col className="index-chat-bot col-12 col-lg-8 col-xl-6 position-relative justify-content-center pt-6 " style={{ height: '450px', overflowY: 'scroll', paddingRight: '5vw', paddingLeft: '5vw', marginTop: '1px' }}>

              {/* 
              <div className="h1 text-white">Votre plateforme arrive très prochainement !</div>
              <span className="h2 d-inline-block text-primary bg-white mb-4 px-4 py-1 rounded">Préinscrivez-vous dès maintenant et commencez avec 50 MeetPoints</span>
              <div className="h1">&nbsp;</div> */}
              {this.state.questions.map((question, idx) => {
                var item = question(this.state.answers)
                var answers
                if (item.a) {
                  switch (item.a.type) {
                    case 'buttons': {
                      answers = (this.state.answers[item.id] ?
                        <Row className="d-flex justify-content-end">
                          {
                            item.a.options.map((option, jdx) => {
                              if (this.state.answers[item.id] === option.value) {
                                return <Col key={jdx} className="col-auto font-weight-bold bg-primary shadow text-white rounded p-3"><span>{option.label}</span></Col>
                              } else {
                                return null
                              }
                            })
                          }
                        </Row>
                        :
                        <Row className="d-flex justify-content-end" >
                          {
                            item.a.options.map((option, jdx) =>
                              <Col key={jdx} className="col-12 col-sm-6 text-right">
                                <Button className="chat-button" onClick={(e) => {
                                  e.preventDefault()
                                  this.answer(item.id, option.value, option.next, idx + 1)
                                }}>{option.label}</Button>
                              </Col>)
                          }
                        </Row>
                      )
                      break;
                    }
                    case 'pharmacyName': {
                      answers = (this.state.answers.pharmacyName ? <Row
                        className="d-flex justify-content-end">
                        <Col className='col-auto font-weight-bold bg-primary shadow text-white rounded p-3'><span>{this.state.answers.pharmacyName}</span></Col>
                      </Row> :
                        <Row className="d-flex justify-content-end">
                          <Col className="col-auto">
                            <Input className="text-dark" placeholder={item.a.label} value={this.state.tmpPharmacyName} onChange={this.changeTmpPharmacyName} onKeyUp={(e) => { if (e.keyCode === 13) { this.answer(item.id, this.state.tmpPharmacyName, item.a.next, idx + 1) } }} />
                          </Col>
                          <Col className="col-auto">
                            <Button className="chat-button" disabled={!this.state.tmpPharmacyName} onClick={(e) => {
                              e.preventDefault()
                              this.answer(item.id, this.state.tmpPharmacyName, item.a.next, idx + 1)
                            }}>Valider</Button>
                          </Col>
                        </Row>)
                      break;
                    }
                    case 'firstName': {
                      answers = (this.state.answers.firstName ? <Row className="d-flex justify-content-end">
                        <Col className="col-auto font-weight-bold bg-primary shadow text-white rounded p-3"><span>{this.state.answers.firstName}</span></Col>
                      </Row> :
                        <Row className="d-flex justify-content-end">
                          <Col className="col-auto">
                            <Input className="text-dark" placeholder={item.a.label} value={this.state.tmpFirstname} onChange={this.changeTmpFirstname} onKeyUp={(e) => { if (e.keyCode === 13) { this.answer(item.id, this.state.tmpFirstname, item.a.next, idx + 1) } }} />
                          </Col>
                          <Col className="col-auto">
                            <Button className="chat-button" disabled={!this.state.tmpFirstname} onClick={(e) => {
                              e.preventDefault()
                              this.answer(item.id, this.state.tmpFirstname, item.a.next, idx + 1)
                            }}>Valider</Button>
                          </Col>
                        </Row>)
                      break;
                    }
                    case 'email': {
                      answers = (this.state.answers.email ?
                        <Row className="d-flex justify-content-end">
                          <Col className="col-auto font-weight-bold bg-primary shadow text-white rounded p-3">
                            <span className="font-italic">{this.state.answers.email}</span>
                          </Col>
                        </Row>
                        :
                        <Row className="d-flex justify-content-end">
                          <Col className="col-auto">
                            <Input placeholder={item.a.label} defaultValue={this.state.answers.email} value={this.state.tmpEmail} onChange={this.changeTmpEmail} onKeyUp={(e) => { if (e.keyCode === 13) { this.answer(item.id, this.state.tmpEmail, item.a.next, idx + 1) } }} />
                          </Col>
                          <Col className="col-auto">
                            <Button className="chat-button" disabled={!miscUtils.emailIsCorrect(this.state.tmpEmail)} onClick={(e) => {
                              e.preventDefault()
                              this.answer(item.id, this.state.tmpEmail, item.a.next, idx + 1)
                            }}>Valider</Button>
                          </Col>
                          {this.state.tmpEmail && !miscUtils.emailIsCorrect(this.state.tmpEmail) && <Col className="col-12 text-white text-right text-sm font-italic">
                            Veuillez saisir une adresse email valide.
                          </Col>
                          }
                        </Row>
                      )
                      break;
                    }
                    case 'address': {
                      answers = (this.state.answers.location ?
                        <Row className="d-flex justify-content-end">
                          <Col className="col-auto font-weight-bold bg-primary shadow text-white rounded p-3">
                            <span className="font-italic">{this.state.answers.location.address} {this.state.answers.location.zip} {this.state.answers.location.city} </span>
                          </Col>
                        </Row>
                        :
                        <Row className="d-flex justify-content-end">

                          <Col className="col-auto">
                            <Row className="d-flex justify-content-end">
                              <Col className="col-auto">
                                <Input placeholder="Rue" defaultValue={this.state.answers.location && this.state.answers.location.address} value={this.state.tmpAddress} onChange={this.changeTmpAddress} onKeyUp={(e) => {
                                  if (e.keyCode === 13) {
                                    e.keyCode = 9;
                                  }
                                }} />
                              </Col>
                              <Col className="col-auto">
                                <Input placeholder="Code postal" defaultValue={this.state.answers.location && this.state.answers.location.zip} value={this.state.tmpZip} onChange={this.changeTmpZip} onKeyUp={(e) => {
                                  if (e.keyCode === 13) {
                                    e.keyCode = 9;
                                  }
                                }} />
                              </Col>
                              <Col className="col-auto">
                                <Input placeholder="Ville" defaultValue={this.state.answers.location && this.state.answers.location.city} value={this.state.tmpCity} onChange={this.changeTmpCity} onKeyUp={async (e) => {
                                  if (e.keyCode === 13) {
                                    var geocoding = await geocodingUtils.coordinatesFromAddress(this.state.tmpAddress, this.state.tmpCity, this.state.tmpZip)
                                    this.setState({ tmpLat: geocoding.lat, tmpLng: geocoding.lng })
                                    this.answer(item.id, { address: this.state.tmpAddress, zip: this.state.tmpZip, city: this.state.tmpCity, lat: geocoding.lat.toString(), lng: geocoding.lng.toString() }, item.a.next, idx + 1)
                                  }
                                }} />
                              </Col>
                            </Row>
                          </Col>
                          <Col className="col-auto">
                            <Button className="chat-button" disabled={!this.state.tmpAddress || !this.state.tmpZip || !this.state.tmpCity} onClick={async (e) => {
                              e.preventDefault()
                              var geocoding = await geocodingUtils.coordinatesFromAddress(this.state.tmpAddress, this.state.tmpCity, this.state.tmpZip)
                              this.setState({ tmpLat: geocoding.lat, tmpLng: geocoding.lng })
                              this.answer(item.id, { address: this.state.tmpAddress, zip: this.state.tmpZip, city: this.state.tmpCity, lat: geocoding.lat.toString(), lng: geocoding.lng.toString() }, item.a.next, idx + 1)

                            }}>Valider</Button>
                          </Col>
                        </Row>)
                      break;
                    }
                    case 'zone': {
                      answers = (this.state.answers.location ?
                        <Row className="d-flex justify-content-end">
                          <Col className="col-auto font-weight-bold bg-primary shadow text-white rounded p-3">
                            <span className="font-italic">{this.state.answers.location.city} </span>
                          </Col>
                          {this.state.questions.length === 3 &&
                            <Col className="col-auto">
                              <Button className="chat-button" onClick={(e) => {
                                e.preventDefault()
                                this.answer(item.id, { city: this.state.city, lat: this.state.lat, lng: this.state.lng }, item.a.next, idx + 1)
                              }}>Valider</Button>
                              <Button className="chat-button" onClick={(e) => {
                                e.preventDefault()
                                this.removePosition()
                              }}>Annuler</Button>
                            </Col>
                          }
                        </Row>
                        :
                        <Row className="d-flex justify-content-end">
                          <Col>
                            <ul id="citiesList" className={'list-group list-group-flush bg-white shadow rounded position-absolute text-left ' + (this.state.city ? 'd-block' : 'd-none')} style={{ position: 'absolute', backgroundColor: 'white', bottom: '45px', right: '15px', left: '15px', borderRadius: '12px !important', zIndex: "1080" }} />
                            <Input placeholder={item.a.label} defaultValue={this.state.answers.location && this.state.answers.location.city} value={this.state.city} onChange={this.onSearchCity} />
                          </Col>
                        </Row>
                      )
                      break;
                    }
                    case 'check': {
                      answers = (this.state.answers.verification ?
                        <Row className="d-flex justify-content-end">
                          <Col className="col-auto font-weight-bold bg-primary shadow text-white rounded p-3">
                            <span className="font-italic"><i className="far fa-thumbs-up" /> </span>
                          </Col>
                        </Row> :
                        <> <Row>
                          <Col>
                            <Map
                              centerFromParent={{ lat: this.state.tmpLat, lng: this.state.tmpLng }}
                              // centerFromParent={{lat:46.648498, lng:0.36368}}
                              zoomFromParent={18}
                              radiusFromParent={null}
                              gestureHandling='none'
                              mapType={'positionTest'}
                              parentDisponibilities={null}
                              setActiveMarker={null}
                              activeMarker={null}
                              setClickedMarker={null}
                              canUpdateMap={null}
                            />
                          </Col>
                        </Row>
                          <Row className="m-2">
                            <Col>
                              <Button className="chat-button" onClick={(e) => {
                                e.preventDefault()
                                this.answer(item.id, true, item.a.next, idx + 1)
                              }} >Oui</Button>
                              <Button className="chat-button" onClick={(e) => {
                                e.preventDefault()
                                this.removeLastAnswer()
                              }}>Non</Button>
                            </Col>
                          </Row>
                        </>
                      )
                      break
                    }
                    default: {
                      <></>
                      break
                    }
                  }
                }
                return <div key={idx}>
                  <Row className="index-chat-bot-row">
                    <Col className="col-auto pr-2"><img className="rounded-circle" src={require("../assets/img/pp.jpg").default} style={{ width: "64px", height: "64px" }} alt="votre interlocuteur" /></Col>
                    <Col className="p-0 m-0"><div className="bg-white border rounded text-left align-middle mr-1">{item.q}</div></Col>
                  </Row>
                  {answers &&
                    <Row className="index-chat-bot-row my-0">
                      <Col className="bg-transparent align-middle my-3">{answers}</Col>
                    </Row>}
                </div>
              }
              )}
              <AlwaysScrollToBottom />
            </Col>
            <Col className="col-12 col-lg-7 text-left p-2">{this.state.answers.welcome && !this.state.answers.email &&
              <span className="fa-stack" onClick={(e) => {
                e.preventDefault();
                this.removeLastAnswer()
              }}>
                <i className="fas fa-circle fa-stack-2x text-white"></i>
                <i className="fas fa-angle-left fa-stack-1x text-primary"></i>
              </span>}
            </Col>

            <Col className="col-12 bg-white pr-0 mr-0" >
              <Row className="mx-auto">
                <Col className="col-12 col-lg-6 align-self-center flex-column text-left p-2 p-lg-5 order-lg-1 order-2 ">
                  <div className="d-inline-block" style={{ float: 'right', maxWidth: '550px' }}>
                    <h3 className="display-3 text-primary" style={{ lineHeight: 1.2 }}>Trouvez un candidat / pharmacie dans votre secteur</h3>
                    <p className="mt-4 text-lg font-weight-bolder" style={{ lineHeight: 1.3 }}>Un moteur de recherche et des préférences pensées pour les pharmacies et leurs futurs employés</p>
                    <p className="mt-4" style={{ lineHeight: 1.3 }}>Meetpharma géolocalise et filtres les résultats en fonction de vos critères de recherches (type de poste, taux horaire, disponibilités, type de contrat, lgo, distance, ...) </p>
                  </div>
                </Col>
                <Col className="col-12 col-lg-6  order-lg-2 order-1 mr-0 pr-0" >
                  <img className="" src={require("../assets/img/meetpharma-homepage-picture2.jpg").default} alt="" style={{ float: 'right', width: "auto", maxHeight: '800px' }} />
                </Col>
              </Row>
            </Col>

            <Col className="col-12" style={{ backgroundColor: '#f3f3f3' }}>
              <Row className="mx-auto" style={{ maxWidth: '1600px' }}>
                <Col className="col-12 col-lg-8 p-2 p-lg-5 ml-0 ml-lg--8" >
                  <img src={require("../assets/img/meetpharma-homepage-picture1.jpg").default} alt="" style={{ maxWidth: "100%" }} />
                </Col>
                <Col className="col-12 col-lg-4 align-self-center d-flex flex-column text-left p-2 p-lg-5  ml-0 ml-lg--5">
                  <div style={{ float: 'left', maxWidth: '550px' }} >
                    <h3 className="display-3 text-primary" style={{ lineHeight: 1.2 }}>Engagez la discussion et signez votre contrat !</h3>
                    <p className="mt-4 text-lg font-weight-bolder" style={{ lineHeight: 1.3 }}>Meetpharma intègre une messagerie sécurisée pour vos échanges professionnels</p>
                    <p className="mt-4" style={{ lineHeight: 1.3 }}>Un outil centralisé pour formaliser tous vos recrutements directement depuis votre onglet discussion.</p>
                  </div>
                </Col>
              </Row>
            </Col>

            {/* <Col className="col-12 bg-white" >
              <Row className="mx-auto" style={{ maxWidth: '1200px' }}>

                <Col className="col-12 col-lg-6 align-self-center d-flex flex-column text-left p-2 p-lg-5 order-lg-1 order-2">
                  <h3 className="display-3 text-primary" style={{ lineHeight: 1.2 }}>Des avantages exclusifs pour les candidats de la plateforme</h3>
                  <p className="mt-4 text-lg font-weight-bolder" style={{ lineHeight: 1.3 }}>Chaque mission génère des MeetPoints que vous pouvez ensuite convertir en euros. Du bonus !</p>
                  <p className="mt-4" style={{ lineHeight: 1.3 }}>Chaque semaine, les candidats recoivent 1 MeetPoint par demi heure travaillée dans le cadre d'une mise en relation validée sur Meetpharma.</p>
                </Col>
                <Col className="col-12 col-lg-6 p-2 p-lg-5 order-lg-2 order-1" >
                  <img className="rounded" src={require("../assets/img/meetpharma-homepage-picture3.jpg").default} alt="" style={{ maxWidth: "100%" }} />
                </Col>
              </Row>
            </Col> */}

            <Col className="col-12" style={{ backgroundColor: '#f3f3f3' }}>
              <Row className="mx-auto" style={{ maxWidth: '800px' }}>
                <Col className="col-12 pt-4 pb-5" >
                  <ContactForm></ContactForm>
                </Col>
              </Row>
            </Col>

          </Row></>}
      </>
    );
  }
}