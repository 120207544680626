import http from "../http-common";
import { getOperatingSystem, getWebBrowser } from "../utils/os";

class PushNotificationSender {
  async sendPushNotification(subscription, notificationData) {
    if (!subscription) {
      console.error("Subscription not available");
      return;
    }

    try {
      const payload = JSON.stringify({ subscription, notificationData });
      const response = await http.post("/send-notification", payload);

      if (response.status !== 200) {
        console.error("Failed to send push notification", response.status);
      }
    } catch (error) {
      console.error(`Error sending push notification: ${error.message}`);
    }
  }

  async savePushSubscription(subscription) {
    if (!subscription || typeof subscription !== 'object') {
      console.error("Invalid or missing subscription object.");
      return;
    }

    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.id) {
      console.error("User information is not available.");
      return;
    }

    const payload = {
      subscription,
      user_id: user.id,
      os: getOperatingSystem(),
      browser: getWebBrowser(),
      email: user.email
    };

    try {
      const response = await http.post("/save-subscription", payload);

      if (response.status === 200) {
        console.log("Subscription created successfully.");
      } else if (response.status === 201) {
        console.log("Subscription updated successfully.");
      }
    } catch (error) {
      console.error(`Error saving subscription: ${error.message}`);
    }
  }
}

export default new PushNotificationSender();