import { Component } from 'react'
import ShiftCalendarDisplay from './shiftCalendarDisplay.component'

export default class ShiftComparison extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shiftsC: "", // Shifts candidate
            shiftsP: "", // Shifts Pharmacy
            shiftsCompared: "",
        }
    }
    async compareShifts() {
        const { shiftsC, shiftsP } = this.state
        var shiftsCompared = ""
        for (let i = 0; i < this.state.shiftsC.length; i++) {
            if (shiftsC[i] === "1") {
                if (shiftsP[i] === "1") {
                    shiftsCompared = shiftsCompared + "1" // candidate can work at this time and the offer requires it
                } else {
                    shiftsCompared = shiftsCompared + "2" // Candidate can work at this time but it is not required by the offer
                }
            } else {
                if (shiftsP[i] === "1") {
                    shiftsCompared = shiftsCompared + "3" // Candidate can not work at tjos time BUT the offer requires it
                } else {
                    shiftsCompared = shiftsCompared + "0" // nether the candidate nor the offer require to work at this time
                }
            }
            await this.setState({ shiftsCompared })
        }
    }

    async componentDidMount() {
        await this.setState({ shiftsC: this.props.candidateShifts })
        await this.setState({ shiftsP: this.props.pharmacyShifts })
        await this.compareShifts()
    }

    render() {
        const { shiftsCompared } = this.state
        return (
            <>
                {shiftsCompared.length === 672 && // checks that all the shifts have been loaded
                    <ShiftCalendarDisplay
                        parentShifts={shiftsCompared}
                        isComparison={true}
                        />
                }
            </>
        )
    }
}