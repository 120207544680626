import moment from "moment"
import React, { Component } from "react"
import { Button, Card, Col, FormGroup, Input, Label, Row, Table } from "reactstrap"
import meetCodeService from "../../services/meetCode.service"

export default class meetCodes extends Component {
    constructor(props) {
        super(props)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.save = this.save.bind(this)
        this.newCode = this.newCode.bind(this)
        this.state = {
            meetcodes: [],
            currentCode: null,
            message: "",
            error: "",
            loading: false,
            addCode: ""
        }
    }

    async getAllRequests() {
        var meetcodes = (await meetCodeService.getAll()).data
        await this.setState({ meetcodes })
    }

    handleInputChange(e) {
        const target = e.target
        const value = target.value
        const name = target.name
        const checked = target.checked
        this.setState({ currentCode: { ...this.state.currentCode, [name]: target.type === "checkbox" ? checked : value } })
    }

    save(e) {
        this.setState({ loading: true })
        meetCodeService.save({
            code: this.state.currentCode.code,
            points: this.state.currentCode.points,
            active: this.state.currentCode.active,
            limit_date: this.state.currentCode.limit_date,
            limit_use: this.state.currentCode.limit_use
        })
            .then((response) => {
                this.setState({
                    message: "Vos modifications ont été enregistrées.",
                    loading: false
                })
                window.location.reload()
            })
            .catch(error => {
                if (error?.response?.data?.code === 'UNAUTHORIZED') {
                    return this.setState({
                        error: "Vous n'êtes pas autorisé à utiliser ce code.",
                        loading: false
                    })
                }
                if (error?.response?.data?.code === 'CODE_MANDATORY') {
                    return this.setState({
                        error: "Merci de saisir un code.",
                        loading: false
                    })
                }

                return this.setState({
                    error: "Erreur interne: veuillez réessayer plus tard.",
                    loading: false
                })
            });
    }

    newCode() {
        this.setState({
            currentCode: { new: true, active: true },
            loading: false,
            message: "",
            error: ""
        })
    }

    select(meetcode) {
        this.setState({
            currentCode: meetcode,
            loading: false,
            message: "",
            error: ""
        })
    }

    componentDidMount() {
        this.getAllRequests()
    }

    render() {
        const { meetcodes, currentCode, loading, message, error } = this.state
        return (
            <Row className="mt-2 p-0 p-lg-3 mx-auto">
                <Col className="col-12 col-lg-8 px-0 px-lg-3" style={{ maxHeight: "70vw", overflowY: 'auto' }}>
                    <div className="h2 text-left text-primary col-auto text-xl">
                        <span>MeetCodes</span> <Button color="primary" className="ml-2" outline onClick={this.newCode}><i className="fas fa-plus mr-2" /> Ajouter un code</Button>
                    </div>
                    <Table className="table-hover  justify-content-center text-left">
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>MP</th>
                                <th>Nb d'utilisation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {meetcodes.length > 0 &&
                                meetcodes.filter(meetcode => meetcode.active).map((meetcode, index) => (
                                    <tr key={index} className={meetcode.code === currentCode?.code ? "cursor-pointer text-white bg-primary" : "cursor-pointer"} onClick={() => this.select(meetcode)}>
                                        <td>{meetcode.code}</td>
                                        <td>{meetcode.points}</td>
                                        <td>{meetcode.count_use}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                    <div className="h2 text-left text-primary col-auto text-xl">
                        <span>Historique</span>
                    </div>
                    <Table className="table-hover  justify-content-center text-left">
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>MP</th>
                                <th>Nb d'utilisation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {meetcodes.length > 0 &&
                                meetcodes.filter(meetcode => !meetcode.active).map((meetcode, index) => (
                                    <tr key={index} className={meetcode.code === currentCode?.code ? "cursor-pointer text-white bg-primary" : "cursor-pointer"} onClick={() => this.select(meetcode)}>
                                        <td>{meetcode.code}</td>
                                        <td>{meetcode.points}</td>
                                        <td>{meetcode.count_use}</td>
                                    </tr>

                                ))}
                        </tbody>
                    </Table>
                </Col>
                <Col className="col-12 col-lg-4 px-0 px-lg-3">
                    <Card className="mt-2">
                        {currentCode && <Row>
                            <Col className="col-12 text-left">
                                Code : <b>{currentCode.code}</b> <br />
                                Meetpoints : <b>{currentCode.points} MP</b> <br />
                                Actif : <b>{currentCode.active ? "oui" : "non"}</b> <br />
                                Nombre d'utilisations : <b>{currentCode.count_use}</b> <br />
                                Nombre d'utilisations maximum : <b>{currentCode.limit_use}</b> <br />
                                Date d'expiration : <b>{currentCode.limit_date && moment(currentCode.limit_date).format('LLLL')}</b> <br />
                                Créé le : <b>{moment(currentCode.creation_date).format('LLLL')}</b> <br />
                                Date de la dernière modification : <b>{moment(currentCode.modification_date).format('LLLL')}</b>
                            </Col>
                            <Col className="col-12 mt-2 pt-2 border-top text-left">
                                {currentCode?.new && <FormGroup row className="mb-0">
                                    <Label
                                        className="mt-0 text-right"
                                        for="code"
                                        sm={4}
                                    >
                                        MeetCode
                                    </Label>
                                    <Col sm={8}>
                                        <Input
                                            id="code"
                                            name="code"
                                            placeholder="MeetCode"
                                            type="code"
                                            value={currentCode.code}
                                            onChange={this.handleInputChange}
                                        />
                                    </Col>
                                </FormGroup>
                                }
                                <FormGroup row className="mb-0">
                                    <Label
                                        className="mt-0 text-right"
                                        for="points"
                                        sm={4}
                                    >
                                        Meetpoints
                                    </Label>
                                    <Col sm={8}>
                                        <Input
                                            id="points"
                                            name="points"
                                            placeholder="Meetpoints"
                                            type="text"
                                            value={currentCode.points ? currentCode.points : ""}
                                            onChange={this.handleInputChange}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-0">
                                    <Label
                                        className="mt-0 text-right"
                                        for="active"
                                        sm={4}
                                    >
                                        Actif
                                    </Label>
                                    <Col sm={8}>
                                        <Input
                                            className="ml-3 mt-3"
                                            id="active"
                                            name="active"
                                            type="checkbox"
                                            checked={currentCode.active}
                                            onChange={this.handleInputChange}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-0">
                                    <Label
                                        className="mt-0 text-right"
                                        for="limit_use"
                                        sm={4}
                                    >
                                        Nombre d'utilisations maximum
                                    </Label>
                                    <Col sm={8}>
                                        <Input
                                            id="limit_use"
                                            name="limit_use"
                                            type="number"
                                            value={currentCode.limit_use ? currentCode.limit_use : ""}
                                            min={0}
                                            onChange={this.handleInputChange}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-0">
                                    <Label
                                        className="mt-0 text-right"
                                        for="limit_date"
                                        sm={4}
                                    >
                                        Date d'expiration
                                    </Label>
                                    <Col sm={8}>
                                        <Input
                                            id="limit_date"
                                            name="limit_date"
                                            type="date"
                                            value={currentCode.limit_date ? moment(currentCode.limit_date).format('YYYY-MM-DD') : ""}
                                            onChange={this.handleInputChange}
                                        />
                                    </Col>
                                </FormGroup>
                                <Button className="float-right mt-2" onClick={this.save} color="primary" disabled={!currentCode.code || loading}>Enregistrer</Button>
                                {message && <p className="text-success mt-3">{message}</p>}
                                {error && <p className="text-danger mt-3">{error}</p>}
                            </Col>
                            <Col className="col-12 mt-2 pt-2 border-top text-left">
                                Historique d'utilisation : <br />
                                {currentCode.candidates && currentCode.candidates.map((candidate, index) => (
                                    <span>- {candidate.name} {candidate.last_name} ({moment(candidate.Meetcode_Usage.creation_date).format('LLLL')})<br /></span>
                                ))}
                            </Col>
                        </Row>
                        }
                    </Card>
                </Col>
            </Row>
        )
    }
}