import React, { Component } from 'react'
import { Badge, Button, Col, Row } from 'reactstrap'
import { informationStyle, overlayStyle } from '../styles/mapPage.style'
import accountConfig from './config/account.config'
import miscConfig from './config/misc.config'
import ShiftComparison from './shift/shiftComparison.component'

export default class OfferDisplay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clickedOffer: null,
            shiftsCandidate: null,
        }
    }

    componentDidMount() {
        this.setState({
            clickedOffer: this.props.clickedOffer,
            shiftsCandidate: this.props.shiftsCandidate
        })
    }

    render() {
        const { clickedOffer, shiftsCandidate } = this.state
        if (clickedOffer && shiftsCandidate) {
            return (

                <div>
                    <div className="overlay" id="overlay" style={overlayStyle} onClick={this.props.deactivateOverlay} />
                    <div className="information p-3 p-md-5" id="informationPage" style={informationStyle}>
                        <Row>
                            <Col className="text-right">
                                <Button outline color="primary" onClick={this.props.deactivateOverlay} className="mb--3 mr--3 bg-white" style={{ zIndex: "1080", border: "none" }}><i className="fas fa-times fa-lg" /></Button>
                            </Col>
                        </Row>
                        <Row className="position-relative">
                            <Col style={{ minHeight: "220px" }}>
                                <div
                                    alt="Bannière de la pharmacie"
                                    className="rounded-lg w-100 h-100"
                                    style={{
                                        backgroundImage: "url('" + (clickedOffer.offer.pharmacy.logo ? clickedOffer.offer.pharmacy.banner : require("../assets/img/default-banner.jpg").default) + "')",  // add pharmacy banner
                                        backgroundPosition: "center",
                                        backgroundSize: "cover"
                                    }}></div>
                            </Col>
                            <div className="rounded-lg position-absolute shadow bg-white" style={{ maxWidth: '250px', height: '130px', width: '100%', bottom: '-40px', left: '40px', overflow: 'hidden' }}>
                                <div
                                    alt="Logo de la pharmacie"
                                    className="rounded-lg border-white w-100 h-100"
                                    style={{
                                        border: "2px solid",
                                        backgroundImage: "url('" + (clickedOffer.offer.pharmacy.logo ? clickedOffer.offer.pharmacy.logo : require("../assets/img/default-thumbnail-profil.jpg").default) + "')",  // add pharmacy logo
                                        backgroundPosition: "center",
                                        backgroundSize: "cover"
                                    }}></div>
                            </div>
                        </Row>
                        <Row style={{ marginTop: "50px" }} className="mb-3">
                            <Col className="col-12 col-lg-8 text-left">
                                <span className="text-primary h1">{this.state.clickedOffer.offer.pharmacy.name}</span>
                                <p>{this.state.clickedOffer.offer.pharmacy.address}<br />{this.state.clickedOffer.offer.pharmacy.city}-{this.state.clickedOffer.offer.pharmacy.zip}</p>
                            </Col>
                            <Col className="col-12 col-lg-4 text-right">
                                <Button outline color="primary" className="border-info w-100 p-2 shadow-none text-sm btn-sm" onClick={this.props.onDemandContact}><i className="far fa-comment-dots"></i><span>Prendre contact</span></Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="text-left">
                                <span className="text-gray">Détails de l'offre d'emploi</span>
                                <hr className="mt-2 mb-3" />
                            </Col>
                        </Row>
                        <Row className="h1">
                            <Col className="col-12 col-lg-auto pl-0 pr-0">
                                <Badge className="bg-white text-primary px-3 text-lowercase"><span className="text-capitalize font-weight-light">Poste : </span><span>{accountConfig.positionList[this.state.clickedOffer.offer.disponibility.position]}</span></Badge>
                            </Col>
                            <Col className="col-12 col-lg-auto pr-0">
                                <Badge className="px-3 bg-primary text-lowercase"><span className="text-capitalize font-weight-light">Distance : </span><i className="fas fa-map-marker-alt" /><span className="pl-1">{this.state.clickedOffer.similarities.distance} km</span></Badge>
                            </Col>
                            <Col className="col-12 col-lg-auto pr-0">
                                <Badge color="secondary" className="px-3"><span className="text-capitalize font-weight-light">Type</span><span className="font-weight-light text-lowercase"> de contrat : </span><span>{this.state.clickedOffer.offer.disponibility.contract_type}</span></Badge>
                            </Col>
                            <Col className="col-12 col-lg-auto pr-0">
                                <Badge className="bg-gray px-3 text-lowercase"><span className="text-capitalize font-weight-light">Rémunération : </span><span>{this.state.clickedOffer.offer.disponibility.salary} € / h</span></Badge>
                            </Col>
                            <Col className="col-12 col-lg-auto pr-0">
                                <Badge className="bg-light text-gray-dark">
                                    <span className="text-capitalize font-weight-light">LGO : </span>{this.state.clickedOffer.offer.pharmacy.software}
                                </Badge>
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <Col className="text-left">
                                <span className="text-gray">Horaires de l'offre</span>
                                <hr className="mt-2 mb-2" />
                            </Col>
                        </Row>
                        {this.state.clickedOffer.offer.disponibility.shifts_flexibility && <p className="text-left" style={{ lineHeight: 1.2 }}>Cette pharmacie indique que les horaires sont flexibles pour cette offre</p>}
                        <ShiftComparison
                            candidateShifts={shiftsCandidate}
                            pharmacyShifts={this.state.clickedOffer.offer.disponibility.shifts}
                        />
                        {this.state.clickedOffer.offer.info && <>
                            <Row className="mt-3">
                                <Col className="text-left">
                                    <span className="text-gray">Informations suplémentaires</span>
                                    <hr className="mt-2 mb-2" />
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-left">
                                    {this.state.clickedOffer.offer.info}
                                </Col>
                            </Row></>
                        }
                        <Button outline color="primary" className="border-info w-100 p-2 shadow-none text-sm btn-sm" onClick={this.props.onDemandContact}><i className="far fa-comment-dots"></i><span>Prendre contact</span></Button>
                        <Row className="mb-6 mt-3">

                            <Col className="text-left">
                                <Button outline color="default" onClick={this.props.deactivateOverlay} className="border-info"><i className="fas fa-angle-left pr-1 fa-lg text-primary" /> Retour</Button>
                            </Col>
                        </Row>

                    </div>
                </div>
            )
        } else {
            return <></>
        }
    }
}