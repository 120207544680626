import React, { Component } from "react"
import { Button, Card, CardBody, CardFooter, Col, Row } from "reactstrap"
import candidateService from "../../services/candidate.service"
import meetPointService from "../../services/meetPoint.service"
import pharmacyService from "../../services/pharmacy.service"
import extractOffersService from "../../services/extractOffers.service"

export default class adminHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nb_candidats: 0,
            nb_pharmacies: 0,
            nb_subs: 0,
            nb_bots: 0,
            nb_docs: 0,
            nb_payments: 0,
            nb_offers: 0
        }
    }

    componentDidMount() {
        candidateService.getCandidateAll().then((data) => this.setState({
            nb_candidats: data.filter(candidate => !(candidate?.account?.account_connexion?.email?.startsWith('rand_'))).length,
            nb_bots: data.filter(candidate => candidate?.account?.account_connexion?.email?.startsWith('rand_')).length,
        }))
        pharmacyService.getAllPharmacies().then((data) => this.setState({
            nb_pharmacies: data.filter(pharmacy => !(pharmacy?.account?.account_connexion?.email?.startsWith('rand_'))).length,
            nb_subs: data.filter(pharmacy => !(pharmacy?.account?.account_connexion?.email?.startsWith('rand_')) && pharmacy.is_paid).length,
        }))
        meetPointService.getAllRequests().then((data) => this.setState({
            nb_payments: data.filter(payment => payment?.type === 'request').length,
        }))
        candidateService.getAllUnvalidatedFiles().then((data) => this.setState({
            nb_docs: data.diplomaList.length+data.cvList.length+data.kbisList.length,
        }))
        extractOffersService.getCountOfOffers().then((data) => this.setState({
            nb_offers: data,
        }))
    }

    handleExportButtonClick = async () => {
        try {
            await extractOffersService.exportOffers();
        } catch (error) {
          console.error("Error exporting offers:", error);
        }
      }
    
    render() {
        var { nb_pharmacies, nb_subs, nb_candidats, nb_bots, nb_docs, nb_payments, nb_offers } = this.state
        return (<>
            <Row className="mt-2 p-0 p-lg-6 mx-auto">
                <Col className="col-12">
                    <div className="text-left d-block d-lg-none display-4 text-white pb-2">Bienvenue sur Meetpharma</div>
                    <div className="text-left d-none d-lg-block display-2 text-white">Bienvenue sur Meetpharma</div>
                </Col>
                <Col className="col-12 col-lg-6">
                    <Card className="my-3 my-lg-5">
                        <i className="fa fa-users fa-2x position-absolute text-primary bg-white rounded p-2" style={{ left: '5%', top: '-10%' }} />
                        <CardBody><b>{nb_candidats}</b> Candidats inscrits sur la plateforme<br /><b>{nb_bots}</b> Candidats auto</CardBody>
                        <CardFooter>
                            <Button href="/candidats" color="primary">Voir tous</Button>
                        </CardFooter>
                    </Card>
                </Col>
                <Col className="col-12 col-lg-6">
                    <Card className="my-3 my-lg-5">
                        <i className="fa fa-clinic-medical fa-2x position-absolute text-primary bg-white rounded p-2" style={{ left: '5%', top: '-10%' }} />
                        <CardBody><b>{nb_pharmacies}</b> Pharmaciens inscrits sur la plateforme <br />dont <b>{nb_subs}</b> abonnés</CardBody>
                        <CardFooter>
                            <Button href="/pharmacies" color="primary">Voir tous</Button>
                        </CardFooter>
                    </Card>
                </Col>
                <Col className="col-12 col-lg-6">
                    <Card className="my-3 my-lg-5">
                        <i className="fa fa-clipboard-check fa-2x position-absolute text-primary bg-white rounded p-2" style={{ left: '5%', top: '-10%' }} />
                        <CardBody><b>{nb_docs}</b> documents à vérifier</CardBody>
                        <CardFooter>
                            <Button href="/certification" color="primary" outline={!nb_docs}>Vérifier maintenant</Button>
                        </CardFooter>
                    </Card>
                </Col>
                <Col className="col-12 col-lg-6">
                    <Card className="my-3 my-lg-5">
                        <i className="fa fa-money-check-alt fa-2x position-absolute text-primary bg-white rounded p-2" style={{ left: '5%', top: '-10%' }} />
                        <CardBody><b>{nb_payments}</b> virements à effectuer</CardBody>
                        <CardFooter>
                            <Button href="/gestionCagnotte" color="primary" outline={!nb_payments}>Gestion des virements</Button>
                        </CardFooter>
                    </Card>
                </Col>
                <Col className="col-12 col-lg-6">
                    <Card className="my-3 my-lg-5">
                        <i className="fa fa-briefcase fa-2x position-absolute text-primary bg-white rounded p-2" style={{ left: '5%', top: '-10%' }} />
                        <CardBody><b>{nb_offers}</b> {nb_offers < 2 ? 'offre' : 'offres'} à extraire ce mois-ci</CardBody>
                        <CardFooter>
                            <Button onClick={this.handleExportButtonClick} color="primary" outline={!nb_offers}>Extraire</Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row></>
        )
    }
}