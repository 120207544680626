import http from '../http-common'
import AccountHeader from './account-header'

class AccountService {
    getAllRequests() {
        return http.get(
            'meetPoints',
            //{ params: { requests: true } },
            { headers: AccountHeader() }
        ).then(response => {
            return response.data
        })
    }

    debitOne(idRequest, reference) {
        return http.post(
            `meetPoints/${idRequest}`,
            { reference: reference },
            { headers: AccountHeader() }
        )
    }
}
export default new AccountService()