import React, { Component } from "react"
import Form from "react-validation/build/form"
import Input from "react-validation/build/input"
import AccountService from "../services/account.service"
import stripeService from "../services/stripe.service"

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.handleLogin = this.handleLogin.bind(this)
    this.onChangeEmail = this.onChangeEmail.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
    this.forgotPassword = this.forgotPassword.bind(this)
    this.requestReset = this.requestReset.bind(this)

    this.state = {
      forgotPassword: false,
      email: "",
      password: "",
      loading: false,
      message: ""
    }
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    })
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    })
  }

  async handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });

    try {
      const { email, password } = this.state;

      await this.handleLoginAfterSubscription(email, password);
    } catch (error) {
      console.error("Error during login:", error);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleFreeTrialSubscription(email, password) {
    try {
      await stripeService.subscribeToFreeTrial(email);
      await this.handleLoginAfterSubscription(email, password);
    } catch (error) {
      console.error("Error during subscription:", error);
    }
  }

  async handleLoginAfterSubscription(email, password) {
    try {
      await AccountService.login(email, password);
      this.props.history.push("/offers_in_radius");
      window.location.reload();
    } catch (error) {
      this.setState({
        message: "L'email ou le mot de passe est incorrect",
      });
    }
  }

  forgotPassword() {
    this.setState({ forgotPassword: !this.state.forgotPassword })
  }

  home() {
    window.location = "/"
  }

  async requestReset() {
    let value = await AccountService.requestReset(this.state.email)
    if (value) {
      this.setState({ message: "Un email vient d'être envoyé à l'adresse suivante: " + this.state.email })
    } else {
      this.setState({ message: "Cet email est inconnu" })
    }
  }

  render() {
    const { email, password, loading, message, forgotPassword } = this.state
    return (
      <div className="col-md-12">
        <div className="card card-container  text-left">
          <h1 className="text-primary text-center">Connexion Meetpharma</h1>
          {forgotPassword ?
            <div>
              <p className="mt-2 text-sm" style={{ lineHeight: 1.1 }}>Merci de saisir votre <b>email</b> pour recevoir un <b>lien de réinitialisation</b> de mot de passe</p>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={this.onChangeEmail}
                />
              </div>
              <button
                className="btn btn-primary btn-block"
                onClick={this.requestReset}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Réinitialiser mon mot de passe</span>
              </button>
              {message && (
                <div className="form-group mt-2">
                  <div className="alert alert-light text-primary" role="alert">
                    {message}
                  </div>
                </div>
              )}

              <div className="text-left mt-2">
                <span className="cursor-pointer text-sm text-default" onClick={this.forgotPassword}>	← Retour</span>
              </div>
            </div> :
            <Form
              onSubmit={this.handleLogin}
              ref={c => {
                this.form = c
              }}>
              <div className="form-group mb-1">
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={this.onChangeEmail}
                />
              </div>
              <div>
                <div className="form-group">
                  <label htmlFor="password">Mot de passe</label>
                  <Input
                    type="password"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={this.onChangePassword}
                  />
                </div>

                <div className="form-group">
                  <button
                    className="btn btn-primary btn-block"
                    disabled={loading}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Se connecter</span>
                  </button>
                </div>

                {message && (
                  <div className="form-group">
                    <div className="alert alert-light text-primary" role="alert">
                      {message}
                    </div>
                  </div>
                )}
              </div>
              <div className="text-right">
                <span className="cursor-pointer text-sm " onClick={this.forgotPassword}>Mot de passe oublié ?</span>
              </div>
              <div className="text-center mt-2">
                Vous n'avez pas de compte ?<br /> <span className="cursor-pointer text-sm text-primary" onClick={this.home}>Inscrivez-vous gratuitement ici en répondant aux questions de Sarah</span>
              </div>
            </Form>
          }

        </div>
      </div>
    )
  }
}