import miscConfig from "../components/config/misc.config"

class Geocoding {

    async coordinatesFromAddress(street, city, zip, country = 'FR') {
        var parameters = `address=${street},+${city}+${zip},+${country}`
        parameters = parameters.replaceAll(' ', '+')
        var geocodingData
        await fetch(`https://maps.googleapis.com/maps/api/geocode/json?${parameters}&key=${miscConfig.mapApiKey}`)
            .then(response => response.json())
            .then((jsonData) => {
                geocodingData = jsonData
            }).catch((error) => {
                console.error(error)
                return
            })
        return geocodingData?.results[0]?.geometry?.location
    }
}
export default new Geocoding()