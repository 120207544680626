import React, { Component } from "react"
import ReactCrop from "react-image-crop"
import 'react-image-crop/dist/ReactCrop.css'
import { Button, Col, FormGroup, Input, Row } from "reactstrap"

export default class cropper extends Component {
    constructor(props) {
        super(props)
        this.imageRed = ''
        this.onSelectFile = this.onSelectFile.bind(this)
        this.onImageLoaded = this.onImageLoaded.bind(this)
        this.onCropChange = this.onCropChange.bind(this)
        this.onCropComplete = this.onCropComplete.bind(this)
        this.onDeleteFile = this.onDeleteFile.bind(this)
        this.state = {
            src: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 1 / 1
            },
            croped64: "",
            typeImage: this.props.typeImage
        }
    }

    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    // If you setState the crop in here you should return false.
    onImageLoaded = (image) => {
        this.imageRef = image
    }

    onCropComplete = (crop) => {
        this.makeClientCrop(crop)
        this.setState({ myCrop: crop })
    }

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop })
        this.setState({ crop })
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            )
            this.setState({ croppedImageUrl })
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas')
        const pixelRatio = window.devicePixelRatio
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const ctx = canvas.getContext('2d')

        canvas.width = crop.width * pixelRatio * scaleX
        canvas.height = crop.height * pixelRatio * scaleY

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
        ctx.imageSmoothingQuality = 'high'

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        )

        this.setState({ croped64: canvas.toDataURL("image.jpeg") })// Here we get an image as a base64 string we can send it to the api (cf link: https://github.com/expressjs/multer/issues/516)
        var e = {
            target: {
                name: this.state.typeImage,
                value: canvas.toDataURL("image.jpeg"),
            },
        }
        this.props.handleInputChange(e)
    }

    async onDeleteFile() {
        this.setState({
            croped64: null,
            src: null,
        })

        this.props.handleInputChange({
            target: {
                name: this.state.typeImage,
                value: "delete",
            }
        })

    }
    componentDidMount() {
        if (this.props.typeImage === "banner") {
            this.setState({
                crop: {
                    unit: '%',
                    width: 30,
                    aspect: 10 / 3
                }
            })

        }
    }

    render() {
        const { crop, croped64, src } = this.state

        return (
            <Row name={this.props.label}>
                <Col className="col-4">
                    {this.props.thumbnail && (this.props.thumbnailLink || src) && <img className="rounded" alt="croppedImage" src={croped64 ? croped64 : this.props.thumbnailLink} style={{ maxWidth: '100%' }} />}
                </Col>
                <Col className="col-8">
                    <FormGroup className="mb-1">
                        {this.props.label && <label className="font-weight-bold mb-1" htmlFor={this.props.id}>{this.props.label}</label>}
                        {src ? <>
                            <ReactCrop
                                src={src}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                            <br />
                            <Button color="primary" type="button" id={this.props.id} onClick={() => this.onDeleteFile()} style={{ padding: "0.15rem 0.4rem" }} target="_blank" rel="noreferrer">
                                <span>
                                    <i className="fas fa-undo-alt" /> Réinitialiser le fichier
                                </span>
                            </Button></>
                            :
                            <Input
                                type="file"
                                accept="image/*"
                                onChange={this.onSelectFile}
                                className="btn"
                                style={{ background: "#f6f6f6" }}
                            />
                        }
                    </FormGroup>
                </Col>
            </Row>
        )
    }
}
