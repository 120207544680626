import React from "react"
import { Route, Redirect, useLocation } from "react-router-dom"

function AuthenticatedRoute({ children, ...rest }) {
    return (
        <Route {...rest}>
            {rest.user?.role === "titulaire" || rest.user?.role === "candidat" ? (
                //  {isAuthenticated ? ( 
                children
            ) : (<Redirect to={
                `/`
            } />
            )}
        </Route>
    )
}
function AuthenticatedCandidateRoute({ children, ...rest }) {
    const { pathname, search } = useLocation()
    return (
        <Route {...rest}>
            {rest.isCandidate ? (
                children
            ) : (<Redirect to={
                `/login?redirect=${pathname}${search}`
            } />
            )}
        </Route>
    )
}
function AuthenticatedPharmacyRoute({ children, ...rest }) {
    const { pathname, search } = useLocation()
    return (
        <Route {...rest}>
            {rest.isPharmacy ? //((rest.pharmacy.is_paid || rest.path === "/subscription") ?
                children /*: 
                <Redirect to={
                    `/subscription`
                } />)*/
                : (<Redirect to={
                    `/login?redirect=${pathname}${search}`
                } />
                )}
        </Route>
    )

}

function AuthenticatedAdminRoute({ children, ...rest }) {
    const { pathname, search } = useLocation()
    return (
        <Route {...rest}>
            {rest.isAdmin ? (
                children
            ) : (
                <Redirect to={`/login?redirect=${pathname}${search}`} />
            )}
        </Route>
    )
}

function UnauthenticatedRoute({ children, ...rest }) {
    return (
        <Route {...rest}>
            {!rest.user ? (
                children
            ) : (
                <Redirect to="/profile" />
            )}
        </Route>
    );
}

export { AuthenticatedRoute, AuthenticatedCandidateRoute, AuthenticatedPharmacyRoute, UnauthenticatedRoute, AuthenticatedAdminRoute }