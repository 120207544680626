import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import './index.css';
import App from './App';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './assets/scss/argon-dashboard-pro-react.scss'
import './assets/css/meetPharma.css';
import { registerServiceWorker } from './serviceWorkerRegistration';
import IosPWAPrompt from './components/iosPwaPromt.component';
import './utils/analytics.utils';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" render={(props) => <App {...props} />} />
      <Redirect from="*" to="/" />
    </Switch>
    <IosPWAPrompt/>
  </BrowserRouter>,
  document.getElementById('root')
);

registerServiceWorker();
// requestNotificationPermission();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals