import http from '../http-common';
import accountHeader from './account-header';

class ExtractOffersService {
  exportOffers() {
    return http.get('/export-offers', {
      headers: accountHeader(),
      responseType: 'blob',
    })
    .then(response => {
      // Create a Blob object from the binary data
      const blob = new Blob([response.data], { type: 'application/csv' });

      // Create a link element and trigger a download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'offers.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(error => {
      console.error('Error exporting offers:', error);
      throw error;
    });
  }

  getCountOfOffers() {
    return http.get('/count-offers', {
      headers: accountHeader(),
    })
    .then(response => {
      const offerCount = response.data.offerCount;
      return offerCount; 
    })
    .catch(error => {
      console.error('Error getting count of offers:', error);
      throw error;
    });
  }
    
}

export default new ExtractOffersService();
