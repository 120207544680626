module.exports = {
    mapApiKey: "AIzaSyDiFdRtsoIGqxwgrxB9QKTOjeq06lnJQFg",

    searchDistanceLeeway: 0.2, //in %

    offerCloseReason: {
        'date-passee': 'Elle n\'est plus d\'actualité',
        'annulation': "Je ne veux plus qu'elle apparaisse sur Meetpharma",
        'validation': 'J\'ai trouvé un(e) candidate(e) grâce à Meetpharma',
        'trouve-hors-app': "J'ai trouvé un(e) candidat(e) en dehors de l'application",
        'autre': 'Autre...'
    },
    uploadLink: process.env.REACT_APP_API_URL + "/uploads/",

    defaultLogo: "https://st.depositphotos.com/2398103/4765/v/950/depositphotos_47651913-stock-illustration-vector-pharmacy-cross-icon.jpg",

    emptyShifts: "000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
    possibleDebits: [
        //     {
        //     mp: 100,
        //     eur: 20
        // }, 
        {
            mp: 500,
            eur: 20
        }, {
            mp: 1000,
            eur: 50
        }, {
            mp: 2000,
            eur: 100
        }],
}