export default function BeabaPharmacy(props) {
    return (<div>
        <b>Savoir ce vendre</b>
        <div>
            Savoir ce vendre c'est obtenir des contracts plus interessants
        </div>
        <div>

            Ut luctus ac nibh sit amet ornare. Nullam ut dui orci. Cras luctus lacinia nisl et euismod. Integer maximus hendrerit leo sed sollicitudin. Aliquam justo urna, porta dictum venenatis sollicitudin, mollis vitae leo. Pellentesque consequat nulla in consectetur aliquet. Nulla risus orci, molestie id vehicula eget, fringilla nec lacus. Pellentesque mattis porttitor ultricies. Mauris ac dui nunc. Morbi tempor nec quam a aliquet. Fusce finibus in nunc in aliquet. Pellentesque elementum urna ipsum, a commodo elit sagittis vitae. Cras sit amet lectus nulla. Donec feugiat lorem commodo dapibus hendrerit.

            Duis metus nisi, varius et euismod nec, porta in purus. Phasellus venenatis cursus pellentesque. Aenean non vestibulum metus. Sed consectetur augue nulla, quis dignissim nulla tempus et. Vivamus scelerisque, ipsum sed ullamcorper cursus, elit lorem accumsan orci, et euismod justo diam id augue. Nulla velit urna, facilisis sed consectetur et, convallis id justo. Phasellus leo arcu, sodales vel pulvinar non, eleifend nec mi. Morbi elit orci, rhoncus vel tellus nec, semper sollicitudin ex. Quisque at mauris ut metus viverra pharetra. Quisque sed pellentesque augue. Pellentesque molestie lorem ut quam sodales, nec faucibus nisi feugiat. Proin ligula odio, accumsan ac rutrum non, tristique vel lorem. Donec non bibendum enim, non facilisis justo.
            
            Morbi maximus maximus tincidunt. Sed id interdum justo. Duis imperdiet, nisi vel scelerisque consectetur, mauris ipsum tristique arcu, ac vulputate quam ipsum eu tellus. Nam malesuada ligula purus, eget aliquet libero mattis vitae. Nunc vitae augue eu nunc tristique vehicula vitae eu dolor. Ut consectetur mauris nulla, at pellentesque sem sagittis sit amet. Nullam vel malesuada lorem, in fringilla mauris. Phasellus nec libero non est semper volutpat in nec tortor.
        </div>
    </div>)

}