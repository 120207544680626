import http from '../http-common'
import AccountHeader from './account-header'

class MeetCodeService {
    getAll() {
        return http.get(
            'meetcodes',
            { headers: AccountHeader() }
        )
    }

    claim(code) {
        return http.get('meetcodes/' + code,
            { headers: AccountHeader() }
        )
    }

    save(data) {
        return http.post('meetcodes/',
            data,
            { headers: AccountHeader() }
        )
    }
}
export default new MeetCodeService()