import React, { Component } from "react"
import { Button, Card, Col, Row, Table } from "reactstrap"
import candidateService from "../../services/candidate.service"
import miscConfig from "../config/misc.config"

export default class fileValidation extends Component {
    constructor(props) {
        super(props)
        this.getDocuments = this.getDocuments.bind(this)
        this.state = {
            documentList: [],
            currentInfo: null,
        }
    }

    async getDocuments() {
        var files = await candidateService.getAllFiles()
        var combinedFiles = []
        if (files.diplomaList) {
            combinedFiles.push(...files.diplomaList)
        }
        if (files.kbisList) {
            combinedFiles.push(...files.kbisList)
        }
        if (files.cvList) {
            combinedFiles.push(...files.cvList)
        }
        this.setState({ documentList: combinedFiles })
    }
    async validation(e) {
        var data = e.target
        if (window.confirm('Etes-vous certain de vouloir valider ce fichier ?')) {
            await candidateService.validateFile(data.name, data.value)
        }
        this.getDocuments()
    }
    async refusal(e) {
        var data = e.target
        if (window.confirm('Etes-vous certain de vouloir refuser ce fichier ?')) {
            await candidateService.refuseFile(data.name, data.value)
        }
        this.getDocuments()
    }

    componentDidMount() {
        this.getDocuments()
    }
    render() {
        const { documentList, currentInfo } = this.state
        return (
            <Row className="mt-2 p-0 p-lg-3 mx-auto">
                <Col className="col-12 col-lg-8 px-0 px-lg-3" style={{ maxHeight: "70vw", overflowY: 'auto' }}>
                    <div className="h2 text-left text-primary col-auto text-xl">
                        <span>Documents à valider</span>
                    </div>
                    <Table className="table-hover text-left justify-content-center">
                        <thead>
                            <tr>
                                <th>
                                    Utilisateur
                                </th>
                                <th>
                                    Poste
                                </th>
                                <th>
                                    Document
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {documentList.length > 0 &&
                                documentList.filter(doc => !doc.fileValidated).map((info, index) => (
                                    <tr key={index} className={info === currentInfo ? "cursor-pointer text-white bg-primary" : "cursor-pointer"} onClick={() => this.setState({ currentInfo: info, currentFile: info.file })}>
                                        <td className="text-md font-weight-bold align-middle">
                                            {info.userName}
                                        </td>
                                        <td className="text-md font-weight-bold align-middle">
                                            {info.poste}
                                        </td>
                                        <td className="text-md font-weight-bold align-middle">
                                            <span>{info.type}</span>
                                        </td>
                                        {index === this.state.currentIndex && <td className="text-right">
                                            <Button name={info.type} color="white" value={info.userId} onClick={(e) => this.validation(e)}>Valider</Button>
                                            <Button name={info.type} outline color="white" value={info.userId} onClick={(e) => this.refusal(e)}>Refuser</Button>
                                        </td>}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                    <div className="h2 text-left text-primary col-auto text-xl">
                        <span>Documents vérifiés</span>
                    </div>
                    <Table className="table-hover text-left justify-content-center">
                        <thead>
                            <tr>
                                <th>
                                    Utilisateur
                                </th>
                                <th>
                                    Poste
                                </th>
                                <th>
                                    Document
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {documentList.length > 0 &&
                                documentList.filter(doc => doc.fileValidated).map((info, index) => (
                                    <tr key={index} className={info === currentInfo ? "cursor-pointer text-white bg-primary" : "cursor-pointer"} onClick={() => this.setState({ currentInfo: info, currentFile: info.file })}>
                                        <td className="text-md font-weight-bold align-middle">
                                            {info.userName}
                                        </td>
                                        <td className="text-md font-weight-bold align-middle">
                                            {info.poste}
                                        </td>
                                        <td className="text-md font-weight-bold align-middle">
                                            <span>{info.type}</span>
                                        </td>
                                        {index === this.state.currentIndex && <td className="text-right">
                                            <Button name={info.type} color="white" value={info.userId} onClick={(e) => this.validation(e)}>Valider</Button>
                                            <Button name={info.type} outline color="white" value={info.userId} onClick={(e) => this.refusal(e)}>Refuser</Button>
                                        </td>}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Col>
                <Col className="col-12 col-lg-4">
                    <Card className="mt-2">
                        {currentInfo && <Row>
                            <embed src={currentInfo.file} width="100%" height="500px" type='application/pdf' />
                            <Col className="col-12 mt-2 pt-2 border-top">
                                <Button name={currentInfo.type} color="primary" value={currentInfo.userId} onClick={(e) => this.validation(e)}>Valider</Button>
                                <Button name={currentInfo.type} outline color="primary" value={currentInfo.userId} onClick={(e) => this.refusal(e)}>Refuser</Button>
                            </Col>
                        </Row>}
                    </Card>
                </Col>
            </Row>
        )
    }
}