import { Component } from 'react'
import { Row, Col, Table } from 'reactstrap'
import accountConfig from '../config/account.config'

export default class ShiftCalendarDisplay extends Component {
    constructor(props) {
        super(props)
        this.checkTwoWeeks = this.checkTwoWeeks.bind(this)
        this.turnShiftsIntoDays = this.turnShiftsIntoDays.bind(this)
        this.state = {
            shifts: "",
            moTimes: [],
            tuTimes: [],
            weTimes: [],
            thTimes: [],
            frTimes: [],
            saTimes: [],
            suTimes: [],
            moTimes2: [],
            tuTimes2: [],
            weTimes2: [],
            thTimes2: [],
            frTimes2: [],
            saTimes2: [],
            suTimes2: [],
            twoWeeks: true,
        }
    }
    async turnShiftsIntoDays(shifts) {
        if (shifts) {
            this.setState({ shifts })
        }
        var shiftsList = this.state.shifts.match(/.{1,48}/g)

        var nbShifts = 0
        shiftsList.forEach(dayShifts => {
            let dayName = accountConfig.dayEnum[nbShifts]
            nbShifts++
            this.setState({ [dayName]: [...dayShifts] })
        });
    }
    checkTwoWeeks() {

        if (this.props.twoWeeks !== undefined) {
            this.setState({ twoWeeks: this.props.twoWeeks })
        } else {
            var weeks = this.state.shifts.match(/.{1,336}/g)
            if (weeks[0] === weeks[1]) {
                this.setState({ twoWeeks: false })
            }
        }
    }

    async componentDidMount() {
        await this.setState({ shifts: this.props.parentShifts })
        this.checkTwoWeeks()
        this.turnShiftsIntoDays()
    }

    render() {
        const {
            moTimes, tuTimes, weTimes, thTimes, frTimes, saTimes, suTimes, moTimes2, tuTimes2, weTimes2, thTimes2, frTimes2, saTimes2, suTimes2, twoWeeks } = this.state
        return (
            <>
                {this.props.isComparison ? <>
                    <Row>
                        <Col className="text-right text-sm">
                            <div className="c1 rounded-sm d-inline-block ml-3 mr-2" style={{ width: "40px", height: "15px", marginBottom: "-2px" }}></div><span>Horaires compatibles</span>

                            <div className="c3 rounded-sm d-inline-block ml-3 mr-2" style={{ width: "40px", height: "15px", marginBottom: "-2px" }}></div><span>Planning de l'offre</span>
                        </Col>
                    </Row>
                    {/*<p>violet = possible pour le candidat</p>*/}
                </> : <></>}
                <Row className="mt-3">
                    <Col className="text-left col-md-6 col-12">
                        {twoWeeks && <span className="text-gray text-lg">Semaines paires</span>}
                        <Table size="sm" className="schedule table-borderless text-center">
                            <thead>
                                <tr>
                                    <th scope="col"> </th>
                                    <th scope="col">L</th>
                                    <th scope="col">M</th>
                                    <th scope="col">M</th>
                                    <th scope="col">J</th>
                                    <th scope="col">V</th>
                                    <th scope="col">S</th>
                                    <th scope="col">D</th>
                                </tr>
                            </thead>
                            <tbody className="pt-2">
                                {moTimes && moTimes.map((time, index) => {
                                    return <>
                                        {[14, 40].includes(index) && <tr key={index + 'bis'} style={{ height: "5px" }}>
                                        </tr>
                                        }<tr key={index} className={(index < 14 || index >= 40 ? "h-night" : "h-day") }>
                                            {index % 2 === 0 && <th className="hour" scope="row" rowSpan="2" style={{ paddingLeft: 0, paddingTop: ".25rem", paddingRight: 0}}>{accountConfig.timeEnum[index].label}</th>}
                                            <td className={"schedule-box c" + time}></td>
                                            <td className={"schedule-box c" + tuTimes[index]}></td>
                                            <td className={"schedule-box c" + weTimes[index]}></td>
                                            <td className={"schedule-box c" + thTimes[index]}></td>
                                            <td className={"schedule-box c" + frTimes[index]}></td>
                                            <td className={"schedule-box c" + saTimes[index]}></td>
                                            <td className={"schedule-box c" + suTimes[index]}></td>
                                        </tr>
                                    </>
                                })}
                            </tbody>
                        </Table>
                    </Col>
                    {twoWeeks &&
                        <Col className="text-left col-md-6 col-12">
                            <span className="text-gray text-lg">Semaines impaires</span>
                            <Table size="sm" className="schedule table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">L</th>
                                        <th scope="col">M</th>
                                        <th scope="col">M</th>
                                        <th scope="col">J</th>
                                        <th scope="col">V</th>
                                        <th scope="col">S</th>
                                        <th scope="col">D</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {moTimes2 && moTimes2.map((time, index) => {
                                        return <>
                                            {[14, 40].includes(index) && <tr key={index + 'bis'} style={{ height: "5px" }}>
                                            </tr>
                                            }
                                            <tr key={index} className={(index < 14 || index >= 40 ? "h-night" : "h-day") }>
                                                {index % 2 === 0 && <th className="hour" scope="row" rowSpan="2" style={{ paddingLeft: 0, paddingTop: ".25rem", paddingRight: 0}}>{accountConfig.timeEnum[index].label}</th>}
                                                <td className={"schedule-box c" + time}></td>
                                                <td className={"schedule-box c" + tuTimes2[index]}></td>
                                                <td className={"schedule-box c" + weTimes2[index]}></td>
                                                <td className={"schedule-box c" + thTimes2[index]}></td>
                                                <td className={"schedule-box c" + frTimes2[index]}></td>
                                                <td className={"schedule-box c" + saTimes2[index]}></td>
                                                <td className={"schedule-box c" + suTimes2[index]}></td>
                                            </tr></>
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    }
                </Row>
            </>
        )
    }
}