module.exports = {

    positionList: {
        null: 'Non renseigné',
        'pharmacien': 'Pharmacien',
        'preparateur': 'Préparateur en pharmacie',
        'apprenti-preparateur': 'Apprenti préparateur',
        'conditionneur-rayonniste': 'Conditionneur ou rayonniste',
        'etudiant3': 'Étudiant en 3ème année',
        'etudiant4': 'Étudiant en 4ème année',
        'etudiant5': 'Étudiant en 5ème année',
        'etudiant6': 'Étudiant en 6ème année',
        'optique': 'Optique-lunetterie',
        'employe-vendeur': 'Vendeur en parapharmacie',
        'dermocosmetique': 'Conseiller(ère) en dermocosmétique',
        'orthopedie': 'Ouvriers et employés d’orthopédie',
        'audioprothese': 'Employés en audioprothèses'
    },

    fromList: {
        null: '?',
        'rs': 'Réseaux sociaux',
        'proche': 'Recommandation d\'un proche',
        'officine': 'Recommandé par une officine',
        'presse': 'Presse spécialisée',
        'recherche': 'Moteur de recherche',
        'autre': 'Autres'
    },

    contractTypes: ['CDD', 'CDI', 'stage', 'alternance', 'prestation'],

    softwareList: {
        'lgpi': 'LGPI',
        'winpharma': 'Winpharma',
        'smart-rx': 'Smart Rx',
        'leo': 'Léo',
        'pharmaland': 'PharmaLand',
        'pharmavitale': 'PharmaVitale',
        'caduciel-v6': 'Caduciel V6',
        'esculape': 'Esculape',
        'crystal': 'Crystal',
        'actiPharm': 'ActiPharm',
        'logipharm': 'Logiphar',
        'vindilis': 'Vindilis',
        'visiopharm': 'VisioPharm',
    },
    softwareList2: [
        { value: 'actiPharm', label: 'ActiPharm' },
        { value: 'caduciel-v6', label: 'Caduciel V6' },
        { value: 'crystal', label: 'Crystal' },
        { value: 'esculape', label: 'Esculape' },
        { value: 'leo', label: 'Léo' },
        { value: 'lgpi', label: 'LGPI' },
        { value: 'logipharm', label: 'Logiphar' },
        { value: 'pharmaland', label: 'PharmaLand' },
        { value: 'pharmavitale', label: 'PharmaVitale' },
        { value: 'smart-rx', label: 'Smart Rx' },
        { value: 'vindilis', label: 'Vindilis' },
        { value: 'visiopharm', label: 'VisioPharm' },
        { value: 'winpharma', label: 'Winpharma' },
    ],

    infoSize: 3000,

    salaryEnum: {
        '?': 'négociable',
        '-11': 'moins de 11 €',
        '11-12': '11 à 12 €',
        '13-14': '13 à 14 €',
        '15-18': '15 à 18 €',
        '18+': 'plus de 18 €'
    },

    timeEnum: [
        { value: 0, label: "00:00" }, { value: 1, label: "00:30" },
        { value: 2, label: "01:00" }, { value: 3, label: "01:30" },
        { value: 4, label: "02:00" }, { value: 5, label: "02:30" },
        { value: 6, label: "03:00" }, { value: 7, label: "03:30" },
        { value: 8, label: "04:00" }, { value: 9, label: "04:30" },
        { value: 10, label: "05:00" }, { value: 11, label: "05:30" },
        { value: 12, label: "06:00" }, { value: 13, label: "06:30" },
        { value: 14, label: "07:00" }, { value: 15, label: "07:30" },
        { value: 16, label: "08:00" }, { value: 17, label: "08:30" },
        { value: 18, label: "09:00" }, { value: 19, label: "09:30" },
        { value: 20, label: "10:00" }, { value: 21, label: "10:30" },
        { value: 22, label: "11:00" }, { value: 23, label: "11:30" },
        { value: 24, label: "12:00" }, { value: 25, label: "12:30" },
        { value: 26, label: "13:00" }, { value: 27, label: "13:30" },
        { value: 28, label: "14:00" }, { value: 29, label: "14:30" },
        { value: 30, label: "15:00" }, { value: 31, label: "15:30" },
        { value: 32, label: "16:00" }, { value: 33, label: "16:30" },
        { value: 34, label: "17:00" }, { value: 35, label: "17:30" },
        { value: 36, label: "18:00" }, { value: 37, label: "18:30" },
        { value: 38, label: "19:00" }, { value: 39, label: "19:30" },
        { value: 40, label: "20:00" }, { value: 41, label: "20:30" },
        { value: 42, label: "21:00" }, { value: 43, label: "21:30" },
        { value: 44, label: "22:00" }, { value: 45, label: "22:30" },
        { value: 46, label: "23:00" }, { value: 47, label: "23:30" },
        { value: 48, label: "24:00" },
    ],

    dayEnum: [

        "moTimes",
        "tuTimes",
        "weTimes",
        "thTimes",
        "frTimes",
        "saTimes",
        "suTimes",
        "moTimes2",
        "tuTimes2",
        "weTimes2",
        "thTimes2",
        "frTimes2",
        "saTimes2",
        "suTimes2",
    ],

    dayList1: [

        { value: "moTimes", label: "Lundi" },
        { value: "tuTimes", label: "Mardi" },
        { value: "weTimes", label: "Mercredi" },
        { value: "thTimes", label: "Jeudi" },
        { value: "frTimes", label: "Vendredi" },
        { value: "saTimes", label: "Samedi" },
        { value: "suTimes", label: "Dimanche" },
    ],
    dayList2: [
        { value: "moTimes", label: "Lun. sem. paires" },
        { value: "tuTimes", label: "Mar. sem. paires" },
        { value: "weTimes", label: "Mer. sem. paires" },
        { value: "thTimes", label: "Jeu. sem. paires" },
        { value: "frTimes", label: "Ven. sem. paires" },
        { value: "saTimes", label: "Sam. sem. paires" },
        { value: "suTimes", label: "Dim. sem. paires" },
        { value: "moTimes2", label: "Lun. sem. impaires" },
        { value: "tuTimes2", label: "Mar. sem. impaires" },
        { value: "weTimes2", label: "Mer. sem. impaires" },
        { value: "thTimes2", label: "Jeu. sem. impaires" },
        { value: "frTimes2", label: "Ven. sem. impaires" },
        { value: "saTimes2", label: "Sam. sem. impaires" },
        { value: "suTimes2", label: "Dim. sem. impaires" },
    ]
}