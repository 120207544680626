import React, { Component } from "react"
import Form from "react-validation/build/form"
import Input from "react-validation/build/input"
import CheckButton from "react-validation/build/button"
import accountService from "../services/account.service"
import { withRouter } from "react-router";

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Ce champ est obligatoire
            </div>
        )
    }
}

export default withRouter(class Reset extends Component {
    constructor(props) {
        super(props)
        this.handlePassword = this.handlePassword.bind(this)
        this.onChangePassword = this.onChangePassword.bind(this)
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this)
        this.state = {
            codeCorrect: false,
            message: '',
            resetCode: '',
            email: '',
            activationCode: '',
            password: "",
            confirmPassword: "",
            loading: false,
        }
    }

    async checkCode() {
        let response
        if (this.state.activationCode) {
            response = await accountService.checkActivationCode(this.state.activationCode)
        } else {
            response = await accountService.checkResetCode(this.state.resetCode)
        }
        if (response?.data?.email) {
            this.setState({ codeCorrect: true, email: response.data.email })
            /*let user = await accountService.getCurrentUser()
            if (user)
                accountService.logout()*/
        }
    }

    handlePassword(e) {
        e.preventDefault()

        this.setState({
            message: "",
            loading: true,
        })

        this.form.validateAll()
        if (this.checkBtn.context._errors.length === 0) {
            if (this.state.activationCode) {
                accountService.activatePassword(this.state.activationCode, this.state.password).then(
                    () => {
                        this.props.history.push("/login")
                        window.location.reload()
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString()

                        this.setState({
                            loading: false,
                            message: resMessage
                        })
                    }
                )
            } else {
                accountService.resetPassword(this.state.resetCode, this.state.password).then(
                    () => {
                        this.props.history.push("/login")
                        window.location.reload()
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString()

                        this.setState({
                            loading: false,
                            message: resMessage
                        })
                    }
                )
            }
        } else {
            this.setState({
                loading: false
            })
        }
    }
    onChangePassword(e) {
        this.setState({
            password: e.target.value
        })
    }
    onChangeConfirmPassword(e) {
        this.setState({
            confirmPassword: e.target.value
        })
        if (e.target.value !== this.state.password) {
            return (<div className="alert alert-danger" role="alert">Les valeurs doivent être les mêmes </div>)
        }
    }

    async componentDidMount() {
        var { resetCode, activationCode } = this.props.match.params
        await this.setState({ resetCode, activationCode })
        this.checkCode()
    }
    render() {
        const { codeCorrect, password, confirmPassword, loading, message } = this.state
        return (
            <div className="col-md-12">
                <div className="card card-container">
                    <h4>Changez votre mot de passe</h4>
                    <h4>{this.state.email}</h4>
                    {codeCorrect ?
                        <Form
                            onSubmit={this.handlePassword}
                            ref={c => {
                                this.form = c
                            }}>
                            <div className="form-group">
                                <label htmlFor="password">Nouveau mot de passe</label>
                                <Input
                                    type='password'
                                    className="form-control"
                                    name="password"
                                    value={password}
                                    onChange={this.onChangePassword}
                                    validations={[required]}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword">Confirmer le mot de passe</label>
                                <Input
                                    type='password'
                                    className="form-control"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={this.onChangeConfirmPassword}
                                    validations={[required]}
                                />
                            </div>
                            {password !== confirmPassword ?
                                <div>
                                    <div className="alert alert-danger" role="alert">Les deux mots de passes doivent être identiques</div><div className="form-group">
                                        <button
                                            className="btn btn-primary btn-block"
                                            disabled={true}
                                        >
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            <span>Confirmer</span>
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className="form-group">
                                    <button
                                        className="btn btn-primary btn-block"
                                        disabled={loading}
                                    >
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Confirmer</span>
                                    </button>
                                </div>
                            }
                            {message && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}

                            <CheckButton
                                style={{ display: "none" }}
                                ref={c => {
                                    this.checkBtn = c
                                }}
                            />
                        </Form>
                        :
                        <div>Ce lien est expiré.</div>}

                </div>
            </div>
        )
    }
})