import React, { Component } from 'react'
import MarkerInTable from './markerInTable'
export default class Table extends Component {
    constructor(props) {
        super(props)
        this.setActiveMarker = this.setActiveMarker.bind(this)
        this.updateMarkers = this.updateMarkers.bind(this)
        this.setClickedMarker = this.setClickedMarker.bind(this)
    }

    state = {
        currentPoint: null,
        currentIndex: -1,
        myPoints: this.props.parentDisponibilities,
        isHovered: false,
    }

    updateMarkers(data) {
        this.setState({ myPoints: data })
    }

    setActiveMarker = (childData) => {
        this.props.setActiveMarker(childData)
    }
    setClickedMarker(idOffer) {
        this.props.setClickedMarker(idOffer)
    }

    render() {
        return (
            <div className="bg-white shadow rounded py-2 px-0 px-md-2 mx--4 mx-md-0">
                {this.props.parentDisponibilities && this.props.parentDisponibilities.length > 0? this.props.parentDisponibilities.map((aPoint, index) => (
                    <MarkerInTable
                        aPoint={aPoint}
                        index={index}
                        key={index}
                        currentIndex={this.state.currentIndex}
                        activeMarker={this.props.activeMarker}
                        setActiveMarker={this.setActiveMarker}
                        setClickedMarker={this.setClickedMarker}
                        mapType={this.props.mapType}
                    />
                )) :
                <p className="p-4"><span className="text-xl"><i className="far fa-sad-tear" /> Oh non ! </span><br/> Nous n'avons aucun résultat à vous présenter dans cette zone, en ce moment...</p>}
            </div>
        )
    }
}