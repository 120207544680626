import axios from "axios"
import accountService from "./services/account.service";


export default axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-type": "application/json"
    },
    validateStatus: function (status){
        if (status === 401){
            accountService.logout()
        }
        return status >=200 && status < 300
    }
})