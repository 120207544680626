import PushNotificationSender from './services/notification.service'

// Service worker registration
let serviceWorkerRegistration = null;
export const registerServiceWorker = async () => {
  if (serviceWorkerRegistration) {
    return serviceWorkerRegistration;
  }

  if ("serviceWorker" in navigator) {
    try {
      serviceWorkerRegistration = await navigator.serviceWorker.register("/service-worker.js");
      return serviceWorkerRegistration;
    } catch (error) {
      console.error("Service Worker registration failed:", error);
    }
  }
};

// Show notification
export const displayNotification = async (title, options) => {
  if (!serviceWorkerRegistration) {
    serviceWorkerRegistration = await registerServiceWorker();
  }

  if (serviceWorkerRegistration) {
    try {
      await serviceWorkerRegistration.showNotification(title, options);
    } catch (error) {
      console.error(`Error showing notification:${error}`);
    }
  }
};

// Request notification permission
export const requestNotificationPermission = async () => {

  if (!('serviceWorker' in navigator) || !('Notification' in window)) {
    console.log("Service Worker or Notification API not supported in this browser.");
    return null;
  }

  let registration;
  try {
    registration = await navigator.serviceWorker.register('/service-worker.js');
  } catch (error) {
    console.error(`Error registering service worker: ${error}`);
  }

  // Check current permission status
  if (Notification.permission === "granted") {
    console.log("Permission to receive notifications has been granted already.");
    return Notification.permission;
  }
  if (Notification.permission !== "denied") {
    try {
      const permission = await Notification.requestPermission();
      registerServiceWorker();
      await subscribeToPush();
      return permission;
    } catch (error) {
      console.error(`Error requesting notification permission: ${JSON.parse(error)}`,);
    }
  }
  return Notification.permission;
};

export const subscribeToPush = async () => {
  try {
    const registration = await navigator.serviceWorker.ready;

    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: "BHBNNj32WeLL8-dO941ctAx_a1ZWRzP-tjUEFc-kxddsl4jDpA-WtqMYBExDhZCMbnGOXXklKqlEUiBTIrHG-XM"
    }).then(
      (pushSubscription) => {
        PushNotificationSender.savePushSubscription(pushSubscription);
        console.log(pushSubscription)
      },
      (error) => {
        console.error(error);
      },);

    return subscription;
  } catch (error) {
    console.error("Error subscribing to push:", error);
  }
};

export const pushNotification = async (title, text) => {
  try {
    const subscription = await subscribeToPush();

    await PushNotificationSender.sendPushNotification(subscription, { title, text })
  } catch (error) {
    console.error("Error subscribing to push:", error);
  }
};

export const checkPermissionAndSubscribe = async () => {
  if (Notification.permission === "granted") {
    subscribeToPush();
  } else {
    console.log("Notification permission was previously denied. Unable to subscribe to push notifications.");
  }
}
