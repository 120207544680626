import moment from 'moment'
import React, { Component } from 'react'
import { Badge, Button, Card, CardBody, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Table } from 'reactstrap'
import accountService from '../services/account.service'
import candidateService from '../services/candidate.service'
import miscConfig from './config/misc.config'
import { IBANInput, IBANFormat } from './iban.component'
import MeetCodeButton from './mp/meetCodeButton.component'
import CountUp from 'react-countup';
export default class cagnotte extends Component {
    constructor(props) {
        super(props)
        this.currentUser = accountService.getCurrentUser()
        this.handleInputChange = this.handleInputChange.bind(this)
        this.retrieveUser = this.retrieveUser.bind(this)
        this.saveEdit = this.saveEdit.bind(this)
        this.demandPoints = this.demandPoints.bind(this)
        this.state = {
            id: "",
            userName: "",
            userLastName: "",
            meetPoints: 0,
            accountHolder: "",
            bic: "",
            iban: "",
            formattedIban: "",
            dataCorrect: false,
            history: [],
            modified: false,
            request: {}
        }
    }
    async retrieveUser() {
        // var userInfo = await candidateService.getCandidateByAccount(this.currentUser.id)
        var userInfo = await candidateService.getMyHistory()
        var historyDated = []
        userInfo.points_histories.forEach(element => {
            var dateShort = new Date(element.modification_date)
            dateShort = moment(dateShort).format("D/MM/YYYY")
            // dateShort.getDate()+"-"+dateShort.getMonth()+"-"+dateShort.getFullYear()
            historyDated.push({
                candidate_id: element.candidateid,
                points: element.points,
                id: element.id,
                type: element.type,
                modification_date: new Date(element.modification_date).toISOString().replace(/T/, " ").replace(/\..+/, ""),
                dateShort: dateShort
            })
        })
        historyDated.sort(function (a, b) {
            return new Date(b.modification_date) - new Date(a.modification_date)
        })
        await this.setState({
            id: userInfo.id,
            userName: userInfo.name,
            userLastName: userInfo.last_name,
            bic: userInfo.bic,
            iban: userInfo.iban,
            formattedIban: IBANFormat(userInfo.iban),
            meetPoints: userInfo.meet_points,
            history: historyDated,
            accountHolder: userInfo.account_holder,
            modified: false
        })

        if (userInfo.bic && userInfo.iban && userInfo.account_holder) {
            this.setState({ dataCorrect: true })
        }
    }
    handleInputChange(e) {
        const { bic, iban, accountHolder } = this.state
        const target = e.target
        const value = target.value
        const name = target.name
        this.setState({ [name]: value })
        if (name !== 'points') {
            this.setState({ modified: true, dataCorrect: (bic.length === 11 && iban.length === 27 && accountHolder.length > 0) })
        }
    }
    handleIbanChange(ibanValue, formattedIban) {
        const { bic, iban, accountHolder } = this.state
        this.setState({ iban: ibanValue, formattedIban, modified: true, dataCorrect: (bic.length === 11 && iban.length === 27 && accountHolder.length > 0) })
    }

    async saveEdit() {
        const { bic, iban, accountHolder } = this.state
        var body = {}
        if (bic.length === 11) {
            body.bic = bic
        } else {
            body.bic = "delete"
        }
        if (iban.length === 27) {
            body.iban = iban
        } else {
            body.iban = "delete"
        }
        if (accountHolder.length > 0) {
            body.account_holder = accountHolder
        }
        if (bic.length === 11 && iban.length === 27 && accountHolder.length > 0) {
            this.setState({ dataCorrect: true })
        }
        await candidateService.candidateEdit(body, this.state.id)
        await this.retrieveUser()
    }
    demandPoints() {
        if (this.state.dataCorrect && this.state.request.mp <= this.state.meetPoints) {
            this.setState({
                meetPoints: this.state.meetPoints - this.state.request.mp
            })
            var dateShort = moment(new Date()).format('D/MM/YYYY')
            var history = this.state.history
            history.unshift({
                candidate_id: this.state.id,
                points: this.state.request.mp,
                id: this.state.id,
                type: 'request',
                modification_date: new Date(),
                dateShort: dateShort
            })
            this.setState({ history })
            candidateService.requestPoints(this.state.request)
        }
    }

    componentDidMount() {
        this.retrieveUser()
    }

    render() {
        const { bic, iban, formattedIban, modified, dataCorrect, history, meetPoints, request, accountHolder } = this.state
        return (
            <Row className="justify-content-md-center">
                <Col className="mt-5 col-12 col-lg-8 text-left">
                    <Row>
                        <Col className="col-auto">
                            <Button outline color="default" style={{ lineHeight: 1.7 }} onClick={() => { this.props.history.push("/profile") }}><i className="far fa-user"></i><span>Mon profil</span></Button>
                        </Col>
                        <Col className="col-auto">
                            <InputGroup className="cursor-pointer shadow" style={{ width: "262px" }} onClick={() => { this.props.history.push("/cagnotte") }}>
                                <InputGroupAddon addonType="prepend">
                                    <div className="border border-primary py-1 rounded-left text-lg font-weight-bold px-4">
                                        <div className="mt--4 ml--2 pr-4">
                                            <Badge className=" shadow-sm" style={{ backgroundColor: '#fbfbfb' }}>
                                                <span className="text-primary text-xs text-capitalize">MeetPoints</span>
                                            </Badge>
                                        </div>
                                        <CountUp
                                            start={0}
                                            end={meetPoints}
                                            delay={0}
                                            duration={2.75}
                                            separator=" "
                                            decimals={0}
                                        >
                                            {({ countUpRef }) => (
                                                <div>
                                                    <span ref={countUpRef} />
                                                </div>
                                            )}
                                        </CountUp>
                                    </div>
                                </InputGroupAddon>

                                <InputGroupAddon addonType="append">
                                    <Button color="primary py-1" style={{ lineHeight: "1.1" }}>Accéder à <br />ma cagnotte</Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                        <Col className="col-auto">
                            <MeetCodeButton outline style={{ lineHeight: 1.7 }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="">
                            <div className="h1 text-primary mb-2 mt-4" style={{ lineHeight: '1.1' }}><span className="rounded rounded-circle bg-primary text-white text-lg px-2 mr-2">1</span> Je cumule des MeetPoints</div>
                            <div className="pr-3">
                                Chaque semaine, je reçois <b>1 MeetPoint par demi heure travaillée</b> dans le cadre d'une mise en relation validée sur <b>Meetpharma</b>.
                            </div>

                            <div className="h1 text-primary mb-2 mt-4" style={{ lineHeight: '1.1' }}><span className="rounded rounded-circle bg-primary text-white text-lg px-2 mr-2">2</span> Je convertis mes MeetPoints en Euros</div>

                            <div className="mb-3">
                                {miscConfig.possibleDebits && Object.keys(miscConfig.possibleDebits).map((key, index) => {
                                    return <Button outline={request.mp !== miscConfig.possibleDebits[key].mp} color={request.mp !== miscConfig.possibleDebits[key].mp ? 'default' : 'primary'}
                                        className={"m-2 " + (miscConfig.possibleDebits[key].mp > meetPoints ? " text-light" : "")}
                                        style={{ width: '190px' }}
                                        disabled={miscConfig.possibleDebits[key].mp > meetPoints}
                                        key={index}
                                        onClick={(e) => this.setState({ request: miscConfig.possibleDebits[key] })}>

                                        <span className="text-xl">{miscConfig.possibleDebits[key].eur} €</span> <br />
                                        <span>pour {miscConfig.possibleDebits[key].mp} MeetPoints</span>
                                        {miscConfig.possibleDebits[key].mp > meetPoints && <div className="bg-white text-primary d-block position-relative rounded-circle " style={{ width: "25px", margin: "0 auto -20px auto" }}><i className="fa fa-lock" /></div>}
                                    </Button>
                                })}

                            </div>

                            <div className="h1 text-primary mb-4" style={{ lineHeight: '1.1' }}><span className="rounded rounded-circle bg-primary text-white text-lg px-2 mr-2">3</span> Je valide mes informations bancaires</div>
                            <InputGroup className="mb-2">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>IBAN</InputGroupText>
                                </InputGroupAddon>
                                <IBANInput
                                    className="profile-field"
                                    name="iban"
                                    onChange={({ iban, formattedIban }) => this.handleIbanChange(iban, formattedIban)}
                                    value={formattedIban}
                                    iban={iban}
                                />
                            </InputGroup>

                            <InputGroup className="mb-2">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText className="pr-4">BIC</InputGroupText>
                                </InputGroupAddon>
                                <Input className="profile-field" maxLength="11" name="bic" value={bic} onChange={this.handleInputChange} />
                            </InputGroup>

                            <div className="mb-2">
                                <p className="font-weight-bold mb-1">Titulaire du compte</p>
                                <Input className="profile-field" maxLength="30" name="accountHolder" value={accountHolder} onChange={this.handleInputChange} />
                            </div>

                            {dataCorrect ? <p className="text-success text-right"><i className="far fa-check-circle mr-2" />Coordonnées bancaires valides</p> : <p className="text-danger text-right"><i className="fa fa-exclamation-circle mr-2" /> Veuillez vérifier vos informations bancaires</p>}
                            {modified ? <Button color="primary" onClick={this.saveEdit}>Enregistrer mes informations bancaires</Button> :
                                request.mp > 0 && (request.mp < meetPoints) && <Button disabled={!dataCorrect} color={dataCorrect ? "primary" : "primary"} onClick={this.demandPoints} >Décagnotter {request.mp} MeetPoints maintenant
                                    {!dataCorrect && <div className="bg-white text-primary d-block position-relative rounded-circle " style={{ width: "25px", margin: "0 auto -20px auto" }}><i className="fa fa-lock" /></div>}</Button>}
                        </Col>

                        <Col>
                            <p className="h3 mt-4">Conditions de décagnottage</p>
                            <p className="font-italic text-sm"><i className="fa fa-info-circle" /> Pour pouvoir convertir vos points en argent n'oubliez pas de sauvegarder le nom du titulaire du compte, votre BIC et votre IBAN</p>
                            <Card color="primary shadow-none text-left text-white">
                                <CardBody className="p-0">
                                    <i className="fa fa-info-circle" /> Vous pouvez, à tout moment, modifier vos coordonnées bancaires. L'ensemble de vos informations sont protégés de manière durable sur notre plateforme.
                                </CardBody>
                            </Card>


                            <div className="h3 text-default mb-4 mt-4" style={{ lineHeight: '1.1' }}>Historique de ma cagnotte</div>
                            <Table className="justify-content-center">
                                <thead>
                                    <tr>
                                        <th>
                                            date
                                        </th>
                                        <th>
                                            action
                                        </th>
                                        <th>
                                            montant
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {history.length > 0 && history.map((info, index) => (
                                        <tr key={index}>
                                            <td>{info.dateShort}</td>
                                            <td>{info.type === "request" ? "Décagnottage" : info.type}</td>
                                            <td>{info.type === 'credit' ? '+' : '-'}{info.points} MeetPoints</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}