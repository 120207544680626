import http from "../http-common";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

class StripeService {
  async subscribeToFreeTrial(email) {
    try {
      const stripe = await stripePromise;

      const response = await http.post("/subscribe-to-free-trial", { email });

      if (response.data.success) {
        const { subscription } = response.data;
        return subscription;
      } else {
        console.error("Error subscribing to free trial:", response.data.error);
        throw new Error("Error subscribing to free trial");
      }
    } catch (error) {
      console.error("Error subscribing to free trial:", error.message);
      throw error;
    }
  }

  async checkSubscriptionStatus(userEmail) {
    const stripe = await stripePromise;
    return http
      .get(`/check-subscription-status?email=${userEmail}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error checking subscription status:", error.message);
        throw error;
      });
  }

  async checkAndCreateSubscription(userEmail) {
    const stripe = await stripePromise;
    return http
      .get(`/check-and-create-subscription?email=${userEmail}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error checking customer session:", error.message);
        throw error;
      });
  }
  // get subscirption : 
    async getListSubscription() {
      const stripe = await stripePromise;
      return http
        .get(`/subscriptions`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.error("Error checking subscription status:", error.message);
          throw error;
        });
    }
  
    async getSubscriptionDetails(email) {
      const stripe = await stripePromise;
      return http
        .get(`/get-subscription-details?email=${email}`)
        .then((response) => {
          console.log(response)
          return response.data;
        })
        .catch((error) => {
          console.error("Error retrieving subscription details:", error.message);
          throw error;
        });
  }  
  
    // cancel subscription:
    async cancelSubscription(email) {
      try {
        const stripe = await stripePromise;
        const response = await http.post("/cancel-subscription", { email });
        return response.data;
      } catch (error) {
        console.error("Error canceling subscription:", error.message);
        throw error;
      }
    }
}

export default new StripeService();
