export default function CGU(props) {
    return (<div className="text-left legal-text">
        <p align="center" className="text-primary h1 pb-4">
            <strong>Conditions Générales d’Utilisation de MeetPharma</strong>
        </p>
        <p>
            Les présentes conditions générales d’utilisation (ci-après dénommées «
            Conditions Générales d’Utilisation ») sont conclues entre :
        </p>
        <p>
            La société MeetPharma, société par actions simplifiée (S.A.S), immatriculée
            au RCS de Bobigny sous le n° 901 382 531, dont le siège social est situé au
            182 Avenue Jean Lolive - 93500 Pantin (ci-après : la « Société »).
        </p>
        <p>
            Et
        </p>
        <p>
            L’Utilisateur de la plateforme numérique    <a href="https://app.meetpharma.fr/">www.app.meetpharma.fr</a>
        </p>
        <p>
            <strong>Préambule</strong>
            <strong></strong>
        </p>
        <p>
            La Société exploite une plateforme numérique accessible à l’adresse    <a href="https://app.meetpharma.fr/">www.app.meetpharma.fr</a> (ci-après
            la « Plateforme ») hébergée par la société OVH située au 2 Rue Kellermann -
            59100 Roubaix.
        </p>
        <p>
            La Plateforme est dédiée à la mise en relation de Candidats et d’Employeurs
            du secteur de la santé dans le domaine de la pharmacie d’officine.
        </p>
        <p>
            Les présentes Conditions Générales d’Utilisation de MeetPharma ont pour
            objet de régir les conditions et modalités d’utilisation de la Plateforme,
            ainsi que de définir les droits et obligations des Utilisateurs mis en
            relation via la Plateforme.
        </p>
        <p>
            Tout accès et/ou utilisation de la Plateforme suppose l’acceptation sans
            réserve et le respect de l’ensemble des termes des présentes Conditions
            Générales d’Utilisation.
        </p>
        <p>
            <strong>I. </strong>
            <strong>Définitions</strong>
        </p>
        <p>
            <strong>Compte : </strong>
            désigne le compte créé sur la Plateforme par l’Utilisateur lors de son
            inscription sur la Plateforme.
        </p>
        <p>
            <strong>Contenu : </strong>
            désigne tous propos, messages ou informations de quelque nature que ce soit
            (texte, image, vidéos, photographies, commentaires, marques, dénominations
            sociales, etc.), mis en ligne par un Utilisateur sur la Plateforme.
        </p>
        <p>
            <strong>Candidat : </strong>
            désigne toute personne morale ou physique souhaitant exercer dans le
            domaine de la pharmacie d’officine. Est également désigné comme Candidat,
            toute personne agissant en tant qu’indépendant disposant d’un numéro SIRET
            et inscrite au Registre du Commerce et des Sociétés au Répertoire des
            Métiers, au Registre spécial tenu par le greffe du tribunal de Commerce
            dont dépend son centre de formalités des entreprises, ou au Répertoire
            national des associations, soit exerçant en tant qu’indépendant dans des
            conditions légales depuis la France ou l’étranger et qui propose ses
            services aux Employeurs à travers la Plateforme.
        </p>
        <p>
            <strong>Employeur : </strong>
            désigne toute personne morale ou physique exerçant dans le domaine de la
            pharmacie d’officine, utilisant la Plateforme à des fins strictement
            professionnelles, en vue d’entrer en contact ou d’être mis en relation avec
            un ou plusieurs Candidat(s) afin de lui confier la réalisation d’une
            Mission.
        </p>
        <p>
            <strong>Plateforme : </strong>
            désigne le site internet exploité par la Société dont l’adresse est    <a href="https://app.meetpharma.fr/">www.app.meetpharma.fr</a>
        </p>
        <p>
            <strong>Mission : </strong>
            désigne la tâche spécifique confiée par un Employeur à un Candidat via la
            Plateforme.
        </p>
        <p>
            <strong>Services : </strong>
            désigne tous les services fournis par la Société tels que définis à
            l’article 3.1 des présentes Conditions.
        </p>
        <p>
            <strong>Utilisateurs : </strong>
            désigne les Candidats et les Employeurs inscrits sur la Plateforme.
        </p>
        <p>
            <strong>II. </strong>
            <strong>Inscription sur la Plateforme</strong>
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>2.1 </strong>
            <strong>
                Conditions d'inscription sur la Plateforme des Utilisateurs
            </strong>
        </p>
        <p>
            Pour bénéficier des Services de la Plateforme    <a href="https://app.meetpharma.fr/">www.app.meetpharma.fr</a>,
            l’Utilisateur doit créer un Compte via le formulaire d’inscription
            disponible sur la Plateforme.
        </p>
        <p>
            L’Utilisateur est tenu de fournir des informations exactes qu’il s’engage à
            mettre immédiatement à jour en cas de modifications.
        </p>
        <p>
            L’accès au Compte créé est protégé par un identifiant et un mot de passe
            choisi par l’Utilisateur lors de son inscription sur la Plateforme.
            L’Utilisateur est seul responsable de tout usage qui pourrait être fait de
            son identifiant et mot de passe, et seul garant de leur confidentialité,
            ainsi que de toute utilisation de son Compte.
        </p>
        <p>
            <strong>2.2 </strong>
            <strong>Conditions d’inscription sur la Plateforme du Candidat</strong>
        </p>
        <p>
            <strong>2.2.1</strong>
            Pour être référencé comme Candidat sur la Plateforme, être visible des
            Utilisateurs et bénéficier des avantages en euros ou en nature liés aux
            Missions, le Candidat est tenu de renseigner de manière exacte et complète
            son Compte, de télécharger un Curriculum Vitae récent, ainsi que de mettre
            à jour les informations sur son Compte.
        </p>
        <p>
            <strong>2.2.2</strong>
            Concernant les professions libérales pour lesquelles l’exercice de
            l’activité par le Candidat est soumis au respect de critères d'accès ou à
            des conditions d'exercice qui font l'objet d'un encadrement réglementaire
            imposé par l'État (professions réglementées), le Candidat s’engage à être
            titulaire du (i) droit d’exercer la profession dont il se prévaut et du
            (ii) droit de réaliser les Missions dont il fait la publicité sur la
            Plateforme.
        </p>
        <p>
            <strong>2.2.3</strong>
            Dans l'hypothèse où l’Utilisateur fournirait des données fausses,
            inexactes, périmées ou incomplètes, la Société sera en droit de suspendre
            ou de procéder à la fermeture de son Compte et de lui refuser, à l'avenir,
            l'accès à tout ou partie des Services.
        </p>
        <p>
            <strong>2.3 </strong>
            <strong>Conditions d’inscription sur la Plateforme de l’Employeur</strong>
        </p>
        <p>
            <strong>2.3.1 </strong>
            Pour bénéficier des services de la plateforme. L'employeur doit souscrire à un abonnement avec ou sans engagement (voir CGV partie 3 Tarif et paiement)
        </p>
        <p>
            <strong>2.3.2 </strong>
            Il est entendu que la souscription à ces Services suppose l’acceptation
            préalable et sans réserve des Conditions Générales de Vente de la Société
            MeetPharma par le Client.
        </p>
        <p>
            <strong>III. </strong>
            <strong>
                Description des Services et fonctionnement de la Plateforme
            </strong>
        </p>
        <p>
            En cas de modification de la nature proposé sur la plateforme, 
            l’Utilisateur Candidat et/ou l’Utilisateur Employeur en
            sera informé dans les meilleurs délais par e-mail et sera libre de
            souscrire ou non à ces nouveaux Services et/ou de procéder à la résiliation
            de son inscription aux Services modifiés.
        </p>
        <p>
            <strong>3.1 </strong>
            <strong>Description des Services</strong>
        </p>
        <p>
            Les Services comprennent notamment :
        </p>
        <ul type="disc">
            <li>
                la possibilité pour l’Utilisateur de créer un Compte sur la Plateforme
                ;
            </li>
            <li>
                la mise en relation entre des Candidats et des Employeurs dans le
                domaine de la pharmacie d’officine via la Plateforme ;
            </li>
            <li>
                la mise à disposition d’une interface personnalisée : la création par
                la Société d’une interface personnalisée de la Plateforme pour
                l’Utilisateur, permettant une centralisation de ses demandes et
                informations ;
            </li>
            <li>
                la mise à disposition de moyens de géolocalisation destinés à assurer
                la pertinence de la mise en relation entre le Candidat et l’Employeur
                par la Plateforme ;
            </li>
            <li>
                la collecte et la mise à disposition sur la Plateforme des documents
                requis du Candidat dans le cadre de l’obligation de vigilance, étant
                précisé que l’Employeur, en sa qualité de donneur d’ordre, demeure seul
                responsable de la vérification de ces documents ;
            </li>
            {/* <li>
                la validation du contrat par le biais du tchat de la Plateforme ;
            </li>
            <li>
                la mise à disposition d’un système de bonus (parrainage, Meetpoints)
                comprenant divers avantages eu euros ou en nature à destination des
                Candidats (Meetpoints) et des Employeurs (parrainage).
            </li> */}
        </ul>
        <p>
            <strong>3.2. Fonctionnement de la Plateforme</strong>
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>3.2.1 Mise en relation des Utilisateurs via la Plateforme</strong>
        </p>
        <p>
            L’Employeur entre en contact avec le Candidat (i) en le contactant
            directement via la Plateforme ou (ii) par l’entremise de la Société.
        </p>
        <p>
            <strong>3.2.2 Facturation de la Mission</strong>
        </p>
        <p>
            Après échange sur le champ et les modalités de la Mission, le Candidat
            adresse directement à l’Employeur son devis ou ses prétentions salariales
            via la Plateforme.
        </p>
        <p>
            La Société MeetPharma n’intervient en aucun cas dans le paiement de la
            Mission entre le Candidat et l’Employeur.
        </p>
        <p>
            <strong>IV. </strong>
            <strong>Accès à la Plateforme et aux Services</strong>
        </p>
        <p>
            L’accès à la Plateforme et aux Services est exclusivement réservé aux
            Utilisateurs inscrits. La Plateforme    <a href="https://app.meetpharma.fr/">www.app.meetpharma.fr</a> est
            accessible 24h/24, 7j/7 pour l’ensemble des Utilisateurs.
        </p>
        <p>
            Il revient aux Utilisateurs de faire leur affaire personnelle de la mise en
            place des moyens informatiques et de télécommunications permettant l’accès
            à la Plateforme. Ils conservent à leur charge les frais de
            télécommunication lors de l’accès à internet et de l’utilisation de la
            Plateforme.
        </p>
        <p>
            La Société MeetPharma se réserve le droit, sans préavis, ni indemnité, de
            fermer temporairement ou définitivement la Plateforme et/ou l’accès à un ou
            plusieurs Services afin d’effectuer une mise à jour, d’apporter de
            modifications ou un changement sur les méthodes opérationnelles, les
            serveurs et les heures d’accessibilité, sans que cette liste ne soit
            limitative.
        </p>
        <p>
            La Société se réserve également le droit d’apporter à la Plateforme ainsi
            qu’à ses Services toute modification et amélioration qu’elle jugera
            nécessaires ou utiles dans le cadre de son bon fonctionnement.
        </p>
        <p>
            <strong>4.1 </strong>
            <strong>Accès à la Plateforme pour l’Employeur</strong>
        </p>
        <p>
            La Plateforme est réservée à des Employeurs dans le domaine de la pharmacie
            d’officine et ne peut pas être utilisée par des intermédiaires ou des
            professionnels du recrutement agissant pour le compte d'un client final.
        </p>
        <p>
            En cas de violation de ces règles d’accès, la Société se réserve le droit
            de limiter l'accès à ses Services notamment en réduisant la possibilité de
            contacter des Candidats sur la Plateforme ou d’utiliser les fonctionnalités
            de son profil afin de mettre un terme à la concurrence déloyale ainsi
            pratiquée.
        </p>
        <p>
            Au-delà du rappel des règles d’usage de la Plateforme figurant dans les
            Conditions Générales d’Utilisation, cette limitation des possibilités
            d’utilisation de la Plateforme ne fait pas l’objet d’une information
            spécifique afin de se prémunir contre une réitération de cette violation de
            nos conditions d’utilisation via un nouveau Compte.
        </p>
        <p>
            <strong>V. </strong>
            <strong>Données personnelles</strong>
        </p>
        <p>
            La Société MeetPharma s’engage à respecter les dispositions de loi «
            informatique et libertés » du 6 janvier 1978 modifié par la loi du 6 août
            2004 concernant les informations personnelles que l’Utilisateur communique
            dans le cadre de l’utilisation de la Plateforme.
        </p>
        <p>
            Les informations recueillies via les formulaires de la Plateforme font
            l’objet d’un traitement informatique qui a pour finalité de permettre à
            l’Utilisateur de se créer un espace membre, afin qu’il puisse profiter des
            services proposés par la Plateforme.
        </p>
        <p>
            Les données personnelles recueillies sur la Plateforme résultent de la
            communication volontaire des Utilisateurs. L’Utilisateur peut refuser à
            tout moment la collecte de données en choisissant dans son navigateur la
            fonction "navigation privée".
        </p>
        <p>
            La Plateforme décline toute responsabilité en cas de transmission
            volontaire ou involontaire par l’Utilisateur à un tiers des identifiants et
            codes d’accès à son Compte, qu’il lui appartient de conserver en lieu sûr
            et dont l’utilisation par un tiers pourrait entrainer la divulgation,
            l’altération ou la suppression des données personnelles de l’Utilisateur.
        </p>
        <p>
            Les données sont collectées par la Société dans le cadre de :
        </p>
        <ul type="disc">
            <li>
                l’inscription de l’Utilisateur Candidat ;
            </li>
            <li>
                l’inscription de l’Utilisateur Employeur ;
            </li>
            <li>
                des statistiques de fréquentation du Site.
            </li>
        </ul>
        <p>
            Ces informations sont stockées aussi longtemps que nécessaire. Elles ne
            seront en aucun cas cédées ou communiquées à des tiers.
        </p>
        <p>
            Conformément à la loi « Informatique et Libertés » du 6 janvier 1978
            modifiée par la loi du 6 août 2004, l’Utilisateur dispose du droit d’accès
            et de modification des informations qui le concernent. Ces droits peuvent
            être exercés en s’adressant à la Société MeetPharma
            (contact@meetpharma.fr).
        </p>
        <p>
            <strong>VI. </strong>
            <strong>Cookies</strong>
        </p>
        <p>
            Pour assurer le bon fonctionnement de la Plateforme et des Services, des
            cookies peuvent être déposés sur le terminal de l’Utilisateur lors de sa
            connexion à la Plateforme. Les cookies sont de petits fichiers textes qui
            sont stockés dans le navigateur ou le terminal par les Plateformes
            numérique, les applications, les médias en ligne et les publicitaires.
        </p>
        <p>
            La Société MeetPharma informe que l’Utilisateur dispose dans son logiciel
            de navigation de la possibilité de s’opposer à l’enregistrement de cookies
            dans son ordinateur.
        </p>
        <p>
            L’utilisation des traceurs est régie par l’article 32 II de la loi n° 78-17
            du 6 janvier 1978, transposant l’article 5.3 de la directive 2002/58/CE du
            parlement européen et du conseil du 12 juillet 2002 modifiée par la
            directive 2009/136/CE.
        </p>
        <p>
            Pour plus d’informations sur les cookies et traceurs, nous vous invitons à
            consulter le site de la CNIL : www.cnil.fr.
        </p>
        <p>
            <strong>VII. </strong>
            <strong>Propriété Intellectuelle</strong>
        </p>
        <p>
            <strong>7.1 </strong>
            <strong>De la Plateforme</strong>
        </p>
        <p>
            Non limitativement : les textes, images, vidéos, photographies, logos,
            marques, dénominations sociales et noms de domaine sont la propriété
            exclusive de la Société MeetPharma ou de celle de ses partenaires.
        </p>
        <p>
            Ces éléments sont protégés par les lois relatives à la propriété
            intellectuelle et autres, et notamment le droit d’auteur.
        </p>
        <p>
            Toute reproduction ou représentation, intégrale ou partielle, de la
            Plateforme ou de l’un des éléments qui la compose, sans l’autorisation de
            la Société, est interdite et constitue une contrefaçon sanctionnée par le
            Code de la Propriété Intellectuelle.
        </p>
        <p>
            <strong>7.2 </strong>
            <strong>Des Contenus</strong>
        </p>
        <p>
            Tout Utilisateur qui publie du Contenu sur la Plateforme possède l’entière
            propriété de tout ce qu’il publie.
        </p>
        <p>
            L’Utilisateur reconnaît que toute utilisation de son Contenu effectuée par
            la Société préalablement à sa désinscription, la suppression ou la
            résiliation de son Compte ne pourra pas être remise en cause.
        </p>
        <p>
            <strong>VIII. </strong>
            <strong>Service clientèle</strong>
        </p>
        <p>
            Pour toute question ou information concernant la Plateforme et ses
            Services, l’Utilisateur peut contacter la Société en lui adressant un
            courrier électronique à l’adresse suivante : contact@meetpharma.fr.
        </p>
        <p>
            <strong>IX. </strong>
            <strong>Modification des Conditions Générales d’Utilisation</strong>
        </p>
        <p>
            La Société MeetPharma se réserve la possibilité de modifier à tout moment
            tout ou partie des présentes Conditions Générales d’Utilisation.
        </p>
        <p>
            Une fois ces modifications apportées, l’Utilisateur qui continue à utiliser
            la Plateforme est réputé accepter ces modifications.
        </p>
        <p>
            Si les modifications apportées par la Société sont importantes, la Société
            en informera les Utilisateurs à l’aide de moyens raisonnables, par exemple,
            en communiquant sur la Plateforme un avis relatif à ces modifications ou en
            adressant un e-mail informatif aux Utilisateurs.
        </p>
    </div>)

}