import React, { Component } from "react"
import { Button, Col, Row } from "reactstrap"
import { overlayStyle, informationStyle } from "../styles/mapPage.style"
import BeabaPharmacy from "./tools/beabaPharmacy.component"
import CandidateWithStyle from "./tools/candidateWithStyle.component"
import EntrepreneurHelp from "./tools/entrepreneurHelp.component"

const ToolDoc = ({ name, icon, logoSrc, onClick, value }) => {
    return <Button outline color="default" className="my-2 px-2 py-2 rounded shadow w-100 ml-0 border-primary border-top-0 border-right-0 border-bottom-0 cursor-pointer text-left text-md " style={{ borderLeft: "5px solid" }} onClick={onClick}>
        {icon && <i className={icon + " fa-2x ml-2 mr-3 float-left "} />}
        {logoSrc &&
            <img
                alt={name + 'logo'}
                className="ml-2 mr-3 float-left"
                style={{ height: "60px" }}
                src={logoSrc} />}
        <span className="d-block float-left pt-1 pb-1">{name}</span>
    </Button>
}
export default class Toolbox extends Component {
    constructor(props) {
        super(props)
        this.assignClicked = this.assignClicked.bind(this)
        this.deactivateOverlay = this.deactivateOverlay.bind(this)
        this.state = {
            clickedOption: "",
        }
    }
    assignClicked(e) {
        this.setState({ clickedOption: e.target.value })
    }
    deactivateOverlay() {
        this.setState({ clickedOption: "" })
    }

    render() {
        const { clickedOption } = this.state
        return (<>
            <Row className="justify-content-md-center bg-banner">
                <Col className="my-5 col-12 col-lg-8 text-left h2 text-white " >
                    <h1 className="text-white display-3"><i className="fas fa-layer-group pr-3 mb-4" />Boîte à outils</h1></Col>
            </Row>
            <Row>
                <Col className="col-12 col-lg-8 text-left mt-3 px-lg-6 px-4 pb-4">
                    <h3 className="text-primary">Ils nous font confiance</h3>
                    <a rel="noreferrer" href="https://mapharmacie-et-moi.fr/offre/" target="_blank"><ToolDoc name="Groupement Escale Santé" logoSrc={require("../assets/img/logo-escale-sante.jpg").default} /></a>
                    <h3 className="text-primary mt-3">Liens pratiques</h3>
                    <a rel="noreferrer" href="https://www.youtube.com/watch?v=nqXO5YpU64s" target="_blank"><ToolDoc name="Prendre son envol en tant qu'auto-entrepreneur" icon="fab fa-youtube" /></a>
                    <a rel="noreferrer" href="http://www.autoentrepreneur.urssaf.fr" target="_blank"><ToolDoc name="Service autoentrepreneur Urssaf" icon="fas fa-globe" /></a>
                </Col>
                <Col className="col-12 col-lg-4 mt-1 pb-4 px-lg-6 px-4">
                    {/* <Card color="primary" style={{ minHeight: "200px" }}>
                        <CardTitle className="text-white">Image + liens à venir</CardTitle>
                    </Card>
                    <Card color="white" className="shadow" style={{ minHeight: "200px" }}>
                        <CardTitle className="text-primary">Image + liens à venir</CardTitle>
                    </Card> */}
                </Col>
            </Row>
            {clickedOption &&
                <>
                    <div className="overlay" id="overlay" style={overlayStyle} onClick={() => this.deactivateOverlay()} />
                    <div className="information p-3 p-md-5" id="informationPage" style={informationStyle}>
                        <Row>
                            <Col className="text-right">
                                <Button outline color="primary" onClick={() => this.deactivateOverlay()} className="mb--3 mr--3 bg-white" style={{ zIndex: "1080", border: "none" }}><i className="fas fa-times fa-lg" /></Button>
                            </Col>
                        </Row>
                        {clickedOption === "BeabaPharmacy" && <BeabaPharmacy />}
                        {clickedOption === "CandidateWithStyle" && <CandidateWithStyle />}
                        {clickedOption === "EntrepreneurHelp" && <EntrepreneurHelp />}
                        <Row className="mb-6">
                            <Col className="text-left">
                                <Button outline color="default" onClick={() => this.deactivateOverlay()} className="border-info"><i className="fas fa-angle-left pr-1 fa-lg text-primary" /> Retour</Button>
                            </Col>
                        </Row>
                    </div>
                </>}
        </>)
    }
}