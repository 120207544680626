import React, { Component } from "react"
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Card, Col, Container, Row } from "reactstrap"
import candidateService from "../services/candidate.service"
import moment from 'moment';
import 'moment/locale/fr';
import { positionList, salaryEnum } from "./config/account.config";

const dateTimeFormatter = (cell) => <span className="invoice-date">{cell ? moment(cell).format('llll') : '-'}</span>

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Afficher{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                missions.
            </label>
        </div>
    ),
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            &nbsp;Affichage des missions {from} à {to} sur un total de {size} missions.
        </span>
    )
});

const { SearchBar } = Search;


const ReactBSTables = ({ componentRef, alert, data, loading }) =>
    <>
        {alert}
        <Container className="mt--4" fluid>
            <Row>
                <div className="col">
                    <Card>
                        <ToolkitProvider
                            data={data}
                            keyField="id"
                            columns={[
                                {
                                    dataField: "creation_date",
                                    text: "Date de demande",
                                    formatter: dateTimeFormatter,
                                    style: {
                                        width: "1%",
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "offer.pharmacy.name",
                                    text: "Nom de la pharmacie",
                                    style: {
                                        width: "1%",
                                        minWidth: '120px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "offer.disponibility.date_start",
                                    text: "Date de démarrage",
                                    formatter: dateTimeFormatter,
                                    style: {
                                        width: "1%",
                                        minWidth: '120px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "offer.disponibility.date_end",
                                    text: "Date de fin",
                                    formatter: dateTimeFormatter,
                                    style: {
                                        width: "1%",
                                        minWidth: '120px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "offer.disponibility.contract_type",
                                    text: "Contrat",
                                    style: {
                                        width: "1%",
                                        minWidth: '180px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "offer.disponibility.salary",
                                    text: "Salaire",
                                    formatter: (cell) => cell ? salaryEnum[cell] : '?',
                                    style: {
                                        width: "1%",
                                        minWidth: '160px'
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                },
                                {
                                    dataField: "offer.disponibility.position",
                                    text: "Poste",
                                    formatter: (cell) => cell ? positionList[cell] : '?',
                                    style: {
                                        width: "1%",
                                    },
                                    sort: true,
                                    headerClasses: 'btn d-table-cell btn-outline border-0 rounded-0 shadow-none btn-primary'
                                }
                            ]}
                            search
                            exportCSV
                            remote
                            loading={loading}
                        >
                            {(props) => (
                                <div className="py-1 table-responsive">
                                    <Container fluid>
                                        <Row>
                                            <Col xs={12} className="pb-2 text-left">
                                                <SearchBar
                                                    placeholder="Rechercher..." className="form-control" {...props.searchProps}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                    {loading &&
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        > <i className="fas fa-spinner fa-spin fa-3x text-primary"></i>
                                        </div>
                                    }
                                    {!loading && <BootstrapTable
                                        hover
                                        ref={componentRef}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        pagination={pagination}
                                        bordered={false}
                                        id="react-bs-table"
                                    />}
                                </div>
                            )}
                        </ToolkitProvider>
                    </Card>
                </div>
            </Row>
        </Container>
    </>




export default class missions extends Component {
    constructor(props) {
        super(props)
        this.getMissions = this.getMissions.bind(this)
        this.state = {
            missions: [],
            page: 1,
            loading: true,
            sizePerPage: 25
        }
    }

    async getMissions() {
        var candidate = await candidateService.getCandidateInfo(this.props.candidate.candidateId, true, true)
        this.setState({ missions : candidate.validations.filter(a => a.validation_date), loading: false })
    }

    componentDidMount() {
        this.getMissions()
    }

    render() {
        const { missions, page, loading } = this.state
        return (
            <Row className="mt-2 p-0 p-lg-3 mx-auto">
                <Col className="col-12 px-0 px-lg-3">
                    <div className="h2 text-left text-primary col-auto text-xl">
                        <span>Mes missions accomplies</span>
                    </div>
                    <ReactBSTables
                        data={missions}
                        page={page}
                        loading={loading}
                    />
                </Col>
            </Row>
        )
    }
}